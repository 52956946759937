import { Box, ListItem, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "./agreement-list.scss";

const pressedSuffix = "--pressed";

// No need to define the defaultProps property
const AgreementListItem = ({
  text,
  pressed,
  handleClick,
  stylingClass,
  checklistSubText,
}) => {
  const theme = useTheme();
  const ListIcon = pressed ? CheckCircleIcon : CheckCircleOutlineIcon;

  return (
    <ListItem
      onClick={() => handleClick()}
      sx={{
        ...theme.cards.agreementListItem,
        ...(pressed && {
          backgroundColor: theme.palette.secondary.dark,
          color: "white",
        }),
      }}
    >
      <ListIcon
        sx={{
          fontSize: "2.5rem",
          color: theme.palette.primary.main,
          fontWeight: 100,
          ...(pressed && {
            color: theme.palette.success.light,
            strokeWidth: "0 !important",
          }),
          mr: "1rem",
          stroke: !pressed
            ? theme.palette.grey[100]
            : theme.palette.success.light,
          strokeWidth: 0.8,
        }}
      />
      <Box>
        {text}
        <Typography className="agreement-list-subtext">
          {checklistSubText}
        </Typography>
      </Box>
    </ListItem>
  );
};

export default AgreementListItem;
