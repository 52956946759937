// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
    firstFetchMade: false,
    groups: [],
};

// ==============================|| GROUPS REDUCER ||============================== //

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GROUPS_FETCH_SUCCEEDED:
            console.log("Fetch groups reducer. Action = ", action);
            const groupsToAdd = action.groups ? action.groups.filter(
                (group) => !(state.groups.filter(existing => existing.id === group.id).length > 0)
            ) : [];

            const newGroupSet = [...state.groups, ...groupsToAdd];

            return {
                ...state,
                firstFetchMade: true,
                groups: newGroupSet,
            };

        case actionTypes.GROUPS_ADD_OR_UPDATE:
            const groupToUpdate = !!action.updatedGroup ? state.groups.find(
                (group) => group.id === action.updatedGroup.id
            ) : null;

            if (groupToUpdate) {
                NestedObjectAssign(groupToUpdate, action.updatedGroup);
            }
            const addedGroup = groupToUpdate ? [] : [action.updatedGroup];

            const newGroups = [...state.groups, ...addedGroup].sort((a, b) => {
                return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
            });

            return {
                ...state,
                firstFetchMade: true,
                groups: newGroups,
            };

        case actionTypes.GROUPS_DELETE:
            const filteredGroups = state.groups.filter(
                (group) => group.id != action.deletedGroupID
            );

            return {
                ...state,
                firstFetchMade: true,
                groups: filteredGroups,
            };

        default:
            return state;
    }
};

export default groupsReducer;
