import PropTypes from "prop-types";

import { useState, useRef, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIFTS_ADD_OR_UPDATE, SET_VIEW_SHIFT_DRAWER } from "store/actions";

import * as Sentry from "@sentry/react";

import { useNavigate } from "react-router-dom";
import { usePrompt } from "react-router-dom";

import { logEvent } from "firebase/analytics";
import { analytics as firebaseInstance } from "utils/firebase";

import { getCurrency } from "utils/currency";

import { generatePassword } from "../../../utils/passwords";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Box,
    Drawer,
    useMediaQuery,
    Chip,
    Stack,
    Typography,
    Stepper,
    Step,
    StepButton,
    LinearProgress,
    Grid,
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    TextField,
    Select,
    MenuItem,
    Autocomplete,
    List,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Switch,
    Button,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/system";
import Lottie from "lottie-react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { IconCircleX, IconCirclePlus, IconCircleMinus } from "@tabler/icons-react";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// third-party
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";
import * as Yup from "yup";
import { Formik } from "formik";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { parseISO, isDate } from "date-fns";

// project imports
import ShiftStatusBadge from "../../../ui-component/ShiftStatusBadge";
import { drawerWidth } from "store/constant";
import AgreementListItem from "../../../ui-component/lists/AgreementListItem";
import "../../../ui-component/lists/AgreementListItem/agreement-list.scss";

import * as TrophyAnimation from "../../../assets/animations/trophy-winner.json";

// ==============================|| RIGHT SIDEBAR DRAWER ||============================== //

const steps = [
    "Basic Details",
    //   "Rewards & Incentives (optional)",
    //   "Terms",
    "Completed",
];

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PostShiftDrawer = ({
    drawerOpen,
    onDrawerClose,
    shiftID = -1,
    window,
}) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

    const updatingMode = false; // Boolean(shiftID > 0);
    // console.log("EDITING MODE FOR SHIFT ", shiftID);
    // console.log("TARGET FOR UODATE", targetShift);

    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    // Tooltips state (for mobile)
    const [openingsToolTipOpen, setOpeningsToolTipOpen] = useState(false);

    const [resetMode, setResetMode] = useState(false);

    const dispatch = useDispatch();

    // TODO populate from Strapi
    const [checklistItems, setchecklistItems] = useState([
        {
            id: 0,
            checklistItem:
                "After inviting the champion, they will receive a confirmation email that will include the reward incentive specified.",
            pressed: false,
        },
        {
            id: 1,
            checklistItem:
                "They will fill in further details including identity verification and their availability before being able to represent you.",
            pressed: false,
        },
        {
            id: 2,
            checklistItem:
                "By inviting this champion, you agree to our Ambassador Policy.",
            pressed: false,
        },
        {
            id: 3,
            checklistItem:
                "You can delete this champion at any time, or you can toggle their active status, which will hide their testimonial.",
            pressed: false,
        },
    ]);

    const [emailsToInvite, setEmailsToInvite] = useState([]);

    const account = useSelector((state) => state.account);
    const { companyID, accessToken, companyWebsiteHost } = account;

    const initialVals = {
        email: "",
        notifyOnboarded: true,
    };

    const formRef = useRef();

    const container =
        window !== undefined ? () => window.document.body : undefined;

    const inviteAmbassadors = async (values) => {
        setLoading(true);

        if (!emailsToInvite.length > 0) {
            setErrMsg("Please enter at least one email address.");
            console.log(`No emails to invite.`);
            return;
        }

        const postData = {
            emails: emailsToInvite,
            organizationID: companyID,
            notifyOnboarded: values.notifyOnboarded,
        };

        const apiURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/users/invite`;

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const apiErr = (e) => {
            Sentry.captureException(e);
            setErrMsg("Please check your connection and try again.");
            console.log(e);

            logEvent(firebaseInstance, "invite_champion_creation_failed", {
                companyID,
                emailsToInvite,
                values,
                err: e,
            });
        };

        const apiCb = (response) => {
            // console.log(response);
            const { data } = response;
            if (!data || !data.usersAdded) {
                console.log("Unable to parse response from server");
                return;
            }

            logEvent(firebaseInstance, "invite_ambassadors_user_creation_succeeded", {
                companyID,
                emailsToInvite,
                values,
                response,
            });

            for (const ambassadorInvited of data.usersAdded) {
                dispatch({
                    type: SHIFTS_ADD_OR_UPDATE,
                    updatedShift: {
                        id: ambassadorInvited.id,
                        email: ambassadorInvited.email,
                        username: `ambassador/${ambassadorInvited.email}`,
                    },
                });
            }

            setActiveStep(activeStep + 1);
        };

        // console.log(postData);
        try {
            const response = await axios.post(apiURL, postData, apiHeaders);
            apiCb(response);
        } catch (e) {
            apiErr(e);
        }

        setLoading(false);
    };

    const stepValuesValid = (values, errors, status) => {
        if (activeStep === 0) {
            return (
                // TODO needs cleaning up
                // We should be splitting up the steps into their own forms

                // All completed
                !values.email &&
                emailsToInvite.length > 0 &&
                // values.firstName &&
                // values.lastName &&
                // With no errors
                !errors.email
            );
        }
        if (activeStep === 1) {
            return (
                // values.rewardValue &&
                // values.rewardType &&
                // values.maxMonthlyReward &&
                !errors.rewardValue && !errors.rewardType && !errors.maxMonthlyReward
            );
        }
        if (activeStep === 2) {
            return !checklistItems.some((item) => !item.pressed);
        }
    };

    const resetEverything = (e) => {
        if (formRef.current) {
            console.log("RESETTING EVERYTHING");
            // Reset all state
            formRef.current.resetForm();
            setEmailsToInvite([]);
            setErrMsg("");
            setActiveStep(0);
        } else {
            console.log("FAILED RESETTING EVERYTHING");
        }
    };

    useEffect(() => {
        if (drawerOpen) {
            setResetMode(false);
        } else {
            setResetMode(true);
        }
        if (formRef.current) {
            // Ensure the form is initialized with the correct information
            formRef.current.resetForm({
                values: initialVals,
            });
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (activeStep === 0 && resetMode) {
            setResetMode(false);
        }
    }, [activeStep]);

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: "auto" }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                anchor="right"
                open={drawerOpen}
                onClose={(e) => {
                    onDrawerClose(e);
                    resetEverything(e);
                }}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: matchUpMd ? "612px" : "100vw",
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: "none",
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(errMsg)}
                    autoHideDuration={6000}
                    onClose={() => setErrMsg("")}
                >
                    <Alert
                        onClose={() => setErrMsg("")}
                        severity="error"
                        sx={{ width: "100%", whiteSpace: "pre-line" }}
                    >
                        Something went wrong.{`\n`}
                        {errMsg}
                    </Alert>
                </Snackbar>
                <Box
                    sx={{
                        backgroundColor: theme.palette.secondary.dark,
                        padding: "5%",
                    }}
                >
                    <Stack>
                        <Stack
                            direction="row"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <IconCircleX
                                cursor="pointer"
                                color="white"
                                stroke={0.3}
                                size="2.5rem"
                                onClick={(e) => {
                                    resetEverything(e);
                                    onDrawerClose(e);
                                }}
                            />
                        </Stack>
                        <Typography
                            component="span"
                            variant="h1"
                            mt="30px"
                            sx={{
                                color: "white",
                                fontSize: "40px",
                            }}
                        >
                            {updatingMode ? "Update an Ambassador" : "Invite Ambassadors"}
                        </Typography>
                    </Stack>
                </Box>
                <Box sx={{ height: "100%" }}>
                    <Stack
                        direction="column"
                        display="flex"
                        justifyContent="space-between"
                        height="100%"
                    >
                        <Box padding="5%" pb="0">
                            <Stack
                                // padding="5%"
                                pt="0"
                                sx={{
                                    ...theme.cards.agreementListItem,
                                    textAlign: "center",
                                    alignItems: "center",
                                    p: matchUpMd && !updatingMode ? "2rem 7rem" : "2rem 2rem",
                                }}
                                className={
                                    activeStep === steps.length - 1 && !loading
                                        ? "fadedInView"
                                        : "fadedOutView"
                                }
                                spacing={3}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        color: theme.palette.secondary.dark,
                                        fontSize: "1.9rem",
                                    }}
                                >
                                    Your invites have been sent out!
                                </Typography>
                                <Lottie autoplay loop animationData={TrophyAnimation} />
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: "darkgrey",
                                        fontSize: "1.3rem",
                                        pr: updatingMode ? "5rem" : 0,
                                        pl: updatingMode ? "5rem" : 0,
                                    }}
                                >
                                    Your ambassadors will complete their onboarding on the CampusThreads app.
                                </Typography>
                                <Chip
                                    sx={{
                                        fontSize: "1.2rem",
                                        fontWeight: "600",
                                        marginRight: "10px",
                                        height: "55px",
                                        pl: "20px",
                                        pr: "20px",
                                        alignItems: "center",
                                        borderRadius: "27px",
                                        transition: "all .2s ease-in-out",
                                        borderColor: theme.palette.primary.main,
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white",
                                        '&[aria-controls="menu-list-grow"], &:hover': {
                                            borderColor: theme.palette.primary.main,
                                            background: `${theme.palette.primary.dark}!important`,
                                            color: theme.palette.success.light,
                                            "& svg": {
                                                stroke: theme.palette.primary.light,
                                            },
                                        },
                                    }}
                                    label={updatingMode ? "View Updated Shift" : "View Ambassadors"}
                                    variant="outlined"
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                        resetEverything(e);
                                        onDrawerClose(e);
                                    }}
                                />
                                {/* {!updatingMode && (
                  <Chip
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      marginRight: "10px",
                      height: "45px",
                      pl: "10px",
                      pr: "10px",
                      alignItems: "center",
                      borderRadius: "27px",
                      transition: "all .2s ease-in-out",
                      borderWidth: "0",
                      // borderColor: theme.palette.primary.main,
                      backgroundColor: "#C7E1F2",
                      color: theme.palette.primary.dark,
                      '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.dark}!important`,
                        color: theme.palette.success.light,
                        "& svg": {
                          stroke: theme.palette.primary.light,
                        },
                      },
                    }}
                    label="Invite Another Ambassador"
                    variant="outlined"
                    // ref={anchorRef}
                    // aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={resetEverything}
                  />
                )} */}
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: "darkgrey",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={resetEverything}
                                >
                                    Invite More Ambassadors
                                </Typography>
                            </Stack>
                        </Box>
                        {loading && (
                            <Box sx={{ alignSelf: "center" }}>
                                <CircularProgress size="4rem" color="primary" />
                            </Box>
                        )}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Formik
                                // enableReinitialize
                                initialValues={initialVals}
                                validationSchema={Yup.object().shape({
                                    // TODO
                                    email: Yup.string().lowercase().email("Must be a valid email").notOneOf(emailsToInvite, "Email is already included"),
                                })}
                                innerRef={formRef}
                                onSubmit={async (
                                    values,
                                    { setErrors, setStatus, setSubmitting }
                                ) => { }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    touched,
                                    values,
                                    status,
                                    isValid,
                                    isValidating,
                                }) => (
                                    <form
                                        noValidate
                                        onSubmit={handleSubmit}
                                        style={{ display: "contents" }}
                                    >
                                        <Grid
                                            container
                                            rowSpacing={0.1}
                                            spacing={{ xs: 2, md: 3 }}
                                            columns={{ xs: 4, sm: 8, md: 12 }}
                                            padding="5%"
                                            pt="0"
                                            className={
                                                activeStep === 0 && !loading
                                                    ? "fadedInView"
                                                    : "fadedOutView"
                                            }
                                        >
                                            <Grid item xs={4} sm={8} md={12}>
                                                <Typography
                                                    sx={{
                                                        marginBottom: "40px",
                                                        padding: "20px",
                                                        background: theme.palette.secondary.light,
                                                        borderRadius: "15px",
                                                        fontWeight: "500",
                                                        color: theme.palette.grey[600],
                                                    }}
                                                >
                                                    NOTE: Once invited, the ambassador will be sent an email
                                                    outlining steps to download and complete their onboarding on the CampusThreads app.
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Stack
                                                    marginTop="20px"
                                                    alignItems="center"
                                                    direction="row"
                                                    justifyContent="start"
                                                    spacing={3}
                                                >
                                                    <Switch
                                                        defaultChecked={values.notifyOnboarded}
                                                        onChange={(e) => {
                                                            values.notifyOnboarded = e.target.checked;
                                                        }}
                                                        color="primary"
                                                        name="notifyOnboarded"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                    />
                                                    <Typography sx={{ ml: "0px !important" }}>
                                                        Notify me when they complete their onboarding
                                                    </Typography>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={4} sm={8} md={8}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(touched.email && errors.email)}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    {/* <InputLabel htmlFor="outlined-adornment-positionTitle-register">
                            Position Title
                          </InputLabel> */}

                                                    {emailsToInvite.length > 0 && (
                                                        <Typography
                                                            variant="h4"
                                                            fontWeight="300"
                                                            sx={{ p: 1.3 }}
                                                        >
                                                            {emailsToInvite.length} ambassador{emailsToInvite.length == 1 ? "" : "s"} will be invited
                                                        </Typography>
                                                    )}
                                                    {emailsToInvite.map((email) => (
                                                        <Chip
                                                            key={email}
                                                            sx={{
                                                                fontSize: "1rem",
                                                                fontWeight: "600",
                                                                marginRight: "10px",
                                                                marginBottom: "15px",
                                                                height: "45px",
                                                                pl: "10px",
                                                                pr: "10px",
                                                                alignItems: "center",
                                                                borderRadius: "27px",
                                                                transition: "all .2s ease-in-out",
                                                                borderWidth: "0",
                                                                // borderColor: theme.palette.primary.main,
                                                                backgroundColor: "#F2FFF2",
                                                                color: theme.palette.primary.dark,
                                                                justifyContent: "space-between !important",
                                                            }}
                                                            label={email}
                                                            variant="outlined"
                                                            deleteIcon={<CancelIcon />}
                                                            onDelete={() => {
                                                                setEmailsToInvite(
                                                                    emailsToInvite.filter(
                                                                        (emailToInvite) => emailToInvite !== email
                                                                    )
                                                                );
                                                            }}
                                                            // ref={anchorRef}
                                                            // aria-controls={open ? "menu-list-grow" : undefined}
                                                            aria-haspopup="true"
                                                        />
                                                    ))}
                                                    <Stack
                                                        alignItems="center"
                                                        direction="row"
                                                        justifyContent="start"
                                                        spacing={2}
                                                    >
                                                        <OutlinedInput
                                                            id="email"
                                                            // label="Position Title"
                                                            name="email"
                                                            type="text"
                                                            placeholder={`example@${companyWebsiteHost ?? "domain.com"}`}
                                                            value={values.email}
                                                            sx={{
                                                                "& .MuiInputBase-input": {
                                                                    paddingTop: "20.5px !important",
                                                                    paddingBottom: "20.5px !important",
                                                                },
                                                                width: "100%",
                                                            }}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        <AddIcon
                                                            sx={{
                                                                color: "white",
                                                                marginRight: "10px",
                                                                paddingLeft: "10px",
                                                                paddingRight: "10px",
                                                                height: "45px",
                                                                width: "2em",
                                                                alignItems: "center",
                                                                borderRadius: "27px",
                                                                transition: "all .2s ease-in-out",
                                                                borderColor: theme.palette.primary.main,
                                                                backgroundColor: values.email && !errors.email ? theme.palette.primary.main : "grey",
                                                                '&[aria-controls="menu-list-grow"], &:hover': values.email && !errors.email ? {
                                                                    cursor: "pointer",
                                                                    borderColor: theme.palette.primary.main,
                                                                    background: `${theme.palette.primary.dark}!important`,
                                                                    color: theme.palette.success.light,
                                                                    "& svg": {
                                                                        stroke: theme.palette.primary.light,
                                                                    },
                                                                } : null,
                                                            }}
                                                            onClick={values.email && !errors.email ? () => {
                                                                setEmailsToInvite([...emailsToInvite, values.email.toLowerCase()]);
                                                                values.email = "";
                                                            } : null}
                                                        />
                                                    </Stack>

                                                    {touched.email && errors.email && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-email-register"
                                                        >
                                                            {errors.email}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>

                                            {/* // TODO bring back later (import CSV feature) <Grid item xs={12}>
                                                <Chip
                                                    sx={{
                                                        p: "5px",
                                                        pl: matchUpMd ? "5px" : "0px",
                                                        pr: matchUpMd ? "5px" : "0px",
                                                        borderRadius: "50px",
                                                        background: "transparent",
                                                        color: theme.palette.primary.main,
                                                        cursor: "pointer",
                                                        borderWidth: "1px",
                                                        height: "4em",
                                                        borderColor: theme.palette.primary.main,
                                                        borderStyle: "solid",
                                                    }}
                                                    label={<h4>Import From CSV</h4>}
                                                    onClick={() => {
                                                        // No-op
                                                    }}
                                                />
                                            </Grid> */}


                                            {/* <Grid item xs={4} sm={4} md={12}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.linkedIn && errors.linkedIn)}
                          sx={{ ...theme.typography.customInput }}
                        > */}

                                            {/* <InputLabel htmlFor="outlined-adornment-positionTitle-register">
                            Position Title
                          </InputLabel> */}
                                            {/* <Typography
                            variant="h3"
                            fontWeight="300"
                            sx={{ p: 1.3 }}
                          >
                            LinkedIn URL (optional)
                          </Typography>
                          <TextField
                            id="linkedIn"
                            // label="Position Title"
                            name="linkedIn"
                            type="text"
                            value={values.linkedIn}
                            sx={{
                              "& .MuiInputBase-input": {
                                paddingTop: "20.5px !important",
                                paddingBottom: "20.5px !important",
                              },
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            helperText="Information such as profile picture, company, and position will be sourced automatically from their profile if it is not provided below."
                          /> */}
                                            {/* {touched.linkedIn && errors.linkedIn && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-linkedIn-register"
                            >
                              {errors.linkedIn}
                            </FormHelperText>
                          )} */}
                                            {/* </FormControl>
                      </Grid> */}


                                            {/* <Grid item xs={4} sm={4} md={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.firstName && errors.firstName)}
                          sx={{ ...theme.typography.customInput }}
                        > */}
                                            {/* <InputLabel htmlFor="outlined-adornment-positionTitle-register">
                            Position Title
                          </InputLabel> */}
                                            {/* <Typography
                            variant="h3"
                            fontWeight="300"
                            sx={{ p: 1.3 }}
                          >
                            First Name
                          </Typography>
                          <OutlinedInput
                            id="firstName"
                            // label="Position Title"
                            name="firstName"
                            type="text"
                            value={values.firstName}
                            sx={{
                              "& .MuiInputBase-input": {
                                paddingTop: "20.5px !important",
                                paddingBottom: "20.5px !important",
                              },
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.firstName && errors.firstName && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-firstName-register"
                            >
                              {errors.firstName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}

                                            {/* <Grid item xs={4} sm={4} md={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.lastName && errors.lastName)}
                          sx={{ ...theme.typography.customInput }}
                        > */}
                                            {/* <InputLabel htmlFor="outlined-adornment-positionTitle-register">
                            Position Title
                          </InputLabel> */}
                                            {/* <Typography
                            variant="h3"
                            fontWeight="300"
                            sx={{ p: 1.3 }}
                          >
                            Last Name
                          </Typography>
                          <OutlinedInput
                            id="lastName"
                            // label="Position Title"
                            name="lastName"
                            type="text"
                            value={values.lastName}
                            sx={{
                              "& .MuiInputBase-input": {
                                paddingTop: "20.5px !important",
                                paddingBottom: "20.5px !important",
                              },
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.lastName && errors.lastName && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-lastName-register"
                            >
                              {errors.lastName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}

                                            {/* <Grid item xs={4} sm={4} md={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.positionTitle && errors.positionTitle
                          )}
                          sx={{ ...theme.typography.customInput }}
                        > */}
                                            {/* <InputLabel htmlFor="outlined-adornment-positionTitle-register">
                            Position Title
                          </InputLabel> */}
                                            {/* <Typography
                            variant="h3"
                            fontWeight="300"
                            sx={{ p: 1.3 }}
                          >
                            Position Title (optional)
                          </Typography>
                          <OutlinedInput
                            id="positionTitle"
                            // label="Position Title"
                            name="positionTitle"
                            type="text"
                            value={values.positionTitle}
                            sx={{
                              "& .MuiInputBase-input": {
                                paddingTop: "20.5px !important",
                                paddingBottom: "20.5px !important",
                              },
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.positionTitle && errors.positionTitle && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-positionTitle-register"
                            >
                              {errors.positionTitle}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}

                                            {/* <Grid item xs={4} sm={4} md={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(
                            touched.companyName && errors.companyName
                          )}
                          sx={{ ...theme.typography.customInput }}
                        > */}
                                            {/* <InputLabel htmlFor="outlined-adornment-positionTitle-register">
                            Position Title
                          </InputLabel> */}
                                            {/* <Typography
                            variant="h3"
                            fontWeight="300"
                            sx={{ p: 1.3 }}
                          >
                            Company Name (optional)
                          </Typography>
                          <OutlinedInput
                            id="companyName"
                            // label="Position Title"
                            name="companyName"
                            type="text"
                            value={values.companyName}
                            sx={{
                              "& .MuiInputBase-input": {
                                paddingTop: "20.5px !important",
                                paddingBottom: "20.5px !important",
                              },
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {touched.companyName && errors.companyName && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-companyName-register"
                            >
                              {errors.companyName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}
                                        </Grid>

                                        <Stack sx={{ height: "100%", flex: 1 }}>

                                        </Stack>
                                        {Boolean(activeStep != steps.length - 1) && (
                                            <Box
                                                padding="5%"
                                                sx={{
                                                    clear: "both",
                                                    backgroundColor: theme.palette.primary.light,
                                                    // position: "absolute",
                                                    // bottom: 0,
                                                    // width: "100%",
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        sx={{
                                                            color: "darkgrey",
                                                            textDecoration: "underline",
                                                            cursor: "pointer",
                                                            mt: "0.8rem",
                                                            display:
                                                                activeStep === 0 ||
                                                                    activeStep === steps.length - 1
                                                                    ? "none"
                                                                    : "auto",
                                                        }}
                                                        onClick={() => {
                                                            if (activeStep > 0) {
                                                                setActiveStep(activeStep - 1);
                                                            }
                                                        }}
                                                    >
                                                        Back
                                                    </Typography>
                                                    <Chip
                                                        sx={{
                                                            height: "55px",
                                                            pl:
                                                                updatingMode && activeStep === steps.length - 2
                                                                    ? "7px"
                                                                    : "17px",
                                                            pr:
                                                                updatingMode && activeStep === steps.length - 2
                                                                    ? "7px"
                                                                    : "17px",
                                                            alignItems: "center",
                                                            borderRadius: "27px",
                                                            transition: "all .2s ease-in-out",
                                                            borderColor: theme.palette.primary.main,
                                                            backgroundColor: theme.palette.primary.main,
                                                            color: "white",
                                                            '&[aria-controls="menu-list-grow"], &:hover': {
                                                                borderColor: theme.palette.primary.main,
                                                                background: `${theme.palette.primary.dark}!important`,
                                                                color: theme.palette.success.light,
                                                                "& svg": {
                                                                    stroke: theme.palette.primary.light,
                                                                },
                                                            },
                                                            // "& .MuiChip-label": {
                                                            //   lineHeight: 0,
                                                            // },
                                                        }}
                                                        label={
                                                            activeStep === steps.length - 2 ? (
                                                                <h2>{updatingMode ? "Update" : "Invite"}</h2>
                                                            ) : (
                                                                <h2>Next</h2>
                                                            )
                                                        }
                                                        variant="outlined"
                                                        // ref={anchorRef}
                                                        // aria-controls={open ? "menu-list-grow" : undefined}
                                                        aria-haspopup="true"
                                                        disabled={Boolean(
                                                            isValidating ||
                                                            loading ||
                                                            !stepValuesValid(values, errors, status)
                                                        )}
                                                        onClick={async () => {
                                                            console.log(values);
                                                            if (activeStep === 0) {
                                                                // await fillLinkedInDetails(values);
                                                                setResetMode(true);
                                                            }
                                                            if (activeStep === steps.length - 2) {
                                                                // Post the shift!
                                                                inviteAmbassadors(values);
                                                            } else {
                                                                setActiveStep(activeStep + 1);
                                                            }
                                                            // setOpen((prevOpen) => !prevOpen);
                                                            // handleDrawerToggle();
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                        )}
                                    </form>
                                )}
                            </Formik>
                        </LocalizationProvider>
                    </Stack>
                </Box>
            </Drawer>
        </Box>
    );
};

PostShiftDrawer.propTypes = {
    drawerOpen: PropTypes.bool,
    onDrawerClose: PropTypes.func,
    window: PropTypes.object,
};

export default PostShiftDrawer;
