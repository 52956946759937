// action - state management
import * as actionTypes from "./actions";

import * as Sentry from "@sentry/react";

export const initialState = {
    accessToken: "",
    userID: 0,
    username: "",
    firebaseEmployerID: "",
    championID: 0,
    isChampion: false,
    companyID: 0,
    companySlug: "",
    companyName: "",
    companyWebsite: "",
    companyWebsiteHost: null,
    companySearchConfigID: "",
    logoURL: "",
    // TODO change once we have a proper address object
    companyCity: "",
    companyRegion: "",
    companyCountry: "",
    autoClocking: true,
    avatarOptions: null,
    email: "",
    firstName: "",
    lastName: "",
    completedPassword: false,
    fetchedMe: false,
    profilePictureURL: "",
    doNotNotifyCancelledShiftEmail: false,
    doNotNotifyClaimedShiftEmail: false,
    doNotNotifyNewAnnouncementEmail: false,
    doNotNotifyNewAnnouncementPush: false,
    doNotNotifyNewDocumentEmail: false,
    doNotNotifyNewDocumentPush: false,
    doNotNotifyNewModuleEmail: false,
    doNotNotifyNewModulePush: false,
    doNotNotifyNewFeedbackEmail: false,
    doNotNotifyNewFeedbackPush: false,
    identityVerified: false,
    paymentsEnabled: false,
    labels: [],
    tasksDisabled: false,
    shiftsDisabled: false,
    threadsDisabled: false,
    sandboxUser: false,
    associatedAmbassadorID: 0,
    associatedAmbassadorFirebaseID: "",
};

// ==============================|| ACCOUNT REDUCER ||============================== //

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_UPDATE:
            const newAccountDetails = {};
            const actionPayload = action.payload;
            //   console.log("Action Payload:");
            //   console.log(actionPayload);
            Object.keys(actionPayload).forEach((actionProperty) => {
                if (Object.keys(initialState).includes(actionProperty)) {
                    newAccountDetails[actionProperty] = actionPayload[actionProperty];
                }
                if (actionProperty == "userID") {
                    newAccountDetails.userID = parseInt(actionPayload.userID, 10); // keep userID as number
                }
                if (actionProperty == "companyID") {
                    newAccountDetails.companyID = parseInt(actionPayload.companyID, 10); // keep companyID as number
                }
                if (actionProperty == "championID") {
                    newAccountDetails.championID = parseInt(actionPayload.championID, 10); // keep championID as number
                }
                if (actionProperty === "firstName" && actionPayload.firstName) {
                    newAccountDetails.firstName =
                        actionPayload.firstName.charAt(0).toUpperCase() +
                        actionPayload.firstName.slice(1);
                }
                if (actionProperty === "lastName" && actionPayload.lastName) {
                    newAccountDetails.lastName =
                        actionPayload.lastName.charAt(0).toUpperCase() +
                        actionPayload.lastName.slice(1);
                }
                if (actionProperty === "type" && actionPayload.type) {
                    newAccountDetails.isChampion = actionPayload.type === "worker";
                }
                if (actionProperty === "companyWebsite" && actionPayload.companyWebsite) {
                    try {
                        newAccountDetails.companyWebsiteHost = (new URL(actionPayload.companyWebsite)).hostname.split('.').slice(-2).join('.');
                    } catch (e) {
                        Sentry.captureException(e);
                        console.error("Failed to parse company URL");
                    }
                }
                if (actionProperty === "labels") {
                    if (actionPayload.labels) {
                        newAccountDetails.labels = actionPayload.labels.split(",,");
                    } else {
                        newAccountDetails.labels = [];
                    }
                }
            });

            // Update email to the username
            if (newAccountDetails.username && newAccountDetails.username.includes("@") && newAccountDetails.username.startsWith("organization/")) {
                newAccountDetails.email = newAccountDetails.username.split("/")[1];
            }

            // console.log("New account details (from reducer)");
            // console.log(newAccountDetails);

            return {
                ...state,
                ...newAccountDetails,
            };
        case actionTypes.ACCOUNT_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default accountReducer;
