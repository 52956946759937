// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
  conversations: [],
};

// ==============================|| CONVOS REDUCER ||============================== //

const convosReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CONVOS_FETCH_SUCCEEDED:
      return {
        ...state,
        conversations: action.conversations,
      };
    case actionTypes.CONVOS_FETCH_FAILED:
      // console.log("CONVOS FETCH FAILED", action.message);
      return state;
    case actionTypes.CONVOS_DELETE_ALL:
      return initialState;
    case actionTypes.CONVOS_ADD_OR_UPDATE:
      // console.log("STATE", state.conversations);

      const convoToUpdate = state.conversations.find(
        (convo) => convo.id === action.updatedConvo.id
      );

      if (convoToUpdate) {
        NestedObjectAssign(convoToUpdate, action.updatedConvo);
        // Object.assign(convoToUpdate, action.updatedConvo);
      }
      const addedConvo = convoToUpdate ? [] : [action.updatedConvo];

      // TODO sort
      const newConvos = [...state.conversations, ...addedConvo]/*.sort(
        (convoA, convoB) => Date.parse(convoB.time) - Date.parse(convoA.time)
      );*/
      return {
        ...state,
        conversations: newConvos,
      };

    default:
      return state;
  }
};

export default convosReducer;
