import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from "./reducer";
import createSagaMiddleware from "redux-saga";
import thunk from 'redux-thunk';
import { fetchShiftsSaga, fetchConvosSaga, fetchFlagsSaga, fetchEventsSaga, fetchPastEventsSaga, fetchRulesSaga, fetchNotificationsSaga, fetchResourcesSaga, fetchGroupsSaga, fetchSuggestionsSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, thunk];

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(...middleware)));

sagaMiddleware.run(fetchShiftsSaga)
sagaMiddleware.run(fetchConvosSaga)
sagaMiddleware.run(fetchFlagsSaga)
sagaMiddleware.run(fetchEventsSaga)
sagaMiddleware.run(fetchPastEventsSaga)
sagaMiddleware.run(fetchRulesSaga)
sagaMiddleware.run(fetchNotificationsSaga)
sagaMiddleware.run(fetchResourcesSaga)
sagaMiddleware.run(fetchGroupsSaga)
sagaMiddleware.run(fetchSuggestionsSaga)

window.store = store;

export default store;
