// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
  flags: [],
};

// ==============================|| FLAGS REDUCER ||============================== //

const flagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FLAGS_FETCH_SUCCEEDED:
        console.log("Fetch flags reducer. Action = ", action);
        const flagsToAdd = action.flags ? action.flags.filter(
            (flag) => !(state.flags.filter(existing => existing.id === flag.id).length > 0)
        ) : [];

        const newFlagSet = [...state.flags, ...flagsToAdd];
      return {
        ...state,
        flags: newFlagSet,
      };
    case actionTypes.FLAGS_FETCH_FAILED:
      // console.log("FLAGS FETCH FAILED", action.message);
      return state;
    case actionTypes.FLAGS_DELETE_ALL:
      return initialState;
    case actionTypes.FLAGS_ADD_OR_UPDATE:
      // console.log("STATE", state.flags);

      const flagToUpdate = state.flags.find(
        (flag) => flag.id === action.updatedFlag.id
      );

      if (flagToUpdate) {
        NestedObjectAssign(flagToUpdate, action.updatedFlag);
        // Object.assign(flagToUpdate, action.updatedFlag);
      }
      const addedFlag = flagToUpdate ? [] : [action.updatedFlag];

      // TODO sort
      const newFlags = [...state.flags, ...addedFlag]/*.sort(
        (flagA, flagB) => Date.parse(flagB.time) - Date.parse(flagA.time)
      );*/
      return {
        ...state,
        flags: newFlags,
      };

    default:
      return state;
  }
};

export default flagsReducer;
