// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
    suggestions: [],
};

// ==============================|| SUGGESTIONS REDUCER ||============================== //

const suggestionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SUGGESTIONS_FETCH_SUCCEEDED:
            console.log("Fetch suggestions reducer. Action = ", action);
            const suggestionsToAdd = action.suggestions ? action.suggestions.filter(
                (suggestion) => !(state.suggestions.filter(existing => existing.id === suggestion.id).length > 0)
            ) : [];

            const newSuggestionSet = [...state.suggestions, ...suggestionsToAdd];

            return {
                ...state,
                suggestions: newSuggestionSet,
            };
        case actionTypes.SUGGESTIONS_FETCH_FAILED:
            // TODO log and record errors for all reducers
            // console.log(action.message);
            return state;
        case actionTypes.SUGGESTIONS_ADD_OR_UPDATE:
            const suggestionToUpdate = state.suggestions.find(
                (suggestion) => action.updatedSuggestion ? suggestion.id === action.updatedSuggestion.id : false
            );

            if (suggestionToUpdate) {
                NestedObjectAssign(suggestionToUpdate, action.updatedSuggestion);
            }
            const addedSuggestion = suggestionToUpdate ? [] : [action.updatedSuggestion];

            const newSuggestions = [...state.suggestions, ...addedSuggestion];

            return {
                ...state,
                suggestions: newSuggestions,
            };

        case actionTypes.SUGGESTIONS_DELETE:
            const filteredSuggestions = state.suggestions.filter(
                (suggestion) => suggestion.id != action.deletedSuggestionID
            );

            return {
                ...state,
                suggestions: filteredSuggestions,
            };

        default:
            return state;
    }
};

export default suggestionsReducer;
