// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import logo from "assets/images/CampusThreads-White.png";
// TODO get from server instead of local (based on school)
import darkLogo from "assets/images/CampusThreads_Dark.png";

// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
  const theme = useTheme();
  const { dark, width, logoURL, name } = props;

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <img
      src={dark ? (logoURL || darkLogo) : logo}
      alt={name}
      width={width || (dark ? "200" : "210")}
      style={{ marginLeft: dark ? "1em" : "inherit" }}
    />
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width={props.overrideWidth || "584.492"}
    //   height={props.overrideHeight || "69.709"}
    //   viewBox={`0 0 ${props.overrideWidth || "584.492"} ${props.overrideHeight || "69.709"}`}
    // >
    //   <g id="Group_26" data-name="Group 26" transform="translate(0 0)">
    //     <path
    //       id="Path_41"
    //       data-name="Path 41"
    //       d="M71.049.415l-14.943,46L41.356.415H23.988L47.955,68.338h16.4L88.321.415Z"
    //       transform="translate(34.214 0.592)"
    //       fill="#5e7541"
    //     />
    //     <g id="Group_24" data-name="Group 24" transform="translate(0 -0.001)">
    //       <g id="Group_23" data-name="Group 23">
    //         <path
    //           id="Path_42"
    //           data-name="Path 42"
    //           d="M81.587.415h29.887c14.946,0,23.482,9.312,23.482,22.026,0,6.5-2.909,10.867-7.762,13.488a17.7,17.7,0,0,1,7.279,12.905l2.135,19.5h-17.37L117.1,51.357a7.007,7.007,0,0,0-7.182-6.308H98.957v23.29H81.587ZM110.309,30.3c5.532,0,8.15-3.688,8.15-7.859s-2.618-7.861-8.15-7.861H98.957V30.3Z"
    //           transform="translate(116.366 0.593)"
    //           fill="#5e7541"
    //         />
    //         <path
    //           id="Path_43"
    //           data-name="Path 43"
    //           d="M107.666.415H125.13l11.355,42.6L149,.415h14.458l12.614,42.6,11.45-42.6h17.37L185.2,68.339H168.313L156.279,27.39,144.247,68.339H127.459Z"
    //           transform="translate(153.561 0.593)"
    //           fill="#5e7541"
    //         />
    //         <path
    //           id="Path_44"
    //           data-name="Path 44"
    //           d="M150.547,34.833c0-19.99,15.04-34.739,35.416-34.739,20.184,0,35.13,14.749,35.13,34.739s-14.946,34.739-35.13,34.739c-20.376,0-35.416-14.749-35.416-34.739m35.322,19.99c10.477,0,17.95-8.54,17.95-19.99s-7.473-19.99-17.95-19.99c-10.576,0-18.049,8.54-18.049,19.99s7.473,19.99,18.049,19.99"
    //           transform="translate(214.722 0.135)"
    //           fill="#5e7541"
    //         />
    //         <path
    //           id="Path_45"
    //           data-name="Path 45"
    //           d="M185.627.415h29.887C230.46.415,239,9.727,239,22.441c0,6.5-2.909,10.867-7.762,13.488a17.7,17.7,0,0,1,7.279,12.905l2.135,19.5h-17.37l-2.135-16.982a7.006,7.006,0,0,0-7.179-6.308H203v23.29h-17.37ZM214.349,30.3c5.532,0,8.15-3.688,8.15-7.859s-2.618-7.861-8.15-7.861H203V30.3Z"
    //           transform="translate(264.755 0.593)"
    //           fill="#5e7541"
    //         />
    //         <path
    //           id="Path_46"
    //           data-name="Path 46"
    //           d="M214.506,68.339V.415h17.37v26.49l23.1-26.49h21.443L252.642,27.1,278.55,68.339H256.619L240.8,40.3l-8.926,10V68.339Z"
    //           transform="translate(305.945 0.593)"
    //           fill="#5e7541"
    //         />
    //         <path
    //           id="Path_47"
    //           data-name="Path 47"
    //           d="M103.6,14.752H55.079V0H103.6Zm-7.279,26H55.079V26H96.326ZM103.6,67.924H55.079V53.174H103.6Z"
    //           transform="translate(78.558 0.001)"
    //           fill="#5e7541"
    //         />
    //         <path
    //           id="Path_48"
    //           data-name="Path 48"
    //           d="M0,0H47.839V14.749H17.273V26H41.336V40.754H17.273v12.42H48.906V67.924H0Z"
    //           transform="translate(0 0.001)"
    //           fill="#5e7541"
    //         />
    //       </g>
    //     </g>
    //   </g>
    // </svg>
  );
};

export default Logo;
