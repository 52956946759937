// action - state management
import * as actionTypes from "./actions";

export const initialState = {
    fetchPage: 1,
    fetchPastPage: 1,
    fetchPageLimit: 100,
    firstEventsFetchMade: false,
    doneFetching: false,
    doneFetchingPast: false,
    eventPostings: [],
};

// ==============================|| EVENTS REDUCER ||============================== //



const eventsReducer = (state = initialState, action) => {

    const updateEventInPostings = (currentPostings, updatedEvent) => {
        const eventToUpdate = currentPostings.find(
            (event) => event.id === updatedEvent.id
        );

        if (eventToUpdate) {
            Object.assign(eventToUpdate, updatedEvent);
        }
        const addedEvent = eventToUpdate ? [] : [updatedEvent];

        const newPostings = [...currentPostings, ...addedEvent];
        return newPostings;
    };

    // console.log('Fetch events reducer. Action = ', action);
    switch (action.type) {
        case actionTypes.EVENTS_FETCH_SUCCEEDED:
            const updatedOrAddedPostings = action.eventPostings.reduce((postings, event) => {
                return updateEventInPostings(postings, event);
            }, state.eventPostings);

            return {
                ...state,
                fetchPage: state.fetchPage + 1,
                firstEventsFetchMade: true,
                doneFetching: action.eventPostings.length < state.fetchPageLimit,
                eventPostings: updatedOrAddedPostings,
            };
        case actionTypes.EVENTS_FETCH_PAST_SUCCEEDED:
            const updatedOrAddedPastPostings = action.eventPostings.reduce((postings, event) => {
                return updateEventInPostings(postings, event);
            }, state.eventPostings);

            return {
                ...state,
                fetchPastPage: state.fetchPastPage + 1,
                firstEventsFetchMade: true,
                eventPostings: updatedOrAddedPastPostings,
            };
        case actionTypes.EVENTS_FETCH_FAILED:
            console.log(action.message);
            return state;
        case actionTypes.EVENTS_ADD_OR_UPDATE:
            console.log("STATE", state.eventPostings);

            const updatedPostings = action.updatedEvents.reduce((postings, event) => {
                return updateEventInPostings(postings, event);
            }, state.eventPostings);

            //   const newPostings = [...state.eventPostings, ...addedEvent].sort(
            //     (eventA, eventB) => Date.parse(eventB.time) - Date.parse(eventA.time)
            //   );
            return {
                ...state,
                eventPostings: updatedPostings,
            };

        // case actionTypes.EVENTS_DELETE:
        //   const filteredEvents = state.eventPostings
        //     .filter((event) => event.id !== action.deletedEventID)
        //     .sort(
        //       (eventA, eventB) => Date.parse(eventB.time) - Date.parse(eventA.time)
        //     );

        //   return {
        //     ...state,
        //     eventPostings: filteredEvents,
        //   };
        default:
            return state;
    }
};

export default eventsReducer;
