// project imports
import config from "config";

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  isOpen: [], // for active default menu
  defaultId: "default",
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  leftOpened: true,
  postShiftOpened: false,
  postShiftID: -1,
  postEventOpened: false,
  recurringSeries: false,
  postEventTime: null,
  postEventEnd: null,
  postEventID: -1,
  flagActionOpened: false,
  flagID: -1,
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_LEFT_MENU:
      return {
        ...state,
        leftOpened: action.leftOpened,
      };
    case actionTypes.SET_POST_SHIFT_DRAWER:
      return {
        ...state,
        postShiftOpened: action.postShiftOpened,
        postShiftID: action.postShiftID,
      };
    case actionTypes.SET_POST_EVENT_DRAWER:
        return {
            ...state,
            postEventOpened: action.postEventOpened,
            postEventID: action.postEventID,
            recurringSeries: action.recurringSeries,
            postEventTime: action.postEventTime,
            postEventEnd: action.postEventEnd,
        };
    case actionTypes.SET_VIEW_SHIFT_DRAWER:
      return {
        ...state,
        viewShiftID: action.viewShiftID,
      };
    case actionTypes.SET_VIEW_EVENT_DRAWER:
      return {
        ...state,
        viewEventID: action.viewEventID,
      };
    case actionTypes.SET_VIEW_TIMESHEET_DRAWER:
      return {
        ...state,
        viewTimesheetShiftID: action.viewTimesheetShiftID,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    default:
      return state;
  }
};

export default customizationReducer;
