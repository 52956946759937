// material-ui
import { Box } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { IonIcon } from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";

const EWCircularProgress = ({ value, size = 20 }) =>
  value === 100 ? (
    // <IconCircleCheck
    //   sx={{
    //     color: (theme) => theme.palette.secondary.dark,
    //     stroke: (theme) => theme.palette.grey[100],
    //     strokeWidth: 0,
    //     fontWeight: 100,
    //   }}
    // />
    <IonIcon
      style={{
        fontSize: "1.5rem",
        color: "#40492D",
        marginLeft: "-3px",
        marginTop: "3px",
        width: size * 1.2,
        // fontWeight: 100,
        // ...(pressed && {
        //   color: theme.palette.success.light,
        // }),
      }}
      icon={checkmarkCircle}
      // slot="start"
    />
  ) : (
    <Box sx={{ position: "relative", mt: "5px !important" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[200],
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.primary.main,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        value={value}
        thickness={4}
      />
    </Box>
  );

export default EWCircularProgress;
