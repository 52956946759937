/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeCards(theme) {
  // const agreementListItem = {
  //   fontSize: "1.15rem",
  //   pt: "1.5rem",
  //   pr: "0.5rem",
  //   backgroundColor: theme.colors?.grey100,
  //   color: theme.colors?.secondaryDark,
  //   fontWeight: 600,
  //   borderRadius: "1rem",
  //   mt: "1rem",
  // };

  return {
    agreementListItem: {
      fontSize: "1.15rem",
      pt: "1rem",
      pr: "0.5rem",
      backgroundColor: theme.colors?.grey100,
      color: theme.colors?.secondaryDark,
      fontWeight: "100 !important",
      borderRadius: "1rem",
      mt: "1rem",
      lineHeight: "150%",
      pb: "1rem",
    },
    // agreementListItemPressed: {
    //   ...agreementListItem,
    //   backgroundColor: theme.colors?.secondaryDark,
    //   color: "white",
    // },
    // agreementListIcon: {

    // }
  };
}
