// action - customization reducer
export const SET_LEFT_MENU = "@customization/SET_LEFT_MENU";
export const SET_POST_EVENT_DRAWER = "@customization/SET_POST_EVENT_DRAWER";
export const SET_VIEW_EVENT_DRAWER = "@customization/SET_VIEW_EVENT_DRAWER";
export const SET_POST_SHIFT_DRAWER = "@customization/SET_POST_SHIFT_DRAWER";
export const SET_VIEW_SHIFT_DRAWER = "@customization/SET_VIEW_SHIFT_DRAWER";
export const SET_VIEW_TIMESHEET_DRAWER =
    "@customization/SET_VIEW_TIMESHEET_DRAWER";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
// action - shifts reducer
export const SHIFTS_FETCH_REQUESTED = "@shifts/SHIFTS_FETCH_REQUESTED";
export const SHIFTS_FETCH_SUCCEEDED = "@shifts/SHIFTS_FETCH_SUCCEEDED";
export const SHIFTS_FETCH_FAILED = "@shifts/SHIFTS_FETCH_FAILED";
export const SHIFTS_ADD_OR_UPDATE = "@shifts/SHIFTS_ADD_OR_UPDATE";
export const SHIFTS_DELETE = "@shifts/SHIFTS_DELETE";
export const SHIFTS_DELETE_ALL = "@shifts/SHIFTS_DELETE_ALL";
export const SHIFTS_WIP_UPDATE = "@shifts/SHIFTS_WIP_UPDATE";
// action - conversations reducer
export const CONVOS_FETCH_REQUESTED = "@convos/CONVOS_FETCH_REQUESTED";
export const CONVOS_FETCH_SUCCEEDED = "@convos/CONVOS_FETCH_SUCCEEDED";
export const CONVOS_FETCH_FAILED = "@convos/CONVOS_FETCH_FAILED";
export const CONVOS_DELETE_ALL = "@convos/CONVOS_DELETE_ALL";
export const CONVOS_ADD_OR_UPDATE = "@convos/CONVOS_ADD_OR_UPDATE";
// action - flags reducer
export const FLAGS_FETCH_REQUESTED = "@flags/FLAGS_FETCH_REQUESTED";
export const FLAGS_FETCH_SUCCEEDED = "@flags/FLAGS_FETCH_SUCCEEDED";
export const FLAGS_FETCH_FAILED = "@flags/FLAGS_FETCH_FAILED";
export const FLAGS_DELETE_ALL = "@flags/FLAGS_DELETE_ALL";
export const FLAGS_ADD_OR_UPDATE = "@flags/FLAGS_ADD_OR_UPDATE";
// action - suggestions reducer
export const SUGGESTIONS_FETCH_REQUESTED =
    "@suggestions/SUGGESTIONS_FETCH_REQUESTED";
export const SUGGESTIONS_FETCH_SUCCEEDED =
    "@suggestions/SUGGESTIONS_FETCH_SUCCEEDED";
export const SUGGESTIONS_FETCH_FAILED = "@suggestions/SUGGESTIONS_FETCH_FAILED";
export const SUGGESTIONS_ADD_OR_UPDATE = "@suggestions/SUGGESTIONS_ADD_OR_UPDATE";
export const SUGGESTIONS_DELETE = "@suggestions/SUGGESTIONS_DELETE";
// action - prospects reducer
export const PROSPECTS_FETCH_REQUESTED =
    "@prospects/PROSPECTS_FETCH_REQUESTED";
export const PROSPECTS_FETCH_SUCCEEDED =
    "@prospects/PROSPECTS_FETCH_SUCCEEDED";
export const PROSPECTS_FETCH_FAILED = "@prospects/PROSPECTS_FETCH_FAILED";
export const PROSPECTS_ADD_OR_UPDATE = "@prospects/PROSPECTS_ADD_OR_UPDATE";
export const PROSPECTS_DELETE = "@prospects/PROSPECTS_DELETE";
// action - threads reducer
export const THREADS_FETCH_REQUESTED =
    "@threads/THREADS_FETCH_REQUESTED";
export const THREADS_FETCH_SUCCEEDED =
    "@threads/THREADS_FETCH_SUCCEEDED";
export const THREADS_FETCH_FAILED = "@threads/THREADS_FETCH_FAILED";
export const THREADS_ADD_OR_UPDATE = "@threads/THREADS_ADD_OR_UPDATE";
export const THREADS_DELETE = "@threads/THREADS_DELETE";
// action - resources reducer
export const RESOURCES_FETCH_REQUESTED =
    "@resources/RESOURCES_FETCH_REQUESTED";
export const RESOURCES_FETCH_SUCCEEDED =
    "@resources/RESOURCES_FETCH_SUCCEEDED";
export const RESOURCES_FETCH_FAILED = "@resources/RESOURCES_FETCH_FAILED";
export const RESOURCES_ADD_OR_UPDATE = "@resources/RESOURCES_ADD_OR_UPDATE";
export const RESOURCES_DELETE = "@resources/RESOURCES_DELETE";
// action - groups reducer
export const GROUPS_FETCH_REQUESTED =
    "@groups/GROUPS_FETCH_REQUESTED";
export const GROUPS_FETCH_SUCCEEDED =
    "@groups/GROUPS_FETCH_SUCCEEDED";
export const GROUPS_FETCH_FAILED = "@groups/GROUPS_FETCH_FAILED";
export const GROUPS_ADD_OR_UPDATE = "@groups/GROUPS_ADD_OR_UPDATE";
export const GROUPS_DELETE = "@groups/GROUPS_DELETE";
// action - events reducer
export const EVENTS_FETCH_REQUESTED = "@events/EVENTS_FETCH_REQUESTED";
export const EVENTS_FETCH_PAST_REQUESTED = "@events/EVENTS_FETCH_PAST_REQUESTED";
export const EVENTS_FETCH_PAST_SUCCEEDED = "@events/EVENTS_FETCH_PAST_SUCCEEDED";
export const EVENTS_FETCH_SUCCEEDED = "@events/EVENTS_FETCH_SUCCEEDED";
export const EVENTS_FETCH_FAILED = "@events/EVENTS_FETCH_FAILED";
export const EVENTS_ADD_OR_UPDATE = "@events/EVENTS_ADD_OR_UPDATE";
export const EVENTS_DELETE = "@events/EVENTS_DELETE";
// action - rules reducer
export const RULES_FETCH_REQUESTED = "@rules/RULES_FETCH_REQUESTED";
export const RULES_FETCH_SUCCEEDED = "@rules/RULES_FETCH_SUCCEEDED";
export const RULES_FETCH_FAILED = "@rules/RULES_FETCH_FAILED";
export const RULES_ADD_OR_UPDATE = "@rules/RULES_ADD_OR_UPDATE";
export const RULES_DELETE = "@rules/RULES_DELETE";
// action - notifications reducer
export const NOTIFICATIONS_FETCH_REQUESTED =
    "@notifications/NOTIFICATIONS_FETCH_REQUESTED";
export const NOTIFICATIONS_FETCH_SUCCEEDED =
    "@notifications/NOTIFICATIONS_FETCH_SUCCEEDED";
export const NOTIFICATIONS_ADD_OR_UPDATE = "@notifications/NOTIFICATIONS_ADD_OR_UPDATE";
export const NOTIFICATIONS_FETCH_FAILED = "@notifications/NOTIFICATIONS_FETCH_FAILED";
export const NOTIFICATIONS_MARK_ALL_AS_READ = "@notifications/NOTIFICATIONS_MARK_ALL_AS_READ";
export const NOTIFICATIONS_HIDE_ONE = "@notifications/NOTIFICATIONS_HIDE_ONE";
// action - account reducer
export const ACCOUNT_UPDATE = "@account/ACCOUNT_UPDATE";
export const ACCOUNT_LOGOUT = "@account/ACCOUNT_LOGOUT";

export const fetchShifts = (params, accessToken) => ({
    type: SHIFTS_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchConvos = (params, accessToken) => ({
    type: CONVOS_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchFlags = (params, accessToken) => ({
    type: FLAGS_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchEvents = (params, accessToken) => ({
    type: EVENTS_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchPastEvents = (params, accessToken) => ({
    type: EVENTS_FETCH_PAST_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchRules = (params, accessToken) => ({
    type: RULES_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchNotifications = (params, accessToken) => ({
    type: NOTIFICATIONS_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchResources = (params, accessToken) => ({
    type: RESOURCES_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchGroups = (params, accessToken) => ({
    type: GROUPS_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});

export const fetchSuggestions = (params, accessToken) => ({
    type: SUGGESTIONS_FETCH_REQUESTED,
    payload: {
        params,
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    },
});