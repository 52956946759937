import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { format, parseISO } from 'date-fns';
import { IconUser } from '@tabler/icons-react';
import { IconMail } from '@tabler/icons-react';
import { IconX } from '@tabler/icons-react';
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import FailSafeAvatar from 'ui-component/avatars/FailSafeAvatar';
// import { Scrollbar } from 'src/components/scrollbar';

const notificationsPageSize = 10;

const renderContent = (notification, ambassadors, admins, onNotificationClick) => {
    const notificationAttributes = notification.attributes;
    if (!notificationAttributes) return null;

    const createdAt = format(parseISO(notificationAttributes.createdAt), 'MMM dd, h:mm a');

    // Get associated user from the user store
    const associatedUserID = notificationAttributes.associatedUserID;

    // And the associated ambassador
    const associatedUser = ambassadors.find((ambassador) => (ambassador.id === associatedUserID || ambassador.firebaseAmbassadorID === associatedUserID)) ?? admins.find((admin) => (admin.id === associatedUserID || admin.firebaseEmployerID === associatedUserID));

    const itemTitle = notificationAttributes.associatedPostingTitle ?? notificationAttributes.associatedTaskTitle ?? notificationAttributes.associatedResourceTitle;

    const shiftTime = notificationAttributes.associatedPostingTime;
    const shiftDate = shiftTime ? format(parseISO(shiftTime), 'MM/dd/yy') : '';
    const shiftTimeFormatted = shiftTime ? format(parseISO(shiftTime), 'hh:mma') : '';
    // Get the avatar and name from the ambassador store
    const userAvatar = associatedUser?.avatar?.url;
    const userCartoon = associatedUser?.avatarOptions;
    const userName = `${associatedUser?.firstName} ${associatedUser?.lastName}`;
    let itemActionText = '';
    let itemTransitionalText = 'for';
    let itemActionColor = '#2A9D8F';
    if (notificationAttributes.type === 'shiftClaimedForBoth') {
        itemActionText = 'signed up';
    } else if (notificationAttributes.type === 'shiftCancelledForBoth') {
        itemActionText = 'cancelled their assignment';
        itemActionColor = '#E76F51';
    } else if (notificationAttributes.type === 'changedAvailabilityForBoth') {
        itemActionText = 'changed their availability for shifts';
        itemActionColor = '#F4A261';
    } else if (notificationAttributes.type === 'changedChatsAvailabilityForBoth') {
        itemActionText = 'changed their availability for chats';
        itemActionColor = '#F4A261';
    } else if (notificationAttributes.type === 'recurringAssignedForBoth') {
        itemActionText = 'signed up for a recurring series';
    } else if (notificationAttributes.type === 'taskCommentAdded') {
        itemActionText = 'commented';
        itemTransitionalText = 'on task';
        itemActionColor = '#F4A261';
    } else if (notificationAttributes.type === 'resourceCommentAdded') {
        itemActionText = 'commented';
        itemTransitionalText = 'on announcement';
        itemActionColor = '#F4A261';
        console.log('resourceCommentAdded', notificationAttributes);
    } else if (notificationAttributes.type === 'taskMarkedDone') {
        itemActionText = 'completed task';
        itemTransitionalText = '';
    } else if (notificationAttributes.type === 'taskCollaboratorAdded') {
        itemActionText = 'added you as a collaborator';
    } else if (notificationAttributes.type === 'taskDetailsModified') {
        itemActionText = 'modified task details';
        itemActionColor = '#F4A261';
    } else if (notificationAttributes.type === 'newSuggestionRequest') {
        itemActionText = '(or a thread contributor) requested a suggestion';
        itemActionColor = '#F4A261';
    } else if (notificationAttributes.type === 'messageModerated') {
        itemActionText = 'sent a message that was censored';
        itemActionColor = '#E76F51';
    } else if (notificationAttributes.type === 'moduleCompletedForBoth') {
        itemActionText = 'completed module';
        itemTransitionalText = '';
    }

    switch (notificationAttributes.type) {
        case 'taskCommentAdded':
        case 'resourceCommentAdded':
        case 'taskMarkedDone':
        case 'taskCollaboratorAdded':
        case 'taskDetailsModified':
        case 'shiftClaimedForBoth':
        case "shiftCancelledForBoth":
        case 'moduleCompletedForBoth':
            return (
                <Box
                    onClick={() => {
                        onNotificationClick(notification);
                    }}
                    sx={{
                        display: "contents",
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'action.hover'
                        },
                    }}
                >
                    <ListItemAvatar sx={{ mt: 0.5 }}>
                        <FailSafeAvatar profilePicURL={userAvatar} cartoonOptions={userCartoon} sz={40} firstName={associatedUser?.firstName} lastName={associatedUser?.lastName} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={(
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Typography
                                    sx={{ mr: 0.5 }}
                                    variant="subtitle2"
                                >
                                    {userName}
                                </Typography>
                                <Typography
                                    sx={{ mr: 0.5, color: itemActionColor }}
                                    variant="body2"
                                >
                                    {itemActionText}
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    {itemTransitionalText}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: 600, color: "#364152 !important" }}
                                >
                                    &nbsp;{itemTitle}
                                </Typography>
                                {!!shiftTimeFormatted && (
                                    <Typography
                                        variant="body2"
                                    >
                                        &nbsp;@ {shiftDate} {shiftTimeFormatted}
                                    </Typography>
                                )}
                                {/* TODO inform remaining openings left */}
                            </Box>
                        )}
                        secondary={(
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                {createdAt}
                            </Typography>
                        )}
                        sx={{ my: 0 }}
                    />
                </Box>
            );
        case 'newSuggestionRequest':
        case 'messageModerated':
            return (
                <Box
                    onClick={() => {
                        onNotificationClick(notification);
                    }}
                    sx={{
                        display: "contents",
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'action.hover'
                        },
                    }}
                >
                    <ListItemAvatar sx={{ mt: 0.5 }}>
                        <FailSafeAvatar profilePicURL={userAvatar} cartoonOptions={userCartoon} sz={40} firstName={associatedUser?.firstName} lastName={associatedUser?.lastName} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={(
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Typography
                                    sx={{ mr: 0.5 }}
                                    variant="subtitle2"
                                >
                                    {userName}
                                </Typography>
                                <Typography
                                    sx={{ mr: 0.5, color: itemActionColor }}
                                    variant="body2"
                                >
                                    {itemActionText}
                                </Typography>
                            </Box>
                        )}
                        secondary={(
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                {createdAt}
                            </Typography>
                        )}
                        sx={{ my: 0 }}
                    />
                </Box>
            );
        case "changedAvailabilityForBoth":
        case "changedChatsAvailabilityForBoth":
            return (
                <Box
                    onClick={() => {
                        onNotificationClick(notification);
                    }}
                    sx={{
                        display: "contents",
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'action.hover'
                        },
                    }}
                >
                    <ListItemAvatar sx={{ mt: 0.5 }}>
                        <FailSafeAvatar profilePicURL={userAvatar} cartoonOptions={userCartoon} sz={40} firstName={associatedUser?.firstName} lastName={associatedUser?.lastName} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={(
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Typography
                                    sx={{ mr: 0.5 }}
                                    variant="subtitle2"
                                >
                                    {userName}
                                </Typography>
                                <Typography
                                    sx={{ mr: 0.5, color: itemActionColor }}
                                    variant="body2"
                                >
                                    {itemActionText}
                                </Typography>
                            </Box>
                        )}
                        secondary={(
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                {createdAt}
                            </Typography>
                        )}
                        sx={{ my: 0 }}
                    />
                </Box>
            );
        case "recurringAssignedForBoth":
            return (
                <Box
                    onClick={() => {
                        onNotificationClick(notification);
                    }}
                    sx={{
                        display: "contents",
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: 'action.hover'
                        },
                    }}
                >
                    <ListItemAvatar sx={{ mt: 0.5 }}>
                        <FailSafeAvatar profilePicURL={userAvatar} cartoonOptions={userCartoon} sz={40} firstName={associatedUser?.firstName} lastName={associatedUser?.lastName} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={(
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Typography
                                    sx={{ mr: 0.5 }}
                                    variant="subtitle2"
                                >
                                    {userName}
                                </Typography>
                                <Typography
                                    sx={{ mr: 0.5, color: itemActionColor }}
                                    variant="body2"
                                >
                                    {itemActionText}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontWeight: "bolder", color: "#364152 !important" }}
                                >
                                    &nbsp;{itemTitle}
                                </Typography>
                            </Box>
                        )}
                        secondary={(
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                {createdAt}
                            </Typography>
                        )}
                        sx={{ my: 0 }}
                    />
                </Box>
            );
        default:
            return null;
    }
};

export const NotificationsPopover = (props) => {
    const {
        anchorEl,
        notifications,
        notificationsHidden,
        events,
        ambassadors,
        admins,
        onNotificationClick,
        onClose,
        // onMarkAllAsRead,
        onHideOne,
        open = false,
        ...other
    } = props;

    const isEmpty = notifications.length === 0;

    const [shownNotifications, setShownNotifications] = useState(notificationsPageSize);

    const handleScroll = (e) => {
        const bottomThreshold = 10;
        const distanceFromBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
        if (distanceFromBottom <= bottomThreshold) {
            setShownNotifications(oldShownNotifications => oldShownNotifications + notificationsPageSize);
        }
    };

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={open}
            PaperProps={{ sx: { width: 380 } }}
            {...other}>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="start"
                spacing={2}
                sx={{
                    px: 3,
                    py: 2
                }}
            >
                <Typography
                    color="inherit"
                    variant="h4"
                >
                    Notifications
                </Typography>
                {/* <Tooltip title="Mark all as read">
                    <IconButton
                        onClick={onMarkAllAsRead}
                        size="small"
                        color="inherit"
                    >
                        <SvgIcon>
                            <IconMail />
                        </SvgIcon>
                    </IconButton>
                </Tooltip> */}
            </Stack>
            <Divider />
            {isEmpty
                ? (
                    <Box sx={{ p: 2, textAlign: "center", mb: "10px" }}>
                        <Typography variant="subtitle2">
                            There are no notifications
                        </Typography>
                    </Box>
                )
                : (
                    <Box sx={{ maxHeight: 400, overflow: "auto" }} onScroll={handleScroll} >
                        <List disablePadding>
                            {notifications.slice(0, shownNotifications).filter((notification) => !notificationsHidden.includes(notification.id)).map((notification) => (
                                <ListItem
                                    divider
                                    key={notification.id}
                                    sx={{
                                        padding: "5%",
                                        alignItems: 'flex-start',
                                        '&:hover': {
                                            backgroundColor: 'action.hover'
                                        },
                                        '& .MuiListItemSecondaryAction-root': {
                                            top: '24%'
                                        }
                                    }}
                                // secondaryAction={(
                                //     <Tooltip title="Remove">
                                //         <IconButton
                                //             edge="end"
                                //             onClick={() => onHideOne?.(notification.id)}
                                //             size="small"
                                //         >
                                //             <SvgIcon>
                                //                 <IconX />
                                //             </SvgIcon>
                                //         </IconButton>
                                //     </Tooltip>
                                // )}
                                >
                                    {renderContent(notification, ambassadors, admins, onNotificationClick)}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
        </Popover>
    );
};

NotificationsPopover.propTypes = {
    anchorEl: PropTypes.any,
    notifications: PropTypes.array.isRequired,
    notificationsHidden: PropTypes.array.isRequired,
    ambassadors: PropTypes.array.isRequired,
    admins: PropTypes.array.isRequired,
    onNotificationClick: PropTypes.func,
    onClose: PropTypes.func,
    // onMarkAllAsRead: PropTypes.func,
    onHideOne: PropTypes.func,
    open: PropTypes.bool
};
