export function getExtensionFromImageMimeType(mimeType) {
    const mimeToExtension = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'image/gif': 'gif',
        'image/bmp': 'bmp',
        'image/tiff': 'tiff',
        'image/webp': 'webp',
        'image/svg+xml': 'svg',
    };

    // Check if the provided MIME type exists in the mapping
    if (mimeToExtension[mimeType]) {
        return mimeToExtension[mimeType];
    } else {
        // If the MIME type is not recognized as an image type, return null or an appropriate default extension.
        return null; // You can change this to return a default extension like 'jpg' or 'png'.
    }
}

export function textWithClickableLinks(text) {
    if (!text || typeof text !== 'string') {
        return text;
    }
    const urlPattern = /(https:\/\/|http:\/\/|www\.)?[a-zA-Z0-9\-]{2,}(\.[a-zA-Z0-9\-]{2,}){1,}(\/[a-zA-Z0-9\-._~:\/?#[\]@!$&'()*+,;=%]*)?/gi;
    return text.replace(urlPattern, (url) => {
        const href = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
        return `<a style="text-decoration: underline; color: #2a9d8f;" href="${href}" target="_blank">${url}</a>`;
    });
}

function textWithCountryEmoji(text) {
    const flagEmojiPattern = /(?:\uD83C[\uDDE6-\uDDFF]){2}/g;
    const countryEmoji = text.match(flagEmojiPattern);

    if (countryEmoji) {
        const codePoints = countryEmoji[0].codePointAt(0).toString(16) + ' ' + countryEmoji[0].codePointAt(2).toString(16);
        const countryCode = String.fromCodePoint(
            parseInt(codePoints.split(' ')[0], 16) - 0x1F1E6 + 0x41,
            parseInt(codePoints.split(' ')[1], 16) - 0x1F1E6 + 0x41
        );
        return countryCode;
    }
    return null;
}