/**
 * This function determines the status of the opening. It can be one of:
 *  - Completed
 *  - Filled
 *  - Unassigned
 *  - Late
 *  - Absent
 *  - Live
 *  - N/A
 *
 * @param {Object} opening - opening object; see Strapi for relevant fields
 *
 * @returns {string}
 */
export const getOpeningStatus = (opening, shiftType, autoClocking = true) => {
    if (!opening || shiftType === "N/A") {
        return "N/A";
    }

    const assigned = opening.id && opening.attributes;

    if (shiftType === "Scheduled") {
        return assigned ? "Filled" : "Available";
    }
    if (shiftType === "Completed") {
        if (isOpeningComplete(opening, autoClocking)) {
            return "Completed";
        } else if (assigned && opening.attributes.reportedAbsentByCompany) {
            return "Absent";
        } else {
            return assigned ? "Live" : "Unassigned";
        }
    }
    if (shiftType === "Live Now") {
        return assigned ? "Live" : "Unassigned";
    }
    if (shiftType === "Cancelled") return "Cancelled";
    if (shiftType === "Draft") return "--";

    return "N/A";
};

/**
 * This function determines the timesheet status of the opening. It can be one of:
 *  - Confirmed
 *  - Disputed
 *  - Awaiting Approval
 *  - N/A
 *
 * @param {Object} opening - opening object; see Strapi for relevant fields
 *
 * @returns {string}
 */
export const getOpeningTimesheetStatus = (opening, autoClocking = true) => {
    if (!opening || !opening.id || !opening.attributes) {
        return "N/A";
    }

    if (
        opening.attributes.approvedClockIn &&
        opening.attributes.approvedClockOut &&
        opening.attributes.approvedClockIn != opening.attributes.shiftClockIn &&
        opening.attributes.approvedClockOut != opening.attributes.shiftClockOut && !autoClocking
    ) {
        return "Disputed";
    }

    if (!opening.attributes.reportedAbsentByCompany &&
        (!opening.attributes.approvedClockIn ||
            !opening.attributes.approvedClockOut) &&
        (!opening.attributes.resolvedClockIn ||
            !opening.attributes.resolvedClockOut)
    ) {
        return "Awaiting Approval";
    }

    return "Confirmed";
};

export const isOpeningComplete = (opening, autoClocking = true) => {
    return (
        opening.id &&
        opening.attributes && !opening.attributes.reportedAbsentByCompany &&
        // Note: shiftClockOut intentionally left out here
        // because we automatically assign end time of the shift
        // if they do not clock out
        (opening.attributes.shiftClockIn || autoClocking ||
            (opening.attributes.approvedClockIn &&
                opening.attributes.approvedClockOut) ||
            (opening.attributes.resolvedClockIn &&
                opening.attributes.resolvedClockOut))
    );
};
