import { getOpeningTimesheetStatus } from "./openings";

const millisInMins = 1000 * 60;

const getEventTotalWorkedHours = (event, autoClocking = true) => {
    if (!event.attributes || !event.attributes.shifts) return null;

    const { time, shiftDurationMins } = event.attributes;

    const startTime = new Date(Date.parse(time));
    const endTime = new Date(Date.parse(time) + shiftDurationMins * 60 * 1000);

    const filledOpenings = event.attributes.shifts.data;

    const totalWorkedHours = filledOpenings.reduce(
        (accumulator, currentOpening) =>
            accumulator +
            (currentOpening.attributes.reportedAbsentByCompany ? 0 : (getEventDurationHours(
                currentOpening.attributes.resolvedClockIn ||
                currentOpening.attributes.approvedClockIn ||
                (autoClocking ? startTime : currentOpening.attributes.shiftClockIn),
                currentOpening.attributes.resolvedClockOut ||
                currentOpening.attributes.approvedClockOut ||
                currentOpening.attributes.shiftClockOut ||
                endTime
            ))),
        0
    );

    return totalWorkedHours;
};

/**
 * This function determines the type of the shift. It can be one of:
 *  - Live Now
 *  - Scheduled
 *  - Completed
 *  - Cancelled
 *  - Draft
 *  - N/A
 *
 * @param {Object} shift - shift object; see Strapi for relevant fields
 *
 * @returns {string}
 */
export const getEventType = (shift) => {
    if (!shift.id || !shift.attributes) return "N/A";

    if (shift.attributes.status === "draft") {
        return "Draft";
    }
    if (shift.attributes.status === "cancelled") {
        return "Cancelled";
    }

    const curTime = new Date();
    const startTime = new Date(Date.parse(shift.attributes.time));
    const endTime = new Date(
        Date.parse(shift.attributes.time) +
        shift.attributes.shiftDurationMins * millisInMins
    );

    if (curTime > startTime && curTime < endTime) {
        // console.log("LIVE. ", curTime, startTime, endTime);
        return "Live Now";
    }
    if (curTime > endTime) {
        return "Completed";
    }
    if (curTime < startTime) {
        return "Scheduled";
    }
};

/**
 * This function determines the timesheet type of the shift. It can be one of:
 *  - Confirmed
 *  - Disputed
 *  - Awaiting Approval
 *  - N/A
 *
 * @param {Object} shift - shift object; see Strapi for relevant fields
 *
 * @returns {string}
 */
export const getTimesheetType = (shift) => {
    if (getEventType(shift) !== "Completed") return "N/A";
    if (!shift.attributes.shifts) return "N/A";

    if (
        shift.attributes.shifts.data.some(
            (opening) => getOpeningTimesheetStatus(opening) === "Disputed"
        )
    ) {
        return "Disputed";
    }

    if (
        shift.attributes.shifts.data.some(
            (opening) => getOpeningTimesheetStatus(opening) == "Awaiting Approval"
        )
    ) {
        return "Awaiting Approval";
    }

    if (
        shift.attributes.shifts.data.every(
            (opening) => getOpeningTimesheetStatus(opening) === "Confirmed"
        )
    ) {
        return "Confirmed";
    }

    return "N/A";
};

export const eventTimeRangeHumanReadable = (shift, newLine = true) => {
    if (!shift.id || !shift.attributes) return "N/A";

    const dateUTCString = shift.attributes.time;
    const durationMins = shift.attributes.shiftDurationMins;
    const dateStart = new Date(dateUTCString);
    const dateEnd = new Date(dateStart.getTime() + millisInMins * durationMins);

    const timeStart = dateStart
        .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        })
        .replace(/^0+/, "").replace(' ', '');
    const timeEnd = dateEnd
        .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        })
        .replace(/^0+/, "").replace(' ', '');

    return `${timeStart} -${newLine ? '\n' : ''} ${timeEnd}`;
};

export const getEarliestFutureEventInRecurringSeries = (eventPostings, recurringID) => {
    if (!recurringID) return null;

    const events = eventPostings.filter((event) => event.attributes.recurringID === recurringID);
    if (events.length === 0) return null;

    return events.reduce((earliest, current) => {
        if (Date.parse(current.attributes.time) < Date.parse(earliest.attributes.time) && getEventType(current) === "Scheduled") {
            return current;
        }
        return earliest;
    });
};

export const getLatestFutureEventInRecurringSeries = (eventPostings, recurringID) => {
    if (!recurringID) return null;

    const events = eventPostings.filter((event) => event.attributes.recurringID === recurringID);
    if (events.length === 0) return null;

    return events.reduce((latest, current) => {
        if (Date.parse(current.attributes.time) > Date.parse(latest.attributes.time) && getEventType(current) === "Scheduled") {
            return current;
        }
        return latest;
    });
};

export const getRecentTemplates = (eventPostings) => {
    if (!eventPostings || eventPostings.length === 0) return [];

    // Sort the events by createdAt in descending order
    const sortedEvents = [...eventPostings].sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));

    // Filter the sorted events to only include unique events
    const uniqueEvents = sortedEvents.filter((event, index, self) =>
        index === self.findIndex((e) =>
            e.attributes.workType === event.attributes.workType &&
            e.attributes.address === event.attributes.address &&
            e.attributes.workDuties === event.attributes.workDuties &&
            e.attributes.summary === event.attributes.summary &&
            e.attributes.ratePerHourCAD === event.attributes.ratePerHourCAD &&
            e.attributes.totalOpenings === event.attributes.totalOpenings
        )
    );

    // Return the last 10 unique events
    return uniqueEvents.slice(0, 10);
};

export function getEventDurationHours(startTime, endTime) {
    if (
        !startTime ||
        isNaN(Date.parse(startTime)) ||
        !endTime ||
        isNaN(Date.parse(endTime))
    ) {
        return 0;
    }

    const milliseconds = Date.parse(endTime) - Date.parse(startTime);

    //Get hours from milliseconds
    return milliseconds / (1000 * 60 * 60);
}

export const getEventEffectiveHours = (event, autoClocking = true) => {
    if (!event.attributes || !event.attributes.shifts) return 0;

    const { shiftDurationMins, breakDurationMins, totalOpenings } = event.attributes;
    const eventType = getEventType(event);

    const totalWorkedHours = getEventTotalWorkedHours(event) ?? 0;

    const effectiveHours =
        eventType === "Completed"
            ? totalWorkedHours
            : ((shiftDurationMins - breakDurationMins) / 60) * totalOpenings;

    return effectiveHours;
}

export const getEventSubTotal = (event, autoClocking = true) => {
    if (!event.attributes || !event.attributes.shifts) return null;

    const { totalOpenings, ratePerHourCAD } = event.attributes;
    const eventType = getEventType(event);

    const filledOpenings = event.attributes.shifts.data;
    const numFilledOpenings = filledOpenings.length;

    // Used for computation in prices and total hours
    const effectiveOpenings =
        eventType === "Cancelled"
            ? 0
            : eventType === "Scheduled" || eventType === "Draft"
                ? totalOpenings
                : numFilledOpenings;

    const effectiveHours = getEventEffectiveHours(event, autoClocking);

    const subTotal = ratePerHourCAD * effectiveHours;

    return subTotal;
};

export const recurrenceHumanReadable = (event) => {
    if (!event.attributes.recurringID) return "Never";

    if (!event.attributes.repeatsSunday && !event.attributes.repeatsMonday && !event.attributes.repeatsTuesday && !event.attributes.repeatsWednesday && !event.attributes.repeatsThursday && !event.attributes.repeatsFriday && !event.attributes.repeatsSaturday) {
        return "Never";
    }

    if (!event.attributes.repeatsSunday && event.attributes.repeatsMonday && event.attributes.repeatsTuesday && event.attributes.repeatsWednesday && event.attributes.repeatsThursday && event.attributes.repeatsFriday && !event.attributes.repeatsSaturday) {
        return "Every Weekday";
    }

    if (event.attributes.repeatsSunday && event.attributes.repeatsMonday && event.attributes.repeatsTuesday && event.attributes.repeatsWednesday && event.attributes.repeatsThursday && event.attributes.repeatsFriday && event.attributes.repeatsSaturday) {
        return "Every Day";
    }

    const weekdayMap = {
        repeatsSunday: "Sunday",
        repeatsMonday: "Monday",
        repeatsTuesday: "Tuesday",
        repeatsWednesday: "Wednesday",
        repeatsThursday: "Thursday",
        repeatsFriday: "Friday",
        repeatsSaturday: "Saturday",
    };

    let recurrence = "Every";

    for (const weekday in weekdayMap) {
        if (event.attributes[weekday]) {
            recurrence += ` ${weekdayMap[weekday]},`;
        }
    }

    recurrence = recurrence.slice(0, -1);

    return recurrence;
};