import { Suspense } from 'react';

// project imports
import Loader from './Loader';

import * as Sentry from "@sentry/react";

import PropTypes from 'prop-types';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { cloneElement, Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        if (process.env.NODE_ENV !== 'development') {
            return { hasError: true };
        }

        console.log("IS DEVERROR", error);

        // throw error;
    }

    componentDidCatch(error, info) {
        console.error(error, info);
        Sentry.captureException(error, { extra: info });

        if (this.props.onError && typeof this.props.onError === 'function') {
            this.props.onError(error, info);
        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <Typography variant="h3" align="center" sx={{ mt: 10 }}>
                    Something went wrong. Please try refreshing the page.
                </Typography>
            );
        }

        // Add a key that depends on the hasError state
        return cloneElement(this.props.children, { key: this.state.hasError });
    }
}

export const Error = (props) => {
    const { statusCode, title } = props;
    const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const text = `${statusCode}: ${title}`;

    return (
        <Box
            component="main"
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                py: '80px'
            }}
        >
            <Container maxWidth="lg">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 6
                    }}
                >
                    <Typography
                        align="center"
                        variant={mdUp ? 'h1' : 'h4'}
                    >
                        {text}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

Error.propTypes = {
    statusCode: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
};

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const FailSafeLoadable = (Component) => (props) =>
(
    <ErrorBoundary {...props}>
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    </ErrorBoundary>
);

export default FailSafeLoadable;
