import PropTypes from "prop-types";

import { logEvent } from "firebase/analytics";
import { analytics as firebaseInstance } from "utils/firebase";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, Card, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import {
    SET_LEFT_MENU,
} from "store/actions";

// project imports
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import ProfileSection from "./ProfileSection";
import { NotificationsButton } from "./notifications-button";
import NotificationSection from "./NotificationSection";

// assets
import { IconMenu2 } from "@tabler/icons-react";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import { useEffect } from "react";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, handlePostShiftDrawerOpen }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const account = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const { userID, championID } = account;
    const isChampion = Boolean(championID);

    const { shiftPostings: allChampions } = useSelector((state) => state.shifts);
    const invitedChampion = allChampions.length > 0 || true; // TODO remove (disable funneling screen)

    useEffect(() => {
        if (!matchDownMd) {
            dispatch({ type: SET_LEFT_MENU, leftOpened: true });
        }
    }, [matchDownMd]);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 238,
                    display: "flex",
                    [theme.breakpoints.down("md")]: {
                        width: "auto",
                    },
                }}
            >
                <Box
                    component="span"
                    sx={{
                        display: { xs: "none", md: "block" },
                        flexGrow: 1,
                        marginTop: "5px",
                    }}
                >
                    <LogoSection dark width={(isChampion || !invitedChampion) ? "220" : null} />
                </Box>
                {!isChampion && invitedChampion && (
                    /* Inactive for now */
                    <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: "all .2s ease-in-out",
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                "&:hover": {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light,
                                },
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                )}
            </Box>
            {/* header search */}
            <LogoSection hide={matchDownMd} dark width={!matchDownMd ? null : "0"} />
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />
            {/* notification & profile */}
            <a target="_blank" href="https://discord.com/channels/1198313010884780104">
                <Card
                    sx={{
                        p: "10px",
                        pr: "15px",
                        pb: "15px",
                        mr: "15px",
                        background: "transparent",
                        color: theme.palette.primary.dark,
                        cursor: "pointer",
                        background: theme.palette.primary.light,
                        ":hover": {
                            background: theme.palette.primary.main,
                            color: "white",
                        },
                        // width: "85%",
                    }}
                    onClick={() => {
                        logEvent(firebaseInstance, "support_requested", {
                            origin: "header",
                            isChampion,
                            userID,
                        });
                    }}
                >
                    {" "}
                    <SupportAgentOutlinedIcon sx={{ mr: "15px", mb: "-7px" }} />
                    24/7 Support
                </Card>
            </a>
            <NotificationsButton />
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    handlePostShiftDrawerOpen: PropTypes.func,
};

export default Header;
