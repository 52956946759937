// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
    rules: [],
};

// ==============================|| RULES REDUCER ||============================== //

const rulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RULES_FETCH_SUCCEEDED:
            console.log("Fetch rules reducer. Action = ", action);
            const rulesToAdd = action.rules ? action.rules.filter(
                (rule) => !(state.rules.filter(existing => existing.id === rule.id).length > 0)
            ) : [];

            const newRuleSet = [...state.rules, ...rulesToAdd];

            return {
                ...state,
                rules: newRuleSet,
            };
        case actionTypes.RULES_FETCH_FAILED:
            // TODO log and record errors for all reducers
            // console.log(action.message);
            return state;
        case actionTypes.RULES_ADD_OR_UPDATE:
            const ruleToUpdate = state.rules.find(
                (rule) => action.updatedRule ? rule.id === action.updatedRule.id : false
            );

            if (ruleToUpdate) {
                NestedObjectAssign(ruleToUpdate, action.updatedRule);
            }
            const addedRule = ruleToUpdate ? [] : [action.updatedRule];

            const newRules = [...state.rules, ...addedRule];

            return {
                ...state,
                rules: newRules,
            };

        case actionTypes.RULES_DELETE:
            const filteredRules = state.rules.filter(
                (rule) => rule.id != action.deletedRuleID
            );

            return {
                ...state,
                rules: filteredRules,
            };

        default:
            return state;
    }
};

export default rulesReducer;
