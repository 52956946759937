import PropTypes from "prop-types";

import { useState, useRef, useEffect, forwardRef } from "react";

import * as Sentry from "@sentry/react";

import { useDispatch, useSelector } from "react-redux";
import {
    SET_POST_EVENT_DRAWER,
    SET_VIEW_EVENT_DRAWER,
    EVENTS_ADD_OR_UPDATE,
    SET_VIEW_TIMESHEET_DRAWER,
} from "store/actions";

import { useTheme } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Box,
    Drawer,
    useMediaQuery,
    Chip,
    Stack,
    Typography,
    Grid,
    Avatar,
    ClickAwayListener,
    Collapse,
    List,
    FormControl,
    Select,
    OutlinedInput,
    MenuItem,
    Link,
    Checkbox,
} from "@mui/material";

import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { color, styled } from "@mui/system";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import axios from "axios";
import dayjs from "dayjs";

import {
    IconCircleX,
    IconCirclePlus,
    IconCircleMinus,
    IconArrowLeft,
    IconExternalLink,
    IconAlertCircle,
} from "@tabler/icons-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckIcon from "@mui/icons-material/Check";

import { getEarliestFutureEventInRecurringSeries, getEventDurationHours, getEventType, getEventSubTotal, getEventEffectiveHours } from "../../../utils/events";
import { getOpeningStatus, isOpeningComplete } from "../../../utils/openings";
import EWCircularProgress from "../../../ui-component/extended/EWCircularProgress";
import EventStatusBadge from "../../../ui-component/EventStatusBadge";

import AgreementListItem from "../../../ui-component/lists/AgreementListItem";
import "../../../ui-component/lists/AgreementListItem/agreement-list.scss";
import FailSafeAvatar from "ui-component/avatars/FailSafeAvatar";
import { getInitials } from "utils/names";
import { getChampionStatus } from "utils/shifts";
import { textWithClickableLinks } from "utils/media";

export const googleMapsURLParams =
    "&zoom=18&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=800x375";

const emptyOpening = {
    id: 0,
};

const container = window !== undefined ? () => window.document.body : undefined;

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function getShiftDurationHours(startTime, endTime) {
    if (
        !startTime ||
        isNaN(Date.parse(startTime)) ||
        !endTime ||
        isNaN(Date.parse(endTime))
    ) {
        return 0;
    }

    const milliseconds = Date.parse(endTime) - Date.parse(startTime);

    //Get hours from milliseconds
    return milliseconds / (1000 * 60 * 60);
}

const QuestionsBar = ({ sx }) => (
    <Stack
        direction="row"
        mt="20px"
        pt="2%"
        pl="3%"
        pr="10px"
        pb="10px"
        sx={{
            background: (theme) => theme.palette.secondary.dark,
            borderRadius: "17px",
            width: "90%",
            justifyContent: "space-between",
            ...sx,
        }}
    >
        <Typography
            fontSize="1.2rem"
            fontWeight="700"
            color="white"
            alignSelf="center"
        >
            Questions?
        </Typography>
        <Chip
            sx={{
                borderRadius: "50px",
                background: "white",
                color: (theme) => theme.palette.secondary.dark,
                cursor: "pointer",
                borderWidth: "1px",
                borderColor: "white",
                borderStyle: "solid",
                p: "5px",
                alignSelf: "center",
                // pb: "5px",
                "&:hover": {
                    background: "#f5f5f5",
                },
            }}
            label={
                <Typography
                    sx={{
                        color: (theme) => theme.palette.secondary.dark,
                    }}
                >
                    Contact Us
                </Typography>
            }
            onClick={() => {
                // Go to the discord channel
                window.open("https://discord.gg/qKAEBS4Fst");
            }}
        />
    </Stack>
);

const InfoTooltip = styled(({ isMobile, openState, className, ...props }) => (
    <>
        {isMobile ? (
            <Tooltip
                disableFocusListener
                disableTouchListener
                open={openState}
                {...props}
                classes={{ popper: className }}
            />
        ) : (
            <Tooltip {...props} classes={{ popper: className }} hidden={isMobile} />
        )}
    </>
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#C7E1F2",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#C7E1F2",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        padding: "5%",
    },
}));

const ViewEventDrawer = ({ drawerOpen, drawerClose, eventID = -1 }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));

    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [subtotalToolTipOpen, setSubtotalToolTipOpen] = useState(false);
    const [detailsExpanded, setDetailsExpanded] = useState(false);
    const [cancelOpeningID, setCancelOpeningID] = useState(0);
    const [cancelMode, setCancelMode] = useState(false);
    const [viewedOpening, setViewedOpening] = useState({
        id: -1,
    });
    const [openingsCancelTooltips, setOpeningsCancelTooltips] = useState(null);

    // TODO populate from Strapi
    const [checklistItems, setchecklistItems] = useState([
        {
            id: 0,
            checklistItem:
                "Cancelling this shift will cancel all assignments associated to this shift.",
            pressed: false,
        },
        {
            id: 1,
            checklistItem:
                "Cancelling this shift will immediately prevent ambassadors from signing up for this shift.",
            pressed: false,
        },
        {
            id: 2,
            checklistItem:
                "If this shift is part of a recurring series, cancelling this shift will have no impact on the status of other shifts in the series.",
            pressed: false,
        },
        // {
        //   id: 2,
        //   checklistItem:
        //     "Cancelling this event may make ambassadors who have already signed up for it less likely to sign up for future events with your company.",
        //   pressed: false,
        // },
    ]);

    const [availableAmbassadorsLoading, setAvailableAmbassadorsLoading] = useState(false);
    const [availableAmbassadors, setAvailableAmbassadors] = useState([]);

    // console.log("TARGET EVENT ID: ", eventID);

    const dispatch = useDispatch();

    const account = useSelector((state) => state.account);
    const { firstName, lastName, userID, companyID, accessToken, paymentsEnabled, autoClocking, sandboxUser } = account;

    const { shiftPostings: allAmbassadors, adminUsers, firstFetchMade } = useSelector((state) => state.shifts);

    const { eventPostings: newEvents } = useSelector((state) => state.events);
    // console.log("QUARAM", newShifts);
    const targetEvent =
        newEvents.length > 0 && newEvents.some((event) => event.id === eventID)
            ? newEvents.find((event) => event.id === eventID)
            : {
                id: 0,
                attributes: {
                    workType: "",
                    summary: "",
                    workDuties: "",
                    recurringID: 0,
                    untilTime: dayjs().toISOString(),
                    breakDurationMins: 0,
                    shiftDurationMins: 0,
                    ratePerHourCAD: 0,
                    time: dayjs().toISOString(),
                    totalOpenings: 0,
                    address: "N/A",
                    lat: 19.278093,
                    lng: -77.337419,
                    status: "active",
                    shifts: { data: [] },
                    ownedBy: 0,
                },
            };

    const {
        time,
        workType,
        address,
        workDuties,
        summary,
        recurringID,
        untilTime,
        shiftDurationMins,
        breakDurationMins,
        ratePerHourCAD,
        totalOpenings,
        shifts,
        lat,
        lng,
        timezone,
        ownedBy,
    } = targetEvent.attributes;

    const eventType = getEventType(targetEvent);

    const startTime = new Date(Date.parse(time));
    const endTime = new Date(Date.parse(time) + shiftDurationMins * 60 * 1000);

    // console.log("SHIFTS ", shifts);

    const filledOpenings = shifts.data;
    const numFilledOpenings = filledOpenings.length;
    // TODO proper error checking to ensure totalOpenings is > 0 and > number of filled shifts
    const filledEventsPercent =
        totalOpenings > 0
            ? totalOpenings > numFilledOpenings
                ? (numFilledOpenings / totalOpenings) * 100
                : 100
            : 0;

    const subTotal = getEventSubTotal(targetEvent);

    // console.log("FILLED PERCENT", filledShiftsPercent);

    const unfilledOpenings =
        totalOpenings - numFilledOpenings > 0
            ? Array(totalOpenings - numFilledOpenings).fill(emptyOpening)
            : [];

    const allOpenings = [...filledOpenings, ...unfilledOpenings];
    const alreadyAssignedAmbassadors = filledOpenings.map((shift) => shift?.attributes?.user?.data);
    const allActiveAmbassadorsNotAssignedYet = allAmbassadors.filter(
        (ambassador) => getChampionStatus(ambassador) === "Active" &&
            !alreadyAssignedAmbassadors.some(
                (assignedAmbassador) => assignedAmbassador && assignedAmbassador.id === ambassador.id
            )
    );

    const ownedByUser = adminUsers.find((adminUser) => adminUser.id === ownedBy);
    console.log("OWNED BY USER: ", ownedByUser);

    const isUserOwner = Number(ownedBy) === Number(userID);
    const notAllowedToEdit = sandboxUser && !isUserOwner;

    const updateNumOpenings = (numOpenings) => {
        if (numOpenings === totalOpenings) return;
        if (eventType !== "Scheduled" && eventType !== "Draft") return;

        if (notAllowedToEdit) {
            setErrMsg("You can't edit existing shifts in sandbox mode. Please create your own shift instead.");
            return;
        }

        setLoading(true);

        const actionMsg =
            numOpenings > totalOpenings ? "added an opening" : "deleted an opening";

        const apiURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/postings/${targetEvent.id}?populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups`;

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const apiCb = (response) => {
            console.log(response);
            const { data } = response;
            if (!data || !data.data) {
                console.log("Unable to parse response from server");
                throw new Error("Unable to parse response from server");
            }
            // Add updated event to our redux store
            dispatch({
                type: EVENTS_ADD_OR_UPDATE,
                updatedEvents: [{
                    id: data.data.id,
                    attributes: {
                        ...data.data.attributes,
                    },
                }],
            });
            setSuccessMsg(
                `Successfully ${actionMsg}. There are now ${numOpenings} openings for this shift.`
            );
        };

        const apiErr = (e) => {
            Sentry.captureException(e);
            setErrMsg("Please check your connection and try again.");
            console.log(e);
        };

        axios
            .put(
                apiURL,
                {
                    data: {
                        totalOpenings: numOpenings,
                    },
                },
                apiHeaders
            )
            .then(apiCb)
            .catch(apiErr)
            .finally(setLoading(false));
    };

    // const deleteFilledOpening = () => {
    //   axios
    //     .put(
    //       apiURL,
    //       {
    //         data: {
    //           totalOpenings: numOpenings,
    //         },
    //       },
    //       apiHeaders
    //     )
    //     .then(apiCb)
    //     .catch(apiErr)
    //     .finally(setLoading(false));
    // };

    const cancelEvent = () => {
        if (notAllowedToEdit) {
            setErrMsg("You can't edit existing shifts in sandbox mode. Please create your own shift instead.");
            return;
        }

        setLoading(true);

        const apiURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/postings/${targetEvent.id}?populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups`;

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const apiCb = (response) => {
            console.log(response);
            const { data } = response;
            if (!data || !data.data) {
                console.log("Unable to parse response from server");
                return;
            }
            // Add updated event to our redux store
            dispatch({
                type: EVENTS_ADD_OR_UPDATE,
                updatedEvents: [{
                    id: data.data.id,
                    attributes: {
                        ...data.data.attributes,
                    },
                }],
            });
            setCancelMode(false);
        };

        const apiErr = (e) => {
            Sentry.captureException(e);
            setErrMsg("Please check your connection and try again.");
            console.log(e);
        };

        axios
            .put(
                apiURL,
                {
                    data: {
                        status: "cancelled",
                    },
                },
                apiHeaders
            )
            .then(apiCb)
            .catch(apiErr)
            .finally(setLoading(false));
    };

    const resetEverything = () => {
        setLoading(false);
        setErrMsg("");
        setSubtotalToolTipOpen(false);
        setDetailsExpanded(false);
        setCancelMode(false);
        let unpressedChecklist = checklistItems;
        unpressedChecklist.forEach((checklistItem) => {
            checklistItem.pressed = false;
        });
        setchecklistItems(unpressedChecklist);
        setViewedOpening({
            id: -1,
        });
        setOpeningsCancelTooltips(null);
        setAvailableAmbassadors([]);
        setAvailableAmbassadorsLoading(false);
    };

    const fetchAvailableAmbassadors = async () => {
        setAvailableAmbassadorsLoading(true);
        try {
            const response = await axios
                .post(
                    `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/users/available`,
                    {
                        isRecurring: false,
                        weekdays: [],
                        startTime,
                        endTime,
                        timezone: timezone ?? "America/Toronto",
                        companyID,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

            const { data } = response;
            if (!data) {
                console.log("Unable to parse available ambassadors response from server");
                return;
            }

            setAvailableAmbassadors(data);
        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }
        setAvailableAmbassadorsLoading(false);
    };

    const cancelShift = async () => {
        if (cancelOpeningID === 0) return;

        if (notAllowedToEdit) {
            setErrMsg("You can't edit existing shifts in sandbox mode. Please create your own shift instead.");
            return;
        }

        setAvailableAmbassadorsLoading(true);
        try {
            const response = await axios
                .delete(
                    `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/shifts/${cancelOpeningID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

            const { data } = response;
            if (!data) {
                console.log("Unable to parse available ambassadors response from server");
                return;
            }

            dispatch({
                type: EVENTS_ADD_OR_UPDATE,
                updatedEvents: [{
                    id: targetEvent.id,
                    attributes: {
                        ...targetEvent.attributes,
                        shifts: {
                            data: targetEvent.attributes.shifts.data.filter(
                                (shift) => shift.id !== cancelOpeningID
                            ),
                        },
                    },
                }],
            });

            updateNumOpenings(totalOpenings - 1);

        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }

        setAvailableAmbassadorsLoading(false);
    };

    const assignAmbassadorToOpening = async (ambassadorID) => {
        if (notAllowedToEdit) {
            setErrMsg("You can't edit existing shifts in sandbox mode. Please create your own shift instead.");
            return;
        }

        setAvailableAmbassadorsLoading(true);
        try {
            const response = await axios
                .post(
                    `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/shifts?populate[user]=true`,
                    {
                        data: {
                            posting: targetEvent.id,
                            user: ambassadorID,
                            forcedAssigned: true,
                        }
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

            const { data } = response;
            if (!data) {
                console.log("Unable to parse available ambassadors response from server");
                return;
            }

            dispatch({
                type: EVENTS_ADD_OR_UPDATE,
                updatedEvents: [{
                    id: targetEvent.id,
                    attributes: {
                        ...targetEvent.attributes,
                        shifts: {
                            data: [
                                ...targetEvent.attributes.shifts.data,
                                data.data,
                            ],
                        },
                    },
                }],
            });

        } catch (e) {
            Sentry.captureException(e);
            console.error(e);
        }

        setAvailableAmbassadorsLoading(false);
    };

    const getAmbassadorAvatar = (ambassador) => {
        // Search through allAmbassadors for the ambassador with the same ID
        // If found, return the avatar

        console.log("AMBASSADOR: ", ambassador);

        const ambassadorID = ambassador.id;
        const foundAmbassador = allAmbassadors.find(
            (ambassador) => ambassador.id === ambassadorID
        );

        console.log("FOUND AMBASSADOR: ", foundAmbassador);

        if (foundAmbassador && foundAmbassador.avatar) {
            return foundAmbassador.avatar.url;
        }
    };

    useEffect(() => {
        if (drawerOpen && targetEvent.id > 0) {
            resetEverything();
            fetchAvailableAmbassadors();
        }
    }, [drawerOpen, targetEvent]);

    useEffect(() => {
        if (drawerOpen) {
            resetEverything();
        }
    }, [eventID]);

    useEffect(() => {
        if (totalOpenings > numFilledOpenings && targetEvent.id > 0) {
            fetchAvailableAmbassadors();
        }
    }, [totalOpenings, numFilledOpenings, targetEvent]);

    useEffect(() => {
        const fetchTargetEvent = async () => {
            // Try to fetch the event if it's not in our redux store
            if (eventID > 0 && !newEvents.some((event) => event.id === eventID) && drawerOpen) {
                setLoading(true);
                try {
                    const response = await axios
                        .get(
                            `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/postings/${eventID}?populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups`,
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        );

                    const { data } = response;
                    if (!data || !data.data || data.data.id !== eventID) {
                        console.log("Unable to parse event response from server");
                        return;
                    }

                    dispatch({
                        type: EVENTS_ADD_OR_UPDATE,
                        updatedEvents: [data.data],
                    });
                }
                catch (e) {
                    Sentry.captureException(e);
                    console.error(e);
                }
                setLoading(false);
            }
        };

        fetchTargetEvent();
    }, [drawerOpen, dispatch, newEvents]);

    console.log("RECURRING ID: ", recurringID);
    console.log("UNTIL DATE: ", untilTime);
    console.log("EARLIEST IN SERIES", getEarliestFutureEventInRecurringSeries(newEvents, recurringID));

    // Determine if untilTime is in the future
    const untilTimeObj = new Date(Date.parse(untilTime));
    const untilTimeInFuture = untilTimeObj > new Date();

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: "auto" }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                anchor="right"
                open={drawerOpen}
                onClose={(e) => {
                    resetEverything();
                    drawerClose(e);
                }}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: matchUpMd ? "612px" : "100vw",
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: "none",
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(errMsg)}
                    autoHideDuration={6000}
                    onClose={() => setErrMsg("")}
                >
                    <Alert
                        onClose={() => setErrMsg("")}
                        severity="error"
                        sx={{ width: "100%", whiteSpace: "pre-line" }}
                    >
                        Something went wrong.{`\n`}
                        {errMsg}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(successMsg)}
                    autoHideDuration={6000}
                    onClose={() => setSuccessMsg("")}
                >
                    <Alert
                        onClose={() => setSuccessMsg("")}
                        severity="success"
                        sx={{ width: "100%", whiteSpace: "pre-line" }}
                    >
                        {successMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    fullScreen={matchDownMd}
                    open={cancelOpeningID > 0}
                    onClose={() => setCancelOpeningID(0)}
                    aria-labelledby="responsive-dialog-title"
                    sx={{
                        '.MuiPaper-root': {
                            padding: "3%",
                        },
                    }}
                >
                    <DialogTitle
                        variant="h2"
                        id="responsive-dialog-title"
                        color={theme.palette.primary.dark}
                    >
                        {
                            "Deleting this opening will immediately remove any ambassadors who have signed up."
                        }
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h3" whiteSpace="pre-line">
                            This action cannot be undone.
                            {totalOpenings > numFilledOpenings &&
                                ` Consider removing one of the unfilled openings instead.`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            variant="contained"
                            size="large"
                            onClick={() => setCancelOpeningID(0)}
                        >
                            Back
                        </Button>
                        <LoadingButton
                            color="error"
                            variant="outlined"
                            size="large"
                            loading={loading}
                            // loadingPosition="start"
                            // startIcon={<DeleteForeverIcon />}
                            onClick={() => {
                                cancelShift();
                                setCancelOpeningID(0);
                            }}
                            autoFocus
                        >
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                {
                    Boolean(targetEvent.attributes.lat && targetEvent.attributes.lng) && (
                        <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&center=${targetEvent.attributes.lat},${targetEvent.attributes.lng}&markers=color:white|${targetEvent.attributes.lat},${targetEvent.attributes.lng}${googleMapsURLParams}`}
                        />
                    )
                }
                <Link
                    href="http://discord.com/channels/1198313010884780104"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                >
                    <Stack
                        direction="row"
                        sx={{
                            position: "absolute",
                            alignItems: "center",
                            justifyContent: "center",
                            top: matchUpMd ? "2rem" : "1rem",
                            right: matchUpMd ? "2rem" : "1rem",
                            color: "white",
                            cursor: "pointer",
                        }}
                    >
                        <HelpOutlineIcon style={{ fontSize: "23px" }} />{" "}
                        <Typography
                            variant="subtitle1"
                            sx={{
                                textDecoration: "none",
                                color: "white",
                                fontWeight: 600,
                                fontSize: "17px",
                            }}
                            marginLeft="5px"
                        >
                            24/7 Live Support
                        </Typography>
                    </Stack>
                </Link>
                <Chip
                    sx={{
                        height: "40px",
                        width: "60px",
                        borderRadius: "50px",
                        background: "white",
                        color: "white",
                        position: "absolute",
                        top: matchUpMd ? "2rem" : "1rem",
                        left: matchUpMd ? "2rem" : "1rem",
                        pt: "5px",
                        cursor: "pointer",
                        borderWidth: "1px",
                        borderColor: "white",
                        borderStyle: "solid",
                        "&:hover": {
                            background: theme.palette.grey[100],
                        },
                    }}
                    label={<IconArrowLeft color={theme.palette.primary.main} />}
                    onClick={() => {
                        resetEverything();
                        if (cancelMode) {
                            setCancelMode(false);
                        } else {
                            drawerClose();
                        }
                    }}
                />
                {loading && (
                    <Box sx={{ alignSelf: "center" }}>
                        <CircularProgress size="4rem" color="primary" mt="50%" />
                    </Box>
                )}
                {cancelMode && !loading && (
                    <>
                        <Stack padding="5%" pb="0" pt={Boolean(targetEvent.attributes.lat && targetEvent.attributes.lng) ? "5%" : "100px"}>
                            <Stack direction="row" mb="20px">
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: "2.5rem",
                                        mr: "10px",
                                    }}
                                >
                                    😔
                                </Typography>
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: "2.5rem",
                                        color: theme.palette.primary.dark,
                                    }}
                                >
                                    Cancel Your Shift
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 100,
                                        color: theme.palette.primary.main,
                                        ml: "1.5rem",
                                        alignSelf: "center",
                                    }}
                                >
                                    {`ID: ${targetEvent.id}`}
                                </Typography>
                            </Stack>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 100,
                                    color: "darkgrey",
                                }}
                            >
                                Tap the checkmark after reading and agreeing to each item.
                            </Typography>
                            <List className="agreement-list" lines="none">
                                {checklistItems.map((checklistItem) => (
                                    <AgreementListItem
                                        text={checklistItem.checklistItem}
                                        key={checklistItem.id}
                                        pressed={checklistItem.pressed}
                                        handleClick={() => {
                                            let newChecklistItems = [...checklistItems];
                                            newChecklistItems.find(
                                                (item) => item.id === checklistItem.id
                                            ).pressed = !checklistItem.pressed;
                                            setchecklistItems(newChecklistItems);
                                        }}
                                    />
                                ))}
                            </List>
                        </Stack>
                        <Stack
                            direction="row"
                            p="5%"
                            pt="7%"
                            pb="10%"
                            backgroundColor={theme.palette.warning.light}
                            justifyContent="space-between"
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 100,
                                    alignSelf: "center",
                                }}
                            >
                                To continue, read and tap all items above.
                            </Typography>
                            <Chip
                                sx={{
                                    height: "55px",
                                    pl: "17px",
                                    pr: "17px",
                                    alignItems: "center",
                                    borderRadius: "27px",
                                    transition: "all .2s ease-in-out",
                                    borderColor: theme.palette.error.main,
                                    backgroundColor: theme.palette.error.main,
                                    color: "white",
                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                        borderColor: theme.palette.error.main,
                                        background: `${theme.palette.error.dark}!important`,
                                        color: "white",
                                        "& svg": {
                                            stroke: theme.palette.primary.light,
                                        },
                                    },
                                    "& .MuiChip-label": {
                                        lineHeight: 0,
                                    },
                                }}
                                label={<h2>Cancel Shift</h2>}
                                variant="outlined"
                                // ref={anchorRef}
                                // aria-controls={open ? "menu-list-grow" : undefined}
                                aria-haspopup="true"
                                disabled={checklistItems.some((item) => !item.pressed)}
                                onClick={cancelEvent}
                            />
                        </Stack>
                    </>
                )}
                {!cancelMode && !loading && (
                    <Box
                        sx={{
                            backgroundColor: theme.palette.secondary.dark,
                            padding: "5%",
                            paddingTop: Boolean(targetEvent.attributes.lat && targetEvent.attributes.lng) ? "5%" : "100px",
                        }}
                    >
                        <Grid
                            container
                            spacing={{ xs: 2, md: 2 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            mb="25px"
                        >
                            <Grid item md={6} sx={{ alignSelf: "center" }}>
                                <Stack
                                    direction="row"
                                    display="flex"
                                    justifyContent="space-between"
                                    spacing={2}
                                    sx={{
                                        alignItems: "center",
                                        justifyContent: "left",
                                    }}
                                >
                                    <EventStatusBadge eventType={eventType} />
                                    <Typography
                                        variant="h3"
                                        color="darkgray"
                                        fontWeight="100"
                                    >{`ID: ${targetEvent.id}`}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item md={6}>
                                <Stack
                                    direction="row"
                                    display="flex"
                                    justifyContent="space-between"
                                    spacing={2}
                                    sx={{
                                        alignItems: "center",
                                        justifyContent: "right",
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="white"
                                        fontWeight="100"
                                        sx={{
                                            float: "left",
                                            verticalAlign: "middle",
                                        }}
                                    >
                                        Owned By
                                    </Typography>
                                    <Box
                                        sx={{
                                            float: "right",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Tooltip title={`${ownedByUser?.firstName} ${ownedByUser?.lastName}`}>
                                            <div>
                                                <FailSafeAvatar
                                                    sx={{
                                                        ...theme.typography.mediumAvatar,
                                                        margin: "8px!important",
                                                        cursor: "pointer",
                                                        color: "inherit",
                                                    }}
                                                    aria-controls="menu-list-grow"
                                                    aria-haspopup="true"
                                                    profilePicURL={ownedByUser?.avatar?.url}
                                                    cartoonOptions={ownedByUser?.avatarOptions}
                                                    firstName={ownedByUser?.firstName}
                                                    lastName={ownedByUser?.lastName}
                                                />
                                            </div>
                                        </Tooltip>
                                    </Box>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography
                                    component="span"
                                    variant="h1"
                                    sx={{
                                        color: "white",
                                        fontSize: "45px",
                                    }}
                                >
                                    Shift Details
                                </Typography>
                            </Grid>
                            <Grid item md={4} sx={{ justifyContent: "right" }}>
                                <Stack
                                    display="flex"
                                    justifyContent="flex-end"
                                    sx={{
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: theme.palette.success.light,
                                            fontSize: "35px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        $
                                        {subTotal.toLocaleString("en-US", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </Typography>
                                    <Typography color="blanchedalmond">
                                        Total {eventType === "Scheduled" ? "Budgeted" : "Spent"}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 2 }}
                            columns={{ xs: 3, sm: 8, md: 12 }}
                        >
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Shift Date
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {new Date(Date.parse(time))
                                        .toLocaleString("en", {
                                            month: "long",
                                            day: "numeric",
                                        })
                                        .split(" ")
                                        .reverse()
                                        .join(" ")}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Start
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {startTime.toLocaleTimeString("en", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    End
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {endTime.toLocaleTimeString("en", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={12} md={12}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Location
                                </Typography>
                                <Stack direction="row" alignItems="flex-end" spacing={1}>
                                    <Typography variant="h4" sx={{ color: "white" }}>
                                        {address}{" "}
                                        {lat && lng && (
                                            <IconExternalLink
                                                height="1rem"
                                                width="1.2rem"
                                                color="white"
                                                cursor="pointer"
                                                onClick={() =>
                                                    window.open(
                                                        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
                                                        "_blank"
                                                    )
                                                }
                                            />
                                        )}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Openings
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {totalOpenings}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Total Hours
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {getEventEffectiveHours(targetEvent).toFixed(2)}
                                </Typography>
                            </Grid>
                            {
                                paymentsEnabled && (
                                    <Grid item xs={1} md={3}>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                        >
                                            Hourly Rate
                                        </Typography>
                                        <Typography variant="h4" sx={{ color: "white" }}>
                                            $
                                            {ratePerHourCAD.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}{" "}
                                            CAD
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Collapse in={detailsExpanded}>
                            <Grid container mt="22px !important" spacing={{ xs: 2, md: 2 }}>
                                <Grid item xs={12} md={12}>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                    >
                                        Summary
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: "white", whiteSpace: "pre-line" }}
                                        dangerouslySetInnerHTML={{
                                            __html: textWithClickableLinks(summary),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                    >
                                        Notes / Instructions
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{ color: "white", whiteSpace: "pre-line" }}
                                        dangerouslySetInnerHTML={{
                                            __html: textWithClickableLinks(workDuties),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>

                        <Grid
                            container
                            spacing={{ xs: 2, md: 1.5 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            mt="10px !important"
                            sx={{
                                justifyContent: "flex-end"
                            }}
                        >
                            <Grid item xs={4} md={3} sx={{ alignSelf: "center" }}>
                                <Typography
                                    variant="h4"
                                    color="#2A9D8F"
                                    sx={{
                                        textDecoration: "underline",
                                        alignItems: "center",
                                        justifyContent: "left",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setDetailsExpanded(!detailsExpanded)}
                                >
                                    {detailsExpanded ? "Less" : "More"} Details
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md={3.5}>
                                <Chip
                                    sx={{
                                        display: eventType === "Completed" ? "flex" : "none",
                                        width: "100%",
                                        borderRadius: "50px",
                                        background: "transparent",
                                        color: "white",
                                        cursor: "pointer",
                                        borderWidth: "1px",
                                        borderColor: "white",
                                        borderStyle: "solid",
                                        pt: "5px",
                                        pb: "5px",
                                    }}
                                    label={<h4 style={{ fontWeight: 400 }}>View Time Sheet</h4>}
                                    onClick={() => {
                                        resetEverything();
                                        dispatch({
                                            type: SET_VIEW_EVENT_DRAWER,
                                            viewEventID: -1,
                                        });
                                        dispatch({
                                            type: SET_VIEW_TIMESHEET_DRAWER,
                                            viewTimesheetShiftID: targetEvent.id,
                                        });
                                    }}
                                />
                                <Chip
                                    sx={{
                                        display: !!recurringID && untilTimeInFuture && eventType === "Scheduled" && !!getEarliestFutureEventInRecurringSeries(newEvents, recurringID) ? "flex" : "none",
                                        width: "100%",
                                        borderRadius: "50px",
                                        background: "transparent",
                                        color: "white",
                                        cursor: "pointer",
                                        borderWidth: "1px",
                                        borderColor: "white",
                                        borderStyle: "solid",
                                        pt: "5px",
                                        pb: "5px",
                                        '&[aria-controls="menu-list-grow"], &:hover': {
                                            background: `${theme.palette.primary.main}!important`,
                                            color: "white",
                                        },
                                        '& .MuiChip-label': {
                                            pl: "11px !important",
                                        },
                                    }}
                                    label={<h4 style={{ fontWeight: 400 }}>View Recurring Series</h4>}
                                    onClick={() => {
                                        resetEverything();
                                        dispatch({
                                            type: SET_VIEW_EVENT_DRAWER,
                                            viewEventID: -1,
                                        });
                                        dispatch({
                                            type: SET_POST_EVENT_DRAWER,
                                            postEventOpened: true,
                                            postEventID: targetEvent.id,
                                            recurringSeries: true,
                                        });
                                    }}
                                />
                            </Grid>
                            {eventType !== "Cancelled" && (
                                <>
                                    <Grid item xs={2} md={3}>
                                        <Chip
                                            sx={{
                                                width: "100%",
                                                borderRadius: "50px",
                                                background: "transparent",
                                                color: "white",
                                                cursor: "pointer",
                                                borderWidth: "1px",
                                                borderColor: "white",
                                                borderStyle: "solid",
                                                pt: "5px",
                                                pb: "5px",
                                                '&[aria-controls="menu-list-grow"], &:hover': {
                                                    background: `${theme.palette.primary.main}!important`,
                                                    color: "white",
                                                },
                                            }}
                                            label={<h4 style={{ fontWeight: 400 }}>Edit Shift Details</h4>}
                                            onClick={() => {
                                                resetEverything();
                                                dispatch({
                                                    type: SET_VIEW_EVENT_DRAWER,
                                                    viewEventID: -1,
                                                });
                                                dispatch({
                                                    type: SET_POST_EVENT_DRAWER,
                                                    postEventOpened: true,
                                                    postEventID: targetEvent.id,
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={2.5}>
                                        <Chip
                                            sx={{
                                                width: "100%",
                                                borderRadius: "50px",
                                                background: theme.palette.error.dark,
                                                color: "white",
                                                cursor: "pointer",
                                                borderWidth: "1px",
                                                borderColor: theme.palette.error.dark,
                                                borderStyle: "solid",
                                                pt: "5px",
                                                pb: "5px",
                                                '&[aria-controls="menu-list-grow"], &:hover': {
                                                    borderColor: theme.palette.error.main,
                                                    background: `${theme.palette.orange.dark}!important`,
                                                    color: "white",
                                                    "& svg": {
                                                        stroke: theme.palette.primary.light,
                                                    },
                                                },
                                            }}
                                            label={<h4 style={{ fontWeight: 400 }}>Cancel Shift</h4>}
                                            onClick={() => setCancelMode(true)}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                )}
                {viewedOpening.id >= 0 && (
                    <Stack padding="5%" backgroundColor={theme.palette.grey[100]}>
                        <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Chip
                                    sx={{
                                        borderRadius: "50px",
                                        background: "white",
                                        color: theme.palette.primary.main,
                                        pt: "5px",
                                        cursor: "pointer",
                                        borderWidth: "1px",
                                        borderColor: "white",
                                        borderStyle: "solid",
                                        boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 10%)",
                                        '& .MuiChip-label svg': {
                                            stroke: theme.palette.primary.main,
                                        },
                                        "&:hover": {
                                            background: theme.palette.primary.main,
                                            borderColor: theme.palette.primary.main,
                                            color: "white",
                                            '& .MuiChip-label svg': {
                                                stroke: "white",
                                            },
                                        },
                                    }}
                                    label={
                                        <IconArrowLeft
                                        // style={{
                                        //   color: theme.palette.primary.main,
                                        //   ":hover": {
                                        //     color: "white",
                                        //   }
                                        // }}
                                        />
                                    }
                                    onClick={() => setViewedOpening({ id: -1 })}
                                />
                                <Typography
                                    variant="h4"
                                    fontWeight={100}
                                    color={theme.palette.grey[500]}
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setViewedOpening({ id: -1 })}
                                >
                                    Back to Shift Openings
                                </Typography>
                            </Stack>
                            <DeleteForeverIcon
                                sx={{
                                    color: theme.palette.error.dark,
                                    cursor: "pointer",
                                    mt: "1rem",
                                    display: Boolean(
                                        eventType !== "Scheduled" ||
                                        totalOpenings <= 1 || // TODO: HACKY
                                        viewedOpening.id || // Comment to allow for filled shifts to be cancelled too
                                        (viewedOpening.id &&
                                            totalOpenings - numFilledOpenings > 0)
                                    )
                                        ? "none"
                                        : "auto",
                                }}
                                onClick={(e) => {
                                    if (
                                        eventType !== "Scheduled" ||
                                        totalOpenings <= 1 ||
                                        (viewedOpening.id && totalOpenings - numFilledOpenings > 0)
                                    )
                                        return;

                                    if (!viewedOpening.id) {
                                        setViewedOpening({ id: -1 });
                                        // If it's an unfilled opening we can just go ahead and reduce the total openings
                                        updateNumOpenings(totalOpenings - 1);
                                    } else {
                                        // Otherwise, display the confirmation prompt to delete the actual opening
                                        setCancelOpeningID(viewedOpening.id);
                                    }
                                }}
                            />
                        </Stack>
                        <Stack
                            direction={matchUpSm ? "row-reverse" : "column"}
                            display="flex"
                            alignContent="flex-start"
                            alignSelf="flex-start"
                            mt="2rem"
                            spacing={2}
                            sx={{
                                alignItems: matchUpSm ? "center" : "flex-start",
                            }}
                        >
                            <Typography variant="h4" fontWeight="100" color="darkgray">
                                {viewedOpening.id ? `ID #${viewedOpening.id}` : ""}
                            </Typography>
                            <Typography
                                variant="h1"
                                fontSize="1.8rem"
                                // padding="5px"
                                color={theme.palette.secondary.dark}
                            >
                                {workType}
                            </Typography>
                        </Stack>
                        <Grid container rowSpacing={2} columns={{ xs: 4, md: 4 }} mt="1rem">
                            <Grid item xs={2} md={1}>
                                <Typography
                                    variant="h4"
                                    fontWeight="100"
                                    sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                >
                                    Ambassador
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.secondary.dark }}
                                >
                                    {viewedOpening.id > 0 &&
                                        viewedOpening.attributes.user &&
                                        viewedOpening.attributes.user.data ? (
                                        <Box
                                            sx={{
                                                // float: "left",
                                                display: "flex",
                                                flexDirection: "row",
                                                // alignItems: "center",
                                            }}
                                        >
                                            {/* <Avatar
                            sx={{
                              width: '27px',
                              height: '27px',
                              fontSize: '1rem',
                              mt: "-5px",
                              mr: "5px",
                              // margin: "8px!important",
                              // ml: "0px !important",
                              cursor: "pointer",
                            }}
                            aria-controls="menu-list-grow"
                            aria-haspopup="true"
                            color="inherit"
                          >
                            <h5>{initials}</h5>
                          </Avatar> */}
                                            <Typography
                                                variant="h4"
                                                color={theme.palette.primary.dark}
                                            >
                                                {
                                                    viewedOpening.attributes.user.data.attributes
                                                        .firstName
                                                }{" "}
                                                {viewedOpening.attributes.user.data.attributes.lastName
                                                    ? `${viewedOpening.attributes.user.data.attributes.lastName
                                                        .charAt(0)
                                                        .toUpperCase()}.`
                                                    : ""}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        "--"
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <Typography
                                    variant="h4"
                                    fontWeight="100"
                                    sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                >
                                    Status
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color:
                                            eventType === "Completed" &&
                                                isOpeningComplete(viewedOpening)
                                                ? theme.palette.primary.main
                                                : eventType === "Cancelled"
                                                    ? theme.palette.error.main
                                                    : theme.palette.grey[500],
                                    }}
                                >
                                    {getOpeningStatus(viewedOpening, eventType)}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <Typography
                                    variant="h4"
                                    fontWeight="100"
                                    sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                >
                                    Hours
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.secondary.dark }}
                                >
                                    {(shiftDurationMins / 60).toFixed(2)}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={2} md={1}>
                <Typography
                  variant="h4"
                  sx={{ color: theme.palette.grey[200], mb: "12px" }}
                >
                  Lead
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ color: theme.palette.secondary.dark }}
                >
                  N/A
                </Typography>
              </Grid> */}
                        </Grid>
                        <Grid container rowSpacing={2} columns={{ xs: 6, md: 6 }} mt="2rem">
                            <Grid item xs={6} md={6}>
                                <Typography variant="h2">Ambassador Activity</Typography>
                            </Grid>
                            {Boolean(
                                (eventType !== "Completed" && eventType !== "Live Now") ||
                                !viewedOpening.id ||
                                !viewedOpening.attributes ||
                                Date.now() < Date.parse(viewedOpening.attributes.time)
                            ) ? (
                                <Stack
                                    alignItems="center"
                                    textAlign="center"
                                    backgroundColor="white"
                                    width="100%"
                                    padding="5%"
                                    borderRadius="15px"
                                    mt="1rem"
                                >
                                    <Typography variant="h1">👋 👀</Typography>
                                    <Typography
                                        variant="h3"
                                        mt="1rem"
                                        color={theme.palette.grey[500]}
                                    >
                                        Activity will appear when the shift starts.
                                    </Typography>
                                </Stack>
                            ) : (
                                <>
                                    <Grid item xs={3} md={2}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Reported Start Time
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {viewedOpening.id > 0 &&
                                                viewedOpening.attributes &&
                                                viewedOpening.attributes.shiftClockIn
                                                ? new Date(
                                                    Date.parse(viewedOpening.attributes.shiftClockIn)
                                                ).toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })
                                                : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={2}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Reported End Time
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {viewedOpening.id > 0 && viewedOpening.attributes
                                                ? new Date(
                                                    Date.parse(
                                                        viewedOpening.attributes.shiftClockOut || endTime
                                                    )
                                                ).toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })
                                                : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} md={2}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Reported Hours
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {(viewedOpening.id > 0 &&
                                                viewedOpening.attributes &&
                                                getEventDurationHours(
                                                    viewedOpening.attributes.shiftClockIn,
                                                    viewedOpening.attributes.shiftClockOut || endTime
                                                ).toFixed(2)) ||
                                                "--"}
                                        </Typography>
                                    </Grid>
                                    {targetEvent.attributes.checklist && (
                                        <Stack mt="20px">
                                            <Typography
                                                variant="h4"
                                                fontWeight="100"
                                                sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                            >
                                                Task Checklist
                                            </Typography>
                                            {targetEvent.attributes.checklist.map((task, index) => (
                                                <Stack
                                                    key={index}
                                                    direction="row"
                                                    alignItems="center"
                                                >
                                                    <Checkbox
                                                        checked={viewedOpening.attributes.completedItems?.includes(task)}
                                                    />
                                                    <Typography variant="h4" fontWeight="100">
                                                        {task}
                                                    </Typography>
                                                </Stack>
                                            ))}
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Grid>
                        {
                            paymentsEnabled && false && (
                                <>
                                    <Typography variant="h2" mt="2.7rem" mb="1rem">
                                        Payout Activity
                                    </Typography>
                                    <Grid
                                        container
                                        sx={{
                                            background: "white",
                                            padding: "5%",
                                            borderRadius: "15px",
                                            pb: "2rem",
                                        }}
                                        rowSpacing={1}
                                        columns={{ xs: 6, md: 6 }}
                                        mt="1rem"
                                        justifyContent={
                                            Boolean(
                                                eventType !== "Completed" ||
                                                !viewedOpening.id ||
                                                !viewedOpening.attributes ||
                                                !viewedOpening.attributes.paymentDate
                                            )
                                                ? "center"
                                                : "space-between"
                                        }
                                    >
                                        {Boolean(
                                            eventType !== "Completed" ||
                                            !viewedOpening.id ||
                                            !viewedOpening.attributes ||
                                            !viewedOpening.attributes.paymentDate
                                        ) ? (
                                            <Stack alignItems="center" textAlign="center">
                                                <Typography variant="h1">👋 👀</Typography>
                                                <Typography
                                                    variant="h3"
                                                    mt="1rem"
                                                    color={theme.palette.grey[500]}
                                                >
                                                    Payouts will appear after they are issued.
                                                </Typography>
                                            </Stack>
                                        ) : (
                                            <>
                                                <Grid item xs={6} md={6}>
                                                    <Stack direction="row" justifyContent="space-between" mt="-10px">
                                                        <Typography
                                                            fontSize="1.1rem"
                                                            fontWeight="700"
                                                            color={theme.palette.grey[500]}
                                                        >
                                                            {viewedOpening.id > 0 &&
                                                                viewedOpening.attributes.paymentDate &&
                                                                new Date(
                                                                    Date.parse(viewedOpening.attributes.paymentDate)
                                                                )
                                                                    .toLocaleString("en", {
                                                                        month: "long",
                                                                        day: "numeric",
                                                                    })
                                                                    .split(" ")
                                                                    .reverse()
                                                                    .join(" ")}
                                                        </Typography>
                                                        <Typography
                                                            fontWeight="700"
                                                            fontSize="1.1rem"
                                                            color={
                                                                viewedOpening.attributes.paymentStatus === "Paid"
                                                                    ? theme.palette.primary.main
                                                                    : ""
                                                            }
                                                        >
                                                            {viewedOpening.attributes.paymentStatus || "--"}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={6} md={5}>
                                                    <Typography variant="h3">{workType}</Typography>
                                                </Grid>
                                                <Grid item xs={6} md={1}>
                                                    <Typography variant="h2">
                                                        $
                                                        {viewedOpening.id && viewedOpening.attributes
                                                            ? (
                                                                getEventDurationHours(
                                                                    viewedOpening.attributes.resolvedClockIn ||
                                                                    viewedOpening.attributes.approvedClockIn ||
                                                                    viewedOpening.attributes.shiftClockIn,
                                                                    viewedOpening.attributes.resolvedClockOut ||
                                                                    viewedOpening.attributes.approvedClockOut ||
                                                                    viewedOpening.attributes.shiftClockOut ||
                                                                    endTime
                                                                ) * ratePerHourCAD
                                                            ).toFixed(2)
                                                            : "0.00"}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </>
                            )
                        }
                        <QuestionsBar sx={{ width: "100%", mt: "2rem" }} />
                    </Stack>
                )}
                {!cancelMode && !loading && viewedOpening.id < 0 && (
                    <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
                        <Grid
                            container
                            rowSpacing={2}
                            sx={{ padding: "5%", pb: "1%", justifyContent: "space-between" }}
                        >
                            {
                                notAllowedToEdit && (
                                    <Grid item xs={4} sm={8} md={12} sx={{ mt: -2 }} >
                                        <Typography
                                            sx={{
                                                marginBottom: "10px",
                                                padding: "20px",
                                                background: theme.palette.secondary.light,
                                                borderRadius: "15px",
                                                fontWeight: "500",
                                                color: theme.palette.grey[600],
                                            }}
                                        >
                                            NOTE: You are currently on an <strong>interactive demo account</strong>. You can view and interact with existing shifts, but you cannot edit or delete them. Try creating your own shifts!
                                        </Typography>
                                    </Grid>
                                )
                            }
                            <Grid item md={8} sx={{ alignSelf: "center", width: "100%" }}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent={matchUpMd ? "normal" : "space-between"}
                                >
                                    <Typography
                                        variant="h2"
                                        fontWeight={600}
                                        color={theme.palette.secondary.dark}
                                        mr="10px"
                                    >
                                        Shift Openings
                                    </Typography>
                                    <Stack direction="row">
                                        <EWCircularProgress value={filledEventsPercent} size={25} />
                                        <Typography
                                            variant="h3"
                                            fontWeight={100}
                                            color={theme.palette.grey[200]}
                                            ml="10px"
                                            alignSelf="center"
                                        >
                                            {numFilledOpenings}/{totalOpenings} Filled
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            {(eventType === "Scheduled" || eventType === "Draft") && (
                                <Grid item sx={{ justifyContent: "right" }}>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        sx={{
                                            justifyContent: "right",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => updateNumOpenings(totalOpenings + 1)}
                                    >
                                        <IconCirclePlus
                                            fontWeight={100}
                                            color={theme.palette.primary.main}
                                        />
                                        <Typography
                                            variant="h3"
                                            fontWeight={400}
                                            color={theme.palette.primary.main}
                                        >
                                            Add Opening
                                        </Typography>
                                    </Stack>
                                </Grid>
                            )}
                        </Grid>
                        <Stack
                            justifyContent="center"
                            justifySelf="center"
                            alignSelf="center"
                            justifyItems="center"
                            alignItems="center"
                            width="-webkit-fill-available"
                            mb="3rem"
                        >
                            {allOpenings.map((opening, i) => (
                                <Grid
                                    container
                                    key={opening.id || i} // TODO this is hacky
                                    mt="20px"
                                    pt="2%"
                                    pl="5%"
                                    pr="5%"
                                    pb="5%"
                                    rowSpacing={2}
                                    sx={{
                                        background: "white",
                                        borderRadius: "15px",
                                        width: "90%",
                                        cursor: "pointer",
                                        "&:hover": {
                                            boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 10%)",
                                        },
                                        justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                        // TODO uncomment
                                        setViewedOpening(opening);
                                    }}
                                >
                                    <Grid xs={12} item>
                                        <Stack
                                            direction="row"
                                            display="flex"
                                            justifyContent="space-between"
                                            sx={{
                                                alignItems: "center",
                                            }}
                                        >
                                            <Stack
                                                direction={matchUpSm ? "row-reverse" : "column"}
                                                display="flex"
                                                // justifyContent="space-between"
                                                spacing={opening.id ? 1.5 : 0}
                                                sx={{
                                                    alignItems: matchUpSm ? "center" : "flex-start",
                                                }}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    fontWeight="100"
                                                    color="darkgray"
                                                >
                                                    {opening.id ? `ID: ${opening.id}` : ""}
                                                </Typography>
                                                <Typography
                                                    variant="h3"
                                                    // padding="5px"
                                                    color={theme.palette.secondary.dark}
                                                >
                                                    {workType}
                                                </Typography>
                                            </Stack>
                                            <ClickAwayListener
                                                onClickAway={() => {
                                                    setOpeningsCancelTooltips(null);
                                                }}
                                            >
                                                <div>
                                                    {(eventType === "Scheduled" ||
                                                        eventType === "Draft") && (
                                                            <InfoTooltip
                                                                title={
                                                                    <Typography
                                                                        sx={{
                                                                            fontWeight: "600",
                                                                            color: theme.palette.secondary.dark,
                                                                        }}
                                                                    >
                                                                        {Boolean(totalOpenings <= 1) &&
                                                                            "A shift must have at least one opening."}
                                                                    </Typography>
                                                                }
                                                                arrow={Boolean(
                                                                    totalOpenings <= 1
                                                                    // || (opening.id &&
                                                                    //     totalOpenings - numFilledOpenings > 0)
                                                                )}
                                                                placement="bottom-end"
                                                                openState={
                                                                    openingsCancelTooltips === (opening.id || i) &&
                                                                    (totalOpenings <= 1
                                                                        // || (opening.id &&
                                                                        //     totalOpenings - numFilledOpenings > 0)
                                                                    )
                                                                }
                                                                isMobile={true}
                                                            >
                                                                <DeleteForeverIcon
                                                                    sx={{
                                                                        color:
                                                                            totalOpenings <= 1 // ||
                                                                                // opening.id || // Comment to allow for filled shifts to be cancelled too
                                                                                // (opening.id &&
                                                                                //     totalOpenings - numFilledOpenings > 0)
                                                                                ? theme.palette.grey[500]
                                                                                : theme.palette.error.dark,
                                                                        cursor:
                                                                            totalOpenings <= 1 // ||
                                                                                // opening.id || // Comment to allow for filled shifts to be cancelled too
                                                                                // (opening.id &&
                                                                                //     totalOpenings - numFilledOpenings > 0)
                                                                                ? "not-allowed"
                                                                                : "pointer",
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();

                                                                        if (
                                                                            openingsCancelTooltips !== (opening.id || i)
                                                                        ) {
                                                                            setOpeningsCancelTooltips(opening.id || i);
                                                                        }

                                                                        if (
                                                                            totalOpenings <= 1 // ||
                                                                            // opening.id || // Comment to allow for filled shifts to be cancelled too
                                                                            // (opening.id &&
                                                                            //     totalOpenings - numFilledOpenings > 0)
                                                                        )
                                                                            return;

                                                                        setOpeningsCancelTooltips(null);

                                                                        if (!opening.id) {
                                                                            // If it's an unfilled opening we can just go ahead and reduce the total openings
                                                                            updateNumOpenings(totalOpenings - 1);
                                                                        } else {
                                                                            // Otherwise, display the confirmation prompt to delete the actual opening
                                                                            setCancelOpeningID(opening.id);
                                                                        }
                                                                    }}
                                                                />
                                                            </InfoTooltip>
                                                        )}
                                                </div>
                                            </ClickAwayListener>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={8.1}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Ambassador
                                        </Typography>
                                        {opening.id > 0 &&
                                            opening.attributes.user &&
                                            opening.attributes.user.data &&
                                            opening.attributes.user.data.attributes ? (
                                            <Typography
                                                variant="h4"
                                                sx={{ color: theme.palette.secondary.dark }}
                                            >
                                                <Box
                                                    sx={{
                                                        // float: "left",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        columnGap: "6px",
                                                    }}
                                                >
                                                    <FailSafeAvatar
                                                        sz={27}
                                                        firstName={opening.attributes.user.data.attributes.firstName}
                                                        lastName={opening.attributes.user.data.attributes.lastName}
                                                        profilePicURL={getAmbassadorAvatar(opening.attributes.user.data)}
                                                        cartoonOptions={opening.attributes.user.data.attributes.avatarOptions}
                                                        sx={{
                                                            width: "27px",
                                                            height: "27px",
                                                            fontSize: "1rem",
                                                        }}
                                                    />
                                                    <Typography
                                                        variant="h4"
                                                        color={theme.palette.primary.dark}
                                                    >
                                                        {opening.attributes.user.data.attributes.firstName}{" "}
                                                        {opening.attributes.user.data.attributes.lastName}
                                                    </Typography>
                                                </Box>
                                            </Typography>
                                        ) : (
                                            eventType !== "Cancelled" ? (
                                                availableAmbassadorsLoading ? (
                                                    <CircularProgress />
                                                ) : (
                                                    allActiveAmbassadorsNotAssignedYet.length > 0 ? (
                                                        <FormControl
                                                            fullWidth
                                                            sx={{ ...theme.typography.customInput, mt: 0, mb: 0 }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <Select
                                                                autoFocus={false}
                                                                // defaultValue={userAction}
                                                                labelId="action-select-label"
                                                                id="action-select"
                                                                // value={userAction}
                                                                onChange={(e) => {
                                                                    if (e.target.value) {
                                                                        assignAmbassadorToOpening(
                                                                            e.target.value
                                                                        );
                                                                    }
                                                                }}
                                                                label="Type"
                                                                sx={{ height: "55px" }}
                                                                input={
                                                                    <OutlinedInput
                                                                        id="outlined-adornment-rewardType-register"
                                                                        // label="Position Title"
                                                                        name="rewardType"
                                                                        type="text"
                                                                    // value={userAction}
                                                                    // defaultValue={userAction}
                                                                    />
                                                                }
                                                            >
                                                                {allActiveAmbassadorsNotAssignedYet.map((ambassador) => (
                                                                    <MenuItem
                                                                        key={ambassador.id}
                                                                        value={ambassador.id}
                                                                        sx={{
                                                                            justifyContent: "space-between",
                                                                            display: "flex",
                                                                        }}
                                                                        autoFocus={false}
                                                                    >
                                                                        <Typography
                                                                            variant="h4"
                                                                            sx={{ color: theme.palette.secondary.dark, justifyContent: "space-between" }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    // float: "left",
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    alignItems: "center",
                                                                                    columnGap: "6px",
                                                                                }}
                                                                            >
                                                                                <FailSafeAvatar
                                                                                    sz={27}
                                                                                    firstName={ambassador.firstName}
                                                                                    lastName={ambassador.lastName}
                                                                                    profilePicURL={ambassador && ambassador.avatar ? ambassador.avatar.url : ""}
                                                                                    cartoonOptions={ambassador.avatarOptions}
                                                                                    sx={{
                                                                                        width: "27px",
                                                                                        height: "27px",
                                                                                        fontSize: "1rem",
                                                                                    }}
                                                                                />
                                                                                {/* <Avatar
                                                                                    sx={{
                                                                                        width: "27px",
                                                                                        height: "27px",
                                                                                        fontSize: "1rem",
                                                                                        mt: "-5px",
                                                                                        mr: "5px",
                                                                                        // margin: "8px!important",
                                                                                        // ml: "0px !important",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    aria-controls="menu-list-grow"
                                                                                    aria-haspopup="true"
                                                                                    color="inherit"
                                                                                >
                                                                                    <h5>{getInitials(`${ambassador.firstName} ${ambassador.lastName}`)}</h5>
                                                                                </Avatar> */}
                                                                                <Typography
                                                                                    variant="h4"
                                                                                    color={theme.palette.primary.dark}
                                                                                >
                                                                                    {ambassador.firstName}{" "}
                                                                                    {ambassador.lastName}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h5"
                                                                            color={eventType !== "Scheduled" || (!!availableAmbassadors && Array.isArray(availableAmbassadors) && !!availableAmbassadors.find((availableAmbassador) => availableAmbassador.id === ambassador.id)) ? theme.palette.primary.main : theme.palette.warning.dark}
                                                                            sx={{ marginLeft: "12px", display: eventType !== "Scheduled" ? "none" : "flex", alignItems: "center", columnGap: "2px" }}
                                                                        >
                                                                            <IconAlertCircle display={eventType !== "Scheduled" || (!!availableAmbassadors && Array.isArray(availableAmbassadors) && !!availableAmbassadors.find((availableAmbassador) => availableAmbassador.id === ambassador.id)) ? "none" : "inherit"} strokeWidth="1" width="20px" />
                                                                            {eventType !== "Scheduled" || (!!availableAmbassadors && Array.isArray(availableAmbassadors) && !!availableAmbassadors.find((availableAmbassador) => availableAmbassador.id === ambassador.id)) ? "Matches Availability" : "Availability Conflict"}
                                                                        </Typography>
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    ) : (
                                                        <Typography
                                                            variant="h4"
                                                            sx={{ color: theme.palette.secondary.dark }}
                                                        >
                                                            No Ambassadors Active
                                                        </Typography>
                                                    )
                                                )
                                            ) : (
                                                <Typography
                                                    variant="h4"
                                                    sx={{ color: theme.palette.secondary.dark }}
                                                >
                                                    --
                                                </Typography>
                                            )
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Status
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                color:
                                                    eventType === "Completed" &&
                                                        opening.id &&
                                                        (opening.attributes.shiftClockIn || autoClocking)
                                                        ? theme.palette.primary.main
                                                        : eventType === "Cancelled"
                                                            ? theme.palette.error.main
                                                            : getOpeningStatus(opening, eventType) == "Filled" ? "teal" : theme.palette.grey[500],
                                            }}
                                        >
                                            {getOpeningStatus(opening, eventType) == "Filled" ? "Assigned" : getOpeningStatus(opening, eventType)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Hours
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {opening.id > 0 && opening.attributes ? (opening.attributes.reportedAbsentByCompany ? "0.00" : (getShiftDurationHours(
                                                opening.attributes.resolvedClockIn ||
                                                opening.attributes.approvedClockIn ||
                                                (autoClocking ? startTime : opening.attributes.shiftClockIn),
                                                opening.attributes.resolvedClockOut ||
                                                opening.attributes.approvedClockOut ||
                                                opening.attributes.shiftClockOut ||
                                                endTime
                                            ).toFixed(2))) : (eventType === "Completed" ? "0.00" : (shiftDurationMins / 60).toFixed(2))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            <QuestionsBar />
                        </Stack>
                    </Box>
                )}
            </Drawer>
        </Box>
    );
};

ViewEventDrawer.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerClose: PropTypes.func,
    // window: PropTypes.object,
};

export default ViewEventDrawer;
