// material-ui
import { useMediaQuery, Chip } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CheckIcon from "@mui/icons-material/Check";
import PendingIcon from '@mui/icons-material/Pending';
import { useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const ShiftStatusBadge = ({ shiftType, sz = "lg" }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const baseShiftBadgeStyle = {
    p: "5px",
    pl: matchUpMd ? "5px" : "0px",
    pr: matchUpMd ? "5px" : "0px",
    borderRadius: "50px",
    borderStyle: "solid",
  };
  // Draft Shift
  const draftBadgeStyle = {
    ...baseShiftBadgeStyle,
    height: "35px",
    width: "80px",
    background: "transparent",
    borderWidth: "1px",
    borderColor: "white",
    color: "white",
  };

  // Live shift
  const liveBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.secondary.dark,
    color: "white",
    borderColor: theme.palette.secondary.dark,
  };
  const inviteIcon = <MailOutlineIcon color={theme.palette.success.light} />;
  const liveIcon = <SensorsIcon color={theme.palette.success.light} />;
  const pendingInvite = <PendingIcon color="white" />;
  // Completed shift
  const completedBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.primary.dark,
    color: "white",
    borderColor: theme.palette.primary.dark,
  };
  const completedIcon = (
    <CheckIcon color="white" sx={{ color: "white", fontSize: "1rem" }} />
  );
  // Scheduled shift
  const scheduledBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.primary.main,
    color: "white",
    borderColor: theme.palette.primary.main,
  };
  const scheduledIcon = (
    <CheckIcon sx={{ color: theme.palette.secondary.dark, fontSize: "1rem" }} />
  );
  // Cancelled shift
  const cancelledBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.error.dark,
    color: "white",
    borderColor: theme.palette.error.dark,
  };

  const shiftTypeStyles = {
    Archived: {
      badgeStyle: draftBadgeStyle,
      icon: null,
    },
    "No Availability": {
      badgeStyle: cancelledBadgeStyle,
      icon: null,
    },
    "Pending Invite": {
      badgeStyle: liveBadgeStyle,
      icon: pendingInvite,
      explanation: "The ambassador has not yet accepted the invite.",
    },
    "Declined Offer": {
      badgeStyle: cancelledBadgeStyle,
      icon: null,
    },
    Active: {
      badgeStyle: completedBadgeStyle,
      icon: completedIcon,
      explanation: "The ambassador is able to take shifts, chats, or tasks.",
    },
    Inactive: {
      badgeStyle: cancelledBadgeStyle,
      icon: null,
      explanation: "The ambassador can not take shifts, chats, or tasks. Reactive them from the ambassador's settings.",
    },
    "N/A": {
      badgeStyle: null,
      icon: null,
    },
  };

  if (!Object.keys(shiftTypeStyles).includes(shiftType)) {
    return null;
  }

  return (
    <Tooltip title={shiftTypeStyles[shiftType].explanation} classes={{ tooltip: tooltipClasses.tooltip }}>
        <Chip
            sx={{
                ...shiftTypeStyles[shiftType].badgeStyle,
            }}
            icon={shiftTypeStyles[shiftType].icon}
            label={sz === "sm" ? <h5>{shiftType}</h5> : <h4>{shiftType}</h4>}
        />
    </Tooltip>
  );
};

export default ShiftStatusBadge;
