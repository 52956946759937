import PropTypes from "prop-types";
import { Avatar as MUIAvatar } from "@mui/material";
import Avatar from 'avataaars';

const FailSafeAvatar = ({ firstName = null, lastName = null, profilePicURL = null, cartoonOptions = null, sz = 40, sx }) => {

    const lastNameLetter = lastName && lastName.length > 0 ? lastName.charAt(0).toUpperCase() : "";
    const initials =
        firstName && firstName.length > 0 && lastName && lastName.length > 0
            ? `${firstName.charAt(0).toUpperCase()}${lastNameLetter}`
            : firstName && firstName.length > 0 ? firstName.charAt(0).toUpperCase() : null; // no initials

    const alt = !!firstName ? `${firstName}${lastName ? " " + lastName : ""}` : initials ?? "User";

    const basePhotoURL = profilePicURL; // "https://ionicframework.com/docs/img/demos/avatar.svg";
    const photoName = basePhotoURL ? basePhotoURL.substring(basePhotoURL.lastIndexOf('/') + 1) : null;
    const photoURLPrefix = basePhotoURL ? basePhotoURL.substring(0, basePhotoURL.lastIndexOf('/') + 1) : null;

    const thumbnailURL = basePhotoURL ? `${photoURLPrefix}thumbnail_${photoName}` : null;
    const smallURL = basePhotoURL ? `${photoURLPrefix}small_${photoName}` : null;
    const mediumURL = basePhotoURL ? `${photoURLPrefix}medium_${photoName}` : null;
    const largeURL = basePhotoURL ? `${photoURLPrefix}large_${photoName}` : null;

    const srcURL = sz <= 50 ? thumbnailURL : sz <= 100 ? smallURL : sz <= 200 ? mediumURL : largeURL;

    return (
        <MUIAvatar
            alt={alt}
            src={srcURL}
            sx={{ width: `${sz}px`, height: `${sz}px`, border: "none", padding: 0, ...sx }}
        >
            <MUIAvatar
                alt={alt}
                src={basePhotoURL}
                sx={{ width: `${sz}px`, height: `${sz}px`, border: "none", padding: 0, ...sx }}
            >
                {
                    !initials && !cartoonOptions ? (
                        <MUIAvatar
                            alt={alt}
                            src={"https://ionicframework.com/docs/img/demos/avatar.svg"}
                            sx={{ width: `${sz}px`, height: `${sz}px`, ...sx }}
                        >
                            {alt}
                        </MUIAvatar>
                    ) : (
                        !!cartoonOptions ? (
                            <Avatar
                                avatarStyle="Transparent"
                                clotheType="CollarSweater"
                                clotheColor="Heather"
                                eyeType="Default"
                                eyebrowType="Default"
                                mouthType="Smile"
                                {...cartoonOptions}
                                facialHairColor={cartoonOptions.hairColor || "Black"}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                }}
                            />
                        ) : (
                            <h5>{initials}</h5>
                        )
                    )
                }
            </MUIAvatar>
        </MUIAvatar>
    );
};

FailSafeAvatar.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePicURL: PropTypes.string,
    cartoonOptions: PropTypes.object,
    sz: PropTypes.number,
};

export default FailSafeAvatar;
