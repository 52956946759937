import { lazy } from "react";

// project imports
import FailSafeLoadable from "ui-component/FailSafeLoadable";
import MinimalLayout from "layout/MinimalLayout";

const BusinessPage = FailSafeLoadable(
  lazy(() => import("views/pages/public/businesses"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const PublicRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/businesses/:businessId",
      element: <BusinessPage />,
    },
  ],
};

export default PublicRoutes;
