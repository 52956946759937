// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
    firstFetchMade: false,
    threads: [],
};

// ==============================|| THREADS REDUCER ||============================== //

const threadsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.THREADS_ADD_OR_UPDATE:
            const threadToUpdate = !!action.updatedThread ? state.threads.find(
                (thread) => thread.id === action.updatedThread.id
            ) : null;

            if (threadToUpdate) {
                NestedObjectAssign(threadToUpdate, action.updatedThread);
            }
            const addedThread = threadToUpdate ? [] : [action.updatedThread];

            const newThreads = [...state.threads, ...addedThread];

            return {
                ...state,
                firstFetchMade: true,
                threads: newThreads,
            };

        case actionTypes.THREADS_DELETE:
            const filteredThreads = state.threads.filter(
                (thread) => thread.id != action.deletedThreadID
            );

            return {
                ...state,
                firstFetchMade: true,
                threads: filteredThreads,
            };

        default:
            return state;
    }
};

export default threadsReducer;
