// material-ui
import { useMediaQuery, Chip } from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const EventStatusBadge = ({ eventType, sz = "lg" }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const baseShiftBadgeStyle = {
    p: "5px",
    pl: matchUpMd ? "5px" : "0px",
    pr: matchUpMd ? "5px" : "0px",
    borderRadius: "50px",
    borderStyle: "solid",
  };
  // Draft Shift
  const draftBadgeStyle = {
    ...baseShiftBadgeStyle,
    height: "35px",
    width: "80px",
    background: "transparent",
    borderWidth: "1px",
    borderColor: "white",
    color: "white",
  };

  // Live shift
  const liveBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.secondary.dark,
    color: theme.palette.success.light,
    borderColor: theme.palette.success.light,
    borderWidth: "1px",
  };
  const liveIcon = <SensorsIcon color={theme.palette.success.light} />;
  // Completed shift
  const completedBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.primary.dark,
    color: "white",
    borderColor: theme.palette.primary.dark,
  };
  const completedIcon = (
    <CheckIcon color="white" sx={{ color: "white", fontSize: "1rem" }} />
  );
  // Scheduled shift
  const scheduledBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.primary.main,
    color: "white",
    borderColor: theme.palette.primary.main,
  };
  const scheduledIcon = (
    <CheckIcon sx={{ color: theme.palette.secondary.dark, fontSize: "1rem" }} />
  );
  // Cancelled shift
  const cancelledBadgeStyle = {
    ...baseShiftBadgeStyle,
    background: theme.palette.error.dark,
    color: "white",
    borderColor: theme.palette.error.dark,
  };

  const eventTypeStyles = {
    Draft: {
      badgeStyle: draftBadgeStyle,
      icon: null,
      explanation: "The shift is currently being drafted and has not been published yet.",
    },
    "Live Now": {
      badgeStyle: liveBadgeStyle,
      icon: liveIcon,
      explanation: "The shift has started and not ended yet.",
    },
    Completed: {
      badgeStyle: completedBadgeStyle,
      icon: completedIcon,
      explanation: "The shift has ended.",
    },
    Scheduled: {
      badgeStyle: scheduledBadgeStyle,
      icon: null, //scheduledIcon,
      explanation: "The shift is scheduled to start in the future.",
    },
    Cancelled: {
      badgeStyle: cancelledBadgeStyle,
      icon: null,
      explanation: "The shift has been cancelled by an admin.",
    },
    "N/A": {
      badgeStyle: null,
      icon: null,
    },
  };

  if (!Object.keys(eventTypeStyles).includes(eventType)) {
    return null;
  }

  return (
    <Tooltip title={eventTypeStyles[eventType].explanation} classes={{ tooltip: tooltipClasses.tooltip }}>
        <Chip
            sx={{
                ...eventTypeStyles[eventType].badgeStyle,
            }}
            icon={eventTypeStyles[eventType].icon}
            label={sz === "sm" ?  <h5>{eventType}</h5> : <h4>{eventType}</h4>}
        />
    </Tooltip>
  );
};

export default EventStatusBadge;
