import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import shiftsReducer from "./shiftsReducer";
import eventsReducer from "./eventsReducer";
import rulesReducer from "./rulesReducer";
import suggestionsReducer from "./suggestionsReducer";
import convosReducer from "./convosReducer";
import accountReducer from "./accountReducer";
import flagsReducer from "./flagsReducer";
import notificationsReducer from "./notificationsReducer";
import tasksReducer from "./tasksReducer";
import prospectsReducer from "./prospectsReducer";
import threadsReducer from "./threadsReducer";
import resourcesReducer from "./resourcesReducer";
import groupsReducer from "./groupsReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    shifts: shiftsReducer,
    events: eventsReducer,
    rules: rulesReducer,
    tasks: tasksReducer,
    notifications: notificationsReducer,
    suggestions: suggestionsReducer,
    flags: flagsReducer,
    conversations: convosReducer,
    account: accountReducer,
    prospects: prospectsReducer,
    threads: threadsReducer,
    resources: resourcesReducer,
    groups: groupsReducer,
});

export default reducer;
