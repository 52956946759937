// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "#fff",
	"primaryLight": "#eef2f6",
	"primary200": "#f8f5ec",
	"primaryMain": "#2a9d8f",
	"primaryDark": "#15272e",
	"primary800": "#1565c0",
	"secondaryLight": "#f8f5ec",
	"secondary200": "#b39ddb",
	"secondaryMain": "#2a9d8f",
	"secondaryDark": "#264653",
	"secondary800": "#e3eefa",
	"successLight": "#e9c46a",
	"success200": "#f2fff2",
	"successMain": "#e9c46a",
	"successDark": "#00c853",
	"errorLight": "#ef9a9a",
	"errorMain": "#f44336",
	"errorDark": "#e63946",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#e94d59",
	"warningLight": "#fad7da",
	"warningMain": "#ffe57f",
	"warningDark": "#ffc107",
	"grey50": "#f8fafc",
	"grey100": "#f6f6f6",
	"grey200": "#bcbcbc",
	"grey300": "#cdd5df",
	"grey500": "#8f8f8f",
	"grey600": "#797979",
	"grey700": "#364152",
	"grey900": "#121926",
	"darkPaper": "#111936",
	"darkBackground": "#1a223f",
	"darkLevel1": "#29314f",
	"darkLevel2": "#212946",
	"darkTextTitle": "#d7dcec",
	"darkTextPrimary": "#bdc8f0",
	"darkTextSecondary": "#8492c4",
	"darkPrimaryLight": "#eef2f6",
	"darkPrimaryMain": "#2196f3",
	"darkPrimaryDark": "#1e88e5",
	"darkPrimary200": "#90caf9",
	"darkPrimary800": "#1565c0",
	"darkSecondaryLight": "#d1c4e9",
	"darkSecondaryMain": "#7c4dff",
	"darkSecondaryDark": "#651fff",
	"darkSecondary200": "#b39ddb",
	"darkSecondary800": "#6200ea"
};
export default ___CSS_LOADER_EXPORT___;
