// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
    firstFetchMade: false,
    resources: [],
};

// ==============================|| RESOURCES REDUCER ||============================== //

const resourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESOURCES_FETCH_SUCCEEDED:
            console.log("Fetch resources reducer. Action = ", action);
            const resourcesToAdd = action.resources ? action.resources.filter(
                (resource) => !(state.resources.filter(existing => existing.id === resource.id).length > 0)
            ) : [];

            const newResourceSet = [...state.resources, ...resourcesToAdd];

            return {
                ...state,
                firstFetchMade: true,
                resources: newResourceSet,
            };

        case actionTypes.RESOURCES_ADD_OR_UPDATE:
            const resourceToUpdate = !!action.updatedResource ? state.resources.find(
                (resource) => resource.id === action.updatedResource.id
            ) : null;

            if (resourceToUpdate) {
                NestedObjectAssign(resourceToUpdate, action.updatedResource);
            }
            const addedResource = resourceToUpdate ? [] : [action.updatedResource];

            const newResources = [...state.resources, ...addedResource].sort((a, b) => {
                return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
            });

            return {
                ...state,
                firstFetchMade: true,
                resources: newResources,
            };

        case actionTypes.RESOURCES_DELETE:
            const filteredResources = state.resources.filter(
                (resource) => resource.id != action.deletedResourceID
            );

            return {
                ...state,
                firstFetchMade: true,
                resources: filteredResources,
            };

        default:
            return state;
    }
};

export default resourcesReducer;
