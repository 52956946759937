import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ACCOUNT_LOGOUT, ACCOUNT_UPDATE, CONVOS_DELETE_ALL, SHIFTS_DELETE_ALL } from "store/actions";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { analytics as firebaseInstance } from "utils/firebase";
import * as Sentry from "@sentry/react";

// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";

import useInterval from "./utils/intervals";
import axios from "axios";

// ==============================|| APP ||============================== //

// How often we poll and update the shift store
// TODO: use sockets in the future for scalability
const SHIFT_STORE_REFRESH_RATE_SECONDS = 5;
const MAX_USER_FETCH_RETRIES = 3;

Sentry.init({
    dsn: "https://841830bc00444ac8949dc587454f2721@o4505246256857088.ingest.sentry.io/4505246260985856",
    enabled: window.location.host === 'app.campusthreads.co' || window.location.host === 'ct-admin-staging.web.app',
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ["localhost", /^(https:\/\/)?([\w-]+\.)*campusthreads\.co/],
        }),
        new Sentry.Replay({
            maskAllInputs: false,
            maskAllText: false,
            networkCaptureBodies: true,
            networkRequestHeaders: [
                "Authorization",
                "Cache-Control",
                "Content-Type",
                "User-Agent",
                "Host",
                "Origin",
                "Referer",
                "X-Requested-With",
            ],
            networkResponseHeaders: [
                "Server",
                "Date",
                "Content-Type",
                "Content-Length",
                "Referrer-Policy",
                "X-Content-Type-Options",
            ],
            networkDetailAllowUrls: [
                window.location.origin,
                /^(https:\/\/)?([\w-]+\.)*campusthreads\.co/,
            ],
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: window.location.host === 'app.campusthreads.co' || window.location.host === 'ct-admin-staging.web.app' ? 1.0 : 0.0,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const customization = useSelector((state) => state.customization);

    const fetchUserDetails = async (accessToken, retryCount = 0) => {
        try {
            const response = await axios
                .get(
                    `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/users/me?populate=*`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

            console.log(response);
            const { data } = response;

            const newAccountPayload = {
                userID: data.id,
                fetchedMe: true,
                ...data,
            };

            return newAccountPayload;
        } catch (error) {
            console.log("An error occurred:", error);

            if (!error.response && retryCount < MAX_USER_FETCH_RETRIES) {
                console.log("Network error, retrying...");
                const newAccountPayload = await fetchUserDetails(accessToken, retryCount + 1);
                return newAccountPayload;
            } else {
                dispatch({
                    type: SHIFTS_DELETE_ALL,
                });

                dispatch({
                    type: CONVOS_DELETE_ALL,
                });

                dispatch({
                    type: ACCOUNT_LOGOUT,
                });

                localStorage.removeItem("accountDetails");
                if (!window.location.href.includes("/pages/login/login3")) {
                    window.location.href = "/pages/login/login3";
                }
            }
        }
    };

    useEffect(() => {
        const getAccountDetails = async () => {
            // TODO instead of reading from localstorage, make
            // a request to users/me with http cookie to get account info
            // See commented block below

            const url = window.location.href;

            logEvent(firebaseInstance, "app_visited", {
                url,
            });

            console.log("APP.JS useEffect");
            const accountDetailsStr = localStorage.getItem("accountDetails");

            if (accountDetailsStr && JSON.parse(accountDetailsStr) && JSON.parse(accountDetailsStr).accessToken) {
                const accountDetailsObj = JSON.parse(accountDetailsStr);
                const accessToken = accountDetailsObj.accessToken;

                logEvent(firebaseInstance, "app_local_storage_details_hit", {
                    accountDetails: accountDetailsObj,
                });

                Sentry.setUser({ id: accountDetailsObj.userID, email: accountDetailsObj.email });

                setUserId(firebaseInstance, accountDetailsObj.userID);

                setUserProperties(firebaseInstance, {
                    companyID: accountDetailsObj.companyID,
                    companyName: accountDetailsObj.companyName,
                });

                // Fetch user details from server
                const newAccountDetails = await fetchUserDetails(accessToken);
                if (!newAccountDetails || !newAccountDetails.userID) {
                    logEvent(firebaseInstance, "fetch_me_failed_all", {
                        accessToken,
                    });

                    dispatch({
                        type: SHIFTS_DELETE_ALL,
                    });

                    dispatch({
                        type: CONVOS_DELETE_ALL,
                    });

                    dispatch({
                        type: ACCOUNT_LOGOUT,
                    });

                    if (!url.includes("/pages/login/login3")) {
                        window.location.href = "/pages/login/login3";
                    }
                    return;
                }

                dispatch({
                    type: ACCOUNT_UPDATE,
                    payload: {
                        ...newAccountDetails,
                        accessToken,
                    },
                });

                if (!newAccountDetails.completedPassword) {
                    navigate("/pages/register/register3");
                }
            } else {
                // TODO move to own logout function and delete all other data
                dispatch({
                    type: SHIFTS_DELETE_ALL,
                });

                dispatch({
                    type: CONVOS_DELETE_ALL,
                });

                dispatch({
                    type: ACCOUNT_LOGOUT,
                });

                if (!url.includes("/pages/login/login3")) {
                    window.location.href = "/pages/login/login3";
                }
            }

        };

        getAccountDetails();
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
