import axios from "axios";

export const requestShifts = async (params, headers) => {
    //   console.log(`Requesting shifts. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/users?${params}`,
        // withCredentials: true,
        headers,
    });
};

export const requestConvos = async (params, headers) => {
    // console.log(`Requesting convos. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        // TODO uncomment
        // url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/conversations?${params}`,
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/conversations?${params}`,
        // withCredentials: true,
        headers,
    });
};

export const requestEvents = async (params, headers) => {
    // console.log(`Requesting events. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/postings?${params}`,
        // withCredentials: true,
        headers,
    });
};

export const requestRules = async (params, headers) => {
    // console.log(`Requesting rules. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/rules?${params}`,
        // withCredentials: true,
        headers,
    });
};

export const requestFlags = async (params, headers) => {
    // console.log(`Requesting flags. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/flagged-instances?${params}`,
        // withCredentials: true,
        headers,
    });
};

export const requestNotifications = async (params, headers) => {
    // console.log(`Requesting flags. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/notifications?${params}`,
        // withCredentials: true,
        headers,
    });
};

export const requestResources = async (params, headers) => {
    console.log(`Requesting resources. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/resources?${params}`,
        // withCredentials: true,
        headers,
    });
}

export const requestGroups = async (params, headers) => {
    console.log(`Requesting groups. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/groups?${params}`,
        // withCredentials: true,
        headers,
    });
}

export const requestSuggestions = async (params, headers) => {
    // console.log(`Requesting suggestions. Params = ${params}, Headers = ${headers}`);
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/suggestions?${params}`,
        // withCredentials: true,
        headers,
    });
};