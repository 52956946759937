// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
    firstFetchMade: false,
    shiftPostings: [],
    adminUsers: [],
    wipChampions: {},
};

// ==============================|| SHIFTS REDUCER ||============================== //

const shiftsReducer = (state = initialState, action) => {
    // console.log('Fetch shifts reducer. Action = ', action);
    switch (action.type) {
        case actionTypes.SHIFTS_FETCH_SUCCEEDED:
            console.log("GOT AMBASSADORS:", action.shiftPostings);
            const adminsToAdd = action.shiftPostings.filter(
                (ambassador) => ambassador.type === "employer"
            );
            const ambassadorsToAdd = action.shiftPostings.filter(
                (ambassador) => ambassador.type === "worker"
            );
            const shiftsToAdd = ambassadorsToAdd.filter(
                (shift) => !(state.shiftPostings.filter(existing => existing.id === shift.id).length > 0)
            );
            const newAdminsToAdd = adminsToAdd.filter(
                (admin) => !(state.adminUsers.filter(existing => existing.id === admin.id).length > 0)
            );

            // Check and correct for Availability being in UTC
            shiftsToAdd.forEach((shift) => {
                const shiftData = shift.attributes ? shift.attributes : shift;
                if (!shiftData) return;
                // Do so for shifts availability
                // if (shiftData.Availability && shiftData.Availability.length) {
                //     const newAvailability = shiftData.Availability.map((availability) => {
                //         const timezoneOffset =
                //             new Date().getTimezoneOffset() * 60 * 1000;
                //         const startTime = new Date(
                //             new Date(
                //                 `1970-01-01T${availability.startTime}Z`
                //             ).getTime() - timezoneOffset
                //         )
                //             .toISOString()
                //             .split("T")[1]
                //             .substring(0, 8);
                //         const endTime = new Date(
                //             new Date(
                //                 `1970-01-01T${availability.endTime}Z`
                //             ).getTime() - timezoneOffset
                //         )
                //             .toISOString()
                //             .split("T")[1]
                //             .substring(0, 8);
                //         return { ...availability, startTime, endTime };
                //     });
                //     shiftData.Availability = newAvailability;
                // }
                // // And also for chats availability
                // if (shiftData.chatsAvailability && shiftData.chatsAvailability.length) {
                //     const newAvailability = shiftData.chatsAvailability.map((availability) => {
                //         const timezoneOffset =
                //             new Date().getTimezoneOffset() * 60 * 1000;
                //         const startTime = new Date(
                //             new Date(
                //                 `1970-01-01T${availability.startTime}Z`
                //             ).getTime() - timezoneOffset
                //         )
                //             .toISOString()
                //             .split("T")[1]
                //             .substring(0, 8);
                //         const endTime = new Date(
                //             new Date(
                //                 `1970-01-01T${availability.endTime}Z`
                //             ).getTime() - timezoneOffset
                //         )
                //             .toISOString()
                //             .split("T")[1]
                //             .substring(0, 8);
                //         return { ...availability, startTime, endTime };
                //     });
                //     shiftData.chatsAvailability = newAvailability;
                // }
            });

            const newShiftSet = [...state.shiftPostings, ...shiftsToAdd];
            const newAdminSet = [...state.adminUsers, ...newAdminsToAdd];

            const newWipChampions = newShiftSet.reduce(
                (acc, curr) => ((acc[curr.id] = curr), acc),
                {}
            );

            return {
                ...state,
                firstFetchMade: true,
                shiftPostings: newShiftSet,
                adminUsers: newAdminSet,
                wipChampions: newWipChampions,
            };
        case actionTypes.SHIFTS_FETCH_FAILED:
            // console.log(action.message);
            return state;
        case actionTypes.SHIFTS_ADD_OR_UPDATE:
            // console.log("STATE", state.shiftPostings);

            const shiftToUpdate = state.shiftPostings.find(
                (shift) => shift.id === action.updatedShift.id
            );

            if (shiftToUpdate) {
                NestedObjectAssign(shiftToUpdate, action.updatedShift);
                // Object.assign(shiftToUpdate, action.updatedShift);
            }
            const addedShift = shiftToUpdate ? [] : [action.updatedShift];

            const newShifts = [...state.shiftPostings, ...addedShift]; /*.sort(
        (shiftA, shiftB) => Date.parse(shiftB.time) - Date.parse(shiftA.time)
      )*/

            const updatedWipChampions = newShifts.reduce(
                (acc, curr) => ((acc[curr.id] = curr), acc),
                {}
            );

            return {
                ...state,
                shiftPostings: newShifts,
                wipChampions: updatedWipChampions,
            };

        case actionTypes.SHIFTS_WIP_UPDATE:
            const { id, key, val } = action;

            return {
                ...state,
                wipChampions: {
                    ...state.wipChampions,
                    [id]: {
                        id,
                        attributes: {
                            ...state.wipChampions[id].attributes,
                            [key]: val,
                        },
                    },
                }
            }

        case actionTypes.SHIFTS_DELETE:
            // console.log("Before deleted champs: ", state.shiftPostings);

            const filteredShifts = state.shiftPostings.filter(
                (shift) => shift.id != action.deletedShiftID
            );

            const newWipDeletedChampions = filteredShifts.reduce(
                (acc, curr) => ((acc[curr.id] = curr), acc),
                {}
            );

            // console.log("After deleted champs: ", filteredShifts);

            return {
                ...state,
                shiftPostings: filteredShifts,
                wipChampions: newWipDeletedChampions,
            };

        case actionTypes.SHIFTS_DELETE_ALL:
            return initialState;

        default:
            return state;
    }
};

export default shiftsReducer;
