import PropTypes from "prop-types";

import { useState, useRef, useEffect, forwardRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
    EVENTS_ADD_OR_UPDATE,
    SET_VIEW_EVENT_DRAWER,
    SET_VIEW_TIMESHEET_DRAWER,
} from "store/actions";

import * as Sentry from "@sentry/react";

import { getCurrency } from "utils/currency";

import { useTheme } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Box,
    Drawer,
    useMediaQuery,
    Chip,
    Stack,
    Typography,
    Grid,
    Avatar,
    ClickAwayListener,
    Collapse,
    List,
    FormControl,
    FormHelperText,
    TextField,
    FormControlLabel,
    Checkbox,
    Divider,
} from "@mui/material";
import { IconCircleX } from "@tabler/icons-react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Formik } from "formik";

import CircularProgress, {
    circularProgressClasses,
} from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/system";

import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import axios from "axios";
import dayjs from "dayjs";

import { IconCirclePlus, IconArrowLeft, IconExternalLink } from "@tabler/icons-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { getEventEffectiveHours, getEventSubTotal, getEventType } from "../../../utils/events";
import {
    getOpeningStatus,
    isOpeningComplete,
    getOpeningTimesheetStatus,
} from "../../../utils/openings";
// import EWCircularProgress from "../../../ui-component/extended/EWCircularProgress";

export const googleMapsURLParams =
    "&zoom=18&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=800x375";

const timeErrorMessage = "Must be a valid time (e.g. 05:30 PM)";

const container = window !== undefined ? () => window.document.body : undefined;

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const QuestionsBar = ({ sx }) => (
    <Stack
        direction="row"
        mt="20px"
        pt="2%"
        pl="3%"
        pr="10px"
        pb="10px"
        sx={{
            background: (theme) => theme.palette.secondary.dark,
            borderRadius: "17px",
            width: "90%",
            justifyContent: "space-between",
            ...sx,
        }}
    >
        <Typography
            fontSize="1.2rem"
            fontWeight="700"
            color="white"
            alignSelf="center"
        >
            Questions?
        </Typography>
        <Chip
            sx={{
                borderRadius: "50px",
                background: "white",
                color: (theme) => theme.palette.secondary.dark,
                cursor: "pointer",
                borderWidth: "1px",
                borderColor: "white",
                borderStyle: "solid",
                p: "5px",
                alignSelf: "center",
                // pb: "5px",
                "&:hover": {
                    background: "#f5f5f5",
                },
            }}
            label={
                <Typography
                    sx={{
                        color: (theme) => theme.palette.secondary.dark,
                    }}
                >
                    Contact Us
                </Typography>
            }
            onClick={() => {
                window.open("https://discord.com/channels/1198313010884780104");
            }}
        />
    </Stack>
);

function getShiftDurationHours(startTime, endTime) {
    if (
        !startTime ||
        isNaN(Date.parse(startTime)) ||
        !endTime ||
        isNaN(Date.parse(endTime))
    ) {
        return 0;
    }

    const milliseconds = Date.parse(endTime) - Date.parse(startTime);

    //Get hours from milliseconds
    return milliseconds / (1000 * 60 * 60);
}

const ViewTimesheetDrawer = ({ drawerOpen, drawerClose, shiftID = -1 }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));

    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [disputeOpening, setDisputeOpening] = useState({ id: -1 });
    const [approveOpenings, setApproveOpenings] = useState([]);

    console.log("VIEW TIMESHEET FOR POST SHIFT: ", shiftID);

    // console.log("TARGET SHIFT ID: ", shiftID);

    const dispatch = useDispatch();

    const account = useSelector((state) => state.account);
    const { accessToken, paymentsEnabled, autoClocking } = account;

    const { eventPostings: newShifts } = useSelector((state) => state.events);

    const targetShift =
        newShifts.length > 0 && newShifts.some((shift) => shift.id === shiftID)
            ? newShifts.find((shift) => shift.id === shiftID)
            : {
                id: 0,
                attributes: {
                    workType: "",
                    summary: "",
                    workDuties: "",
                    breakDurationMins: 0,
                    shiftDurationMins: 0,
                    ratePerHourCAD: 0,
                    time: dayjs().toISOString(),
                    totalOpenings: 0,
                    address: "N/A",
                    lat: 19.278093,
                    lng: -77.337419,
                    status: "active",
                    shifts: { data: [] },
                },
            };

    const {
        time,
        workType,
        address,
        workDuties,
        shiftDurationMins,
        breakDurationMins,
        ratePerHourCAD,
        totalOpenings,
        shifts,
        lat,
        lng,
    } = targetShift.attributes;

    const shiftType = getEventType(targetShift);

    const startTime = new Date(Date.parse(time));
    const endTime = new Date(Date.parse(time) + shiftDurationMins * 60 * 1000);

    console.log("SHIFT TIME ", time);

    const filledOpenings = shifts.data;
    const numFilledOpenings = filledOpenings.length;
    // TODO proper error checking to ensure totalOpenings is > 0 and > number of filled shifts
    const reviewedOpenings = filledOpenings.filter(
        (opening) =>
            getOpeningTimesheetStatus(opening) === "Disputed" ||
            getOpeningTimesheetStatus(opening) === "Confirmed"
    );
    const reviewedOpeningsPercent =
        numFilledOpenings > 0
            ? numFilledOpenings > reviewedOpenings.length
                ? (reviewedOpenings.length / numFilledOpenings) * 100
                : 100
            : 0;

    const outstandingOpeningsToApprove = filledOpenings.filter(
        (opening) =>
            getOpeningTimesheetStatus(opening) !== "Disputed" &&
            getOpeningTimesheetStatus(opening) !== "Confirmed"
    );

    const totalWorkedHours = getEventEffectiveHours(targetShift, autoClocking);

    // Used for computation in prices and total hours
    const effectiveOpenings =
        shiftType === "Cancelled"
            ? 0
            : shiftType === "Scheduled" || shiftType === "Draft"
                ? totalOpenings
                : numFilledOpenings;

    const subTotal = getEventSubTotal(targetShift, autoClocking);

    const initialVals = {
        startTime: targetShift.id ? dayjs(targetShift.attributes.time) : "",
        endTime: targetShift.id
            ? dayjs(
                new Date(
                    Date.parse(targetShift.attributes.time) +
                    targetShift.attributes.shiftDurationMins * 60 * 1000
                )
            )
            : "",
        disputeReason: "",
        reportedAbsentByCompany: false,
    };

    // useEffect(() => {
    //   if (formRef.current) {
    //     // Ensure the form is initialized with the correct information
    //     formRef.current.resetForm({
    //       values: initialVals,
    //     });
    //   }
    // }, [disputeOpening]);

    const submitOpeningActualTime = (targetOpening, values, dispute = true) => {
        if (targetOpening.id <= 0) return;

        // console.log(values);

        setLoading(true);

        const openingToUpdate = targetShift.attributes.shifts.data.find(
            (opening) => opening.id === targetOpening.id
        );
        const shiftDate = dayjs(targetShift.attributes.time);

        if (!shiftDate || !openingToUpdate) {
            Sentry.captureException(new Error("Invalid shift details"));
            setErrMsg("Unable to process the shift details.");
            // console.log(
            //   `Shift details (${shiftDate}) (${openingToUpdate}) are invalid.`
            // );
            return;
        }

        console.log("VALUES FOR APPROVAL", values);

        if (!values.startTime) {
            // If both are null, then the opening is being marked as absent
            values.reportedAbsentByCompany = true;
        }

        if (
            !values.reportedAbsentByCompany &&
            (!values.startTime ||
                !values.endTime ||
                isNaN(Date.parse(startTime.$d) || isNaN(Date.parse(endTime.$d))))
        ) {
            Sentry.captureException(new Error("Invalid form values"));
            setErrMsg(
                `Unable to process the ${dispute ? "dispute" : "approval"} details.`
            );
            // console.log(`Form values (${values}) are invalid.`);
            return;
        }

        // TODO address edge cases when they're close to midnight
        const startDateAndTime = values.startTime
            ? values.startTime
                .set("date", shiftDate.$D)
                .set("month", shiftDate.$M)
                .set("year", shiftDate.$y)
            : // .toISOString()
            null;

        let endDateAndTime = values.endTime
            ? values.endTime
                .set("date", shiftDate.$D)
                .set("month", shiftDate.$M)
                .set("year", shiftDate.$y)
            : // .toISOString()
            null;

        if (
            startDateAndTime &&
            endDateAndTime &&
            !isNaN(
                Date.parse(startDateAndTime.$d) && !isNaN(Date.parse(endDateAndTime.$d))
            )
        ) {
            if (endDateAndTime.$d <= startDateAndTime.$d) {
                endDateAndTime = endDateAndTime.add(24, "hour");
            }
        }

        const openingData = {
            approvedClockIn: startDateAndTime ? startDateAndTime.toISOString() : null,
            approvedClockOut: (startDateAndTime && endDateAndTime) ? endDateAndTime.toISOString() : null,
            ...(!dispute
                ? {
                    resolvedClockIn: startDateAndTime
                        ? startDateAndTime.toISOString()
                        : null,
                    resolvedClockOut: (startDateAndTime && endDateAndTime)
                        ? endDateAndTime.toISOString()
                        : null,
                }
                : {}),
            disputeReason: values.disputeReason || "",
            reportedAbsentByCompany: values.reportedAbsentByCompany || false,
            disputedByCompany: dispute,
        };

        const apiURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/shifts/${targetOpening.id}?populate=user`;

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const apiCb = (response) => {
            console.log(response);
            const { data } = response;
            if (!data || !data.data) {
                console.log("Unable to parse response from server");
                return;
            }

            Object.assign(openingToUpdate, data.data);

            // Add shift to our redux store
            dispatch({
                type: EVENTS_ADD_OR_UPDATE,
                updatedEvents: [targetShift],
            });
            // 0 displays success message, -1 closes dialog
            if (dispute) {
                setDisputeOpening({ id: 0 });
            } else {
                setApproveOpenings([]);
            }
        };

        const apiErr = (e) => {
            Sentry.captureException(e);
            setErrMsg("Please check your connection and try again.");
            console.log(e);
        };

        console.log(openingData);

        axios
            .put(
                apiURL,
                {
                    data: openingData,
                },
                apiHeaders
            )
            .then(apiCb)
            .catch(apiErr)
            .finally(setLoading(false));
    };

    const resetEverything = () => {
        setLoading(false);
        setErrMsg("");
    };

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: "auto" }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                anchor="right"
                open={drawerOpen}
                onClose={(e) => {
                    resetEverything();
                    drawerClose(e);
                }}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: matchUpMd ? "612px" : "100vw",
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: "none",
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(errMsg)}
                    autoHideDuration={6000}
                    onClose={() => setErrMsg("")}
                >
                    <Alert
                        onClose={() => setErrMsg("")}
                        severity="error"
                        sx={{ width: "100%", whiteSpace: "pre-line" }}
                    >
                        Something went wrong.{`\n`}
                        {errMsg}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(successMsg)}
                    autoHideDuration={6000}
                    onClose={() => setSuccessMsg("")}
                >
                    <Alert
                        onClose={() => setSuccessMsg("")}
                        severity="success"
                        sx={{ width: "100%", whiteSpace: "pre-line" }}
                    >
                        {successMsg}
                    </Alert>
                </Snackbar>
                <Dialog
                    fullScreen={matchDownMd}
                    open={Boolean(disputeOpening.id >= 0)}
                    onClose={() => setDisputeOpening({ id: -1 })}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="sm"
                    fullWidth
                    sx={{
                        "& .MuiPaper-root": {
                            borderRadius: "15px",
                        },
                    }}
                >
                    <IconCircleX
                        cursor="pointer"
                        color={theme.palette.grey[700]}
                        stroke={0.3}
                        size="2.5rem"
                        onClick={(e) => setDisputeOpening({ id: -1 })}
                        style={{
                            alignSelf: "flex-end",
                            marginRight: "10px",
                            marginTop: "10px",
                        }}
                    />
                    <DialogTitle
                        variant="h2"
                        id="responsive-dialog-title"
                        color={theme.palette.primary.dark}
                        textAlign="center"
                        fontSize="1.75rem"
                    >
                        Correct Reported Hours
                    </DialogTitle>
                    {/* <DialogContentText variant="h3" whiteSpace="pre-line">
              This action cannot be undone and may impact your rating with
              workers.
              {totalOpenings > numFilledOpenings &&
                ` Consider removing one of the unfilled openings instead.`}
            </DialogContentText> */}
                    {disputeOpening.id > 0 && (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Formik
                                initialValues={initialVals}
                                initialStatus={{
                                    // HACK had to use status for errors since the validation schema
                                    // used by errors was not behaving the way I expected (should revisit later)
                                    startTimeError: "",
                                    endTimeError: "",
                                    dateOpen: false,
                                    startTimeOpen: false,
                                    endTimeOpen: false,
                                }}
                                // innerRef={formRef}
                                onSubmit={async (
                                    values,
                                    { setErrors, setStatus, setSubmitting }
                                ) => { }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    setFieldValue,
                                    setErrors,
                                    setStatus,
                                    status,
                                    isValid,
                                    isValidating,
                                }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <DialogContent>
                                            <Grid
                                                container
                                                p="5%"
                                                rowSpacing={4}
                                                sx={{
                                                    background: "white",
                                                    borderRadius: "27px",
                                                }}
                                            >
                                                <Grid item xs={6} md={4}>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="100"
                                                        sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                                    >
                                                        Reported Start Time
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{ color: theme.palette.secondary.dark }}
                                                    >
                                                        {disputeOpening.id > 0 &&
                                                            disputeOpening.attributes &&
                                                            disputeOpening.attributes.shiftClockIn
                                                            ? new Date(
                                                                Date.parse(
                                                                    disputeOpening.attributes.shiftClockIn
                                                                )
                                                            ).toLocaleTimeString("en", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })
                                                            : disputeOpening.id > 0 && autoClocking ? startTime.toLocaleTimeString("en", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }) : "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="100"
                                                        sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                                    >
                                                        Reported End Time
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{ color: theme.palette.secondary.dark }}
                                                    >
                                                        {disputeOpening.id > 0 &&
                                                            disputeOpening.attributes &&
                                                            disputeOpening.attributes.shiftClockIn
                                                            ? new Date(
                                                                Date.parse(
                                                                    disputeOpening.attributes.shiftClockOut ||
                                                                    endTime
                                                                )
                                                            ).toLocaleTimeString("en", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })
                                                            : disputeOpening.id > 0 && autoClocking ? endTime.toLocaleTimeString("en", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            }) : "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="100"
                                                        sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                                    >
                                                        Reported Hours
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{ color: theme.palette.secondary.dark }}
                                                    >
                                                        {Boolean(disputeOpening.id > 0) &&
                                                            getShiftDurationHours(
                                                                disputeOpening.attributes.resolvedClockIn ||
                                                                disputeOpening.attributes.approvedClockIn ||
                                                                (autoClocking ? startTime : disputeOpening.attributes.shiftClockIn),
                                                                disputeOpening.attributes.resolvedClockOut ||
                                                                disputeOpening.attributes.approvedClockOut ||
                                                                disputeOpening.attributes.shiftClockOut ||
                                                                endTime
                                                            ).toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="100"
                                                        sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                                    >
                                                        Enter Start Time
                                                    </Typography>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(status.startTimeError)}
                                                    // sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <TimePicker
                                                            id="outlined-adornment-startTime-register"
                                                            // label="Start Time"
                                                            defaultValue={""}
                                                            name="startTime"
                                                            value={values.startTime}
                                                            onBlur={handleBlur}
                                                            error={Boolean(status.startTimeError)}
                                                            minutesStep={5}
                                                            showToolbar
                                                            disabled={values.reportedAbsentByCompany}
                                                            {...(matchUpMd
                                                                ? { open: status.startTimeOpen }
                                                                : {})}
                                                            onClose={() => {
                                                                setStatus({
                                                                    ...status,
                                                                    startTimeOpen: false,
                                                                });
                                                            }}
                                                            onChange={(value) => {
                                                                if (!value) {
                                                                    setStatus({
                                                                        ...status,
                                                                        startTimeError: "Start time required",
                                                                    });
                                                                } else if (isNaN(Date.parse(value.$d))) {
                                                                    setStatus({
                                                                        ...status,
                                                                        startTimeError: timeErrorMessage,
                                                                    });
                                                                } else {
                                                                    setStatus({
                                                                        ...status,
                                                                        startTimeError: "",
                                                                    });
                                                                }
                                                                // setStartTime(value);
                                                                setFieldValue("startTime", value, false);
                                                                console.log(errors);
                                                            }}
                                                            // sx={{ ...theme.typography.customInput }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    // sx={{ ...theme.typography.customInput }}
                                                                    sx={{ width: "90%" }}
                                                                    name="startTime"
                                                                    disabled
                                                                    autoComplete="off"
                                                                    value=""
                                                                    onClick={() =>
                                                                        !values.reportedAbsentByCompany &&
                                                                        setStatus({
                                                                            ...status,
                                                                            startTimeOpen: true,
                                                                        })
                                                                    }
                                                                    {...params}
                                                                    error={Boolean(status.startTimeError)}
                                                                />
                                                                // <></>
                                                            )}
                                                        />
                                                        {!!status.startTimeError && (
                                                            <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-startTime-register"
                                                            >
                                                                {status.startTimeError}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="100"
                                                        sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                                    >
                                                        Enter End Time
                                                    </Typography>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(status.endTimeError)}
                                                    // sx={{ ...theme.typography.customInput }}
                                                    >
                                                        <TimePicker
                                                            id="outlined-adornment-endTime-register"
                                                            // label="End Time"
                                                            name="endTime"
                                                            value={values.endTime}
                                                            onError={(e) => { }}
                                                            onBlur={handleBlur}
                                                            error={Boolean(status.endTimeError)}
                                                            minutesStep={5}
                                                            showToolbar
                                                            disabled={values.reportedAbsentByCompany}
                                                            {...(matchUpMd
                                                                ? { open: status.endTimeOpen }
                                                                : {})}
                                                            onClose={() =>
                                                                setStatus({
                                                                    ...status,
                                                                    endTimeOpen: false,
                                                                })
                                                            }
                                                            onChange={(value) => {
                                                                if (!value) {
                                                                    setStatus({
                                                                        ...status,
                                                                        endTimeError: "End time required",
                                                                    });
                                                                } else if (isNaN(Date.parse(value.$d))) {
                                                                    setStatus({
                                                                        ...status,
                                                                        endTimeError: timeErrorMessage,
                                                                    });
                                                                } else {
                                                                    setStatus({
                                                                        ...status,
                                                                        endTimeError: "",
                                                                    });
                                                                }
                                                                // setEndTime(value);
                                                                setFieldValue("endTime", value, false);
                                                            }}
                                                            // sx={{ ...theme.typography.customInput }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    fullWidth
                                                                    // sx={{ ...theme.typography.customInput }}
                                                                    sx={{ width: "90%" }}
                                                                    name="endTime"
                                                                    disabled
                                                                    autoComplete="off"
                                                                    onClick={() =>
                                                                        !values.reportedAbsentByCompany &&
                                                                        setStatus({
                                                                            ...status,
                                                                            endTimeOpen: true,
                                                                        })
                                                                    }
                                                                    {...params}
                                                                    error={Boolean(status.endTimeError)}
                                                                />
                                                            )}
                                                        />
                                                        {status.endTimeError && (
                                                            <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-endTime-register"
                                                            >
                                                                {status.endTimeError}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} md={4}>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="100"
                                                        sx={{ color: theme.palette.grey[600], mb: "25px" }}
                                                    >
                                                        Actual Hours
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{ color: theme.palette.secondary.dark }}
                                                    >
                                                        {values.startTime != values.endTime &&
                                                            values.startTime &&
                                                            values.endTime &&
                                                            !(status.startTimeError || status.endTimeError)
                                                            ? getShiftDurationHours(
                                                                values.startTime,
                                                                values.endTime
                                                            ).toFixed(2)
                                                            : "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.reportedAbsentByCompany}
                                                            />
                                                        }
                                                        label="Ambassador did not show up"
                                                        name="reportedAbsentByCompany"
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant="h4"
                                                        fontWeight="100"
                                                        sx={{ color: theme.palette.grey[600], mb: "25px" }}
                                                    >
                                                        Enter Reason for Correction
                                                    </Typography>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(
                                                            touched.disputeReason && errors.disputeReason
                                                        )}
                                                    >
                                                        <TextField
                                                            id="input-dispute-reason"
                                                            placeholder={`Example: Ambassador showed up late and did not report it.`}
                                                            // label="Description"
                                                            multiline
                                                            rows={3}
                                                            maxRows={Infinity}
                                                            fullWidth
                                                            // required
                                                            name="disputeReason"
                                                            value={values.disputeReason}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions
                                            sx={{ alignSelf: "center", justifyContent: "center" }}
                                        >
                                            <LoadingButton
                                                loading={loading}
                                                autoFocus
                                                variant="contained"
                                                size="large"
                                                onClick={() => {
                                                    submitOpeningActualTime(disputeOpening, values);
                                                }}
                                                sx={{
                                                    borderRadius: "27px",
                                                    fontWeight: "700",
                                                    mt: "-30px",
                                                    mb: "20px",
                                                }}
                                                disabled={
                                                    !values.reportedAbsentByCompany &&
                                                    (status.startTimeError ||
                                                        status.endTimeError ||
                                                        errors.disputeReason ||
                                                        !values.startTime ||
                                                        !values.endTime)
                                                }
                                            >
                                                Submit Correction
                                            </LoadingButton>
                                        </DialogActions>
                                    </form>
                                )}
                            </Formik>
                        </LocalizationProvider>
                    )}
                    {disputeOpening.id === 0 && (
                        <Stack
                            // padding="5%"
                            pt="0"
                            sx={{
                                ...theme.cards.agreementListItem,
                                textAlign: "center",
                                alignItems: "center",
                                p: matchUpMd ? "2rem 7rem" : "2rem 2rem",
                                m: "2rem",
                                mt: "1rem !important",
                            }}
                            spacing={3}
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    color: theme.palette.secondary.dark,
                                    fontSize: "1.5rem",
                                }}
                            >
                                ✅ &nbsp;Correction Submitted
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{
                                    color: "darkgrey",
                                    fontSize: "1.2rem",
                                }}
                            >
                                The ambassador's hours have been updated and the budget accounting for this shift has been adjusted accordingly.
                            </Typography>
                            {/* <Typography
                variant="h4"
                sx={{
                  color: "darkgrey",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={(e) => setDisputeOpening({ id: -1 })}
              >
                Exit
              </Typography> */}
                        </Stack>
                    )}
                </Dialog>
                <Dialog
                    fullScreen={matchDownMd}
                    open={approveOpenings.length > 0}
                    onClose={() => setApproveOpenings([])}
                    aria-labelledby="responsive-dialog-title-approve"
                >
                    <IconCircleX
                        cursor="pointer"
                        color={theme.palette.grey[700]}
                        stroke={0.3}
                        size="2.5rem"
                        onClick={(e) => setApproveOpenings([])}
                        style={{
                            alignSelf: "flex-end",
                            marginRight: "10px",
                            marginTop: "10px",
                        }}
                    />
                    <DialogTitle
                        variant="h2"
                        id="responsive-dialog-title-approve"
                        color={theme.palette.primary.dark}
                        alignSelf="center"
                    >
                        {"Approve Reported Hours"}
                    </DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText variant="h3" whiteSpace="pre-line" mt="20px">
              {new Date(Date.parse(targetShift.attributes.time)).toLocaleString(
                "en",
                {
                  month: "long",
                  day: "numeric",
                  weekday: "long",
                  year: "numeric",
                }
              )}
            </DialogContentText> */}
                        {approveOpenings.map((opening, i) => (
                            <>
                                <Grid
                                    key={opening.id}
                                    container
                                    p="5%"
                                    rowSpacing={4}
                                    columnSpacing={2}
                                    sx={{
                                        borderRadius: "27px",
                                    }}
                                >
                                    <Grid item xs={4} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Ambassador
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {opening.id > 0 &&
                                                opening.attributes.user &&
                                                opening.attributes.user.data ? (
                                                <Box
                                                    sx={{
                                                        // float: "left",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        // alignItems: "center",
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            width: "27px",
                                                            height: "27px",
                                                            fontSize: "1rem",
                                                            mt: "-5px",
                                                            mr: "10px",
                                                            // margin: "8px!important",
                                                            // ml: "0px !important",
                                                            cursor: "pointer",
                                                        }}
                                                        aria-controls="menu-list-grow"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    >
                                                        <h5>
                                                            {opening.attributes.user.data.attributes
                                                                .firstName &&
                                                                opening.attributes.user.data.attributes.firstName
                                                                    .length > 0 &&
                                                                opening.attributes.user.data.attributes
                                                                    .lastName &&
                                                                opening.attributes.user.data.attributes.lastName
                                                                    .length > 0
                                                                ? `${opening.attributes.user.data.attributes.firstName
                                                                    .charAt(0)
                                                                    .toUpperCase()}${opening.attributes.user.data.attributes.lastName
                                                                        .charAt(0)
                                                                        .toUpperCase()}`
                                                                : "NI"}
                                                        </h5>
                                                    </Avatar>
                                                    <Typography
                                                        variant="h4"
                                                        color={theme.palette.primary.dark}
                                                    >
                                                        {opening.attributes.user.data.attributes.firstName}{" "}
                                                        {opening.attributes.user.data.attributes.lastName
                                                            ? `${opening.attributes.user.data.attributes.lastName
                                                                .charAt(0)
                                                                .toUpperCase()}.`
                                                            : ""}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                "--"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Shift Date
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {new Date(Date.parse(time)).toLocaleString("en", {
                                                month: "long",
                                                day: "numeric",
                                                year: "numeric",
                                            })}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Assignment ID
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {opening.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Reported Start Time
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {opening.id > 0 &&
                                                opening.attributes &&
                                                opening.attributes.shiftClockIn
                                                ? new Date(
                                                    Date.parse(opening.attributes.shiftClockIn)
                                                ).toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })
                                                : opening.id > 0 && autoClocking ? startTime.toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                }) : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Reported End Time
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {opening.id > 0 &&
                                                opening.attributes &&
                                                opening.attributes.shiftClockIn
                                                ? new Date(
                                                    Date.parse(
                                                        opening.attributes.shiftClockOut || endTime
                                                    )
                                                ).toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })
                                                : opening.id > 0 ? endTime.toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                }) : "--"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Reported Hours
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {Boolean(opening.id > 0) &&
                                                getShiftDurationHours(
                                                    opening.attributes.resolvedClockIn ||
                                                    opening.attributes.approvedClockIn ||
                                                    (autoClocking ? startTime : opening.attributes.shiftClockIn),
                                                    opening.attributes.resolvedClockOut ||
                                                    opening.attributes.approvedClockOut ||
                                                    opening.attributes.shiftClockOut ||
                                                    endTime
                                                ).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </>
                        ))}
                    </DialogContent>
                    <DialogActions sx={{ alignSelf: "center", justifyContent: "center" }}>
                        <Box
                            direction="column"
                            alignSelf="center"
                            textAlign="center"
                            mb={3}
                        >
                            <LoadingButton
                                loading={loading}
                                autoFocus
                                variant="contained"
                                size="large"
                                alignSelf="center"
                                onClick={() => {
                                    approveOpenings.forEach((opening) => {
                                        const values = {
                                            startTime: (opening.attributes.shiftClockIn || (autoClocking && startTime)) ? dayjs(opening.attributes.shiftClockIn ?? startTime) : null,
                                            endTime: (opening.attributes.shiftClockOut || endTime) ? dayjs(
                                                opening.attributes.shiftClockOut ?? endTime
                                            ) : null,
                                            disputeReason: "",
                                            reportedAbsentByCompany: false,
                                        };
                                        submitOpeningActualTime(opening, values, false);
                                    });
                                }}
                                sx={{
                                    borderRadius: "27px",
                                    fontWeight: "700",
                                    // mt: "-30px",
                                    mb: "20px",
                                }}
                            >
                                Approve
                            </LoadingButton>
                            <Typography variant="h4" color={theme.palette.grey[500]}>
                                Approving hours cannot be undone.
                            </Typography>
                        </Box>
                    </DialogActions>
                </Dialog>
                {loading && (
                    <Box sx={{ alignSelf: "center" }}>
                        <CircularProgress size="4rem" color="primary" mt="50%" />
                    </Box>
                )}
                {!loading && (
                    <Box
                        sx={{
                            backgroundColor: theme.palette.secondary.dark,
                            padding: "5%",
                            pb: "7%",
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between" mb="2rem">
                            <Stack
                                direction="row"
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "white",
                                }}
                            >
                                <Chip
                                    sx={{
                                        height: "40px",
                                        width: "60px",
                                        borderRadius: "50px",
                                        background: "white",
                                        color: "white",
                                        // position: "absolute",
                                        // top: matchUpMd ? "2rem" : "1rem",
                                        // left: matchUpMd ? "2rem" : "1rem",
                                        pt: "5px",
                                        cursor: "pointer",
                                        borderWidth: "1px",
                                        borderColor: "white",
                                        borderStyle: "solid",
                                        "&:hover": {
                                            background: theme.palette.grey[100],
                                        },
                                    }}
                                    label={<IconArrowLeft color={theme.palette.primary.main} />}
                                    onClick={() => {
                                        resetEverything();
                                        drawerClose();
                                    }}
                                />
                                <Typography
                                    variant="h3"
                                    color="darkgray"
                                    fontWeight="100"
                                    ml="20px"
                                    mt="5px"
                                >{`ID: ${targetShift.id}`}</Typography>
                            </Stack>
                            <a target="_blank" href="https://discord.com/channels/1198313010884780104">
                                <Stack
                                    direction="row"
                                    sx={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                    }}
                                >
                                    <HelpOutlineIcon
                                        style={{ fontSize: "23px", color: theme.palette.grey[500] }}
                                    />{" "}
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            textDecoration: "none",
                                            color: theme.palette.grey[500],
                                            fontWeight: 600,
                                            fontSize: "17px",
                                        }}
                                        marginLeft="5px"
                                    >
                                        24/7 Live Support
                                    </Typography>
                                </Stack>
                            </a>
                        </Stack>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 2 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            mb="25px"
                        >
                            <Grid item md={12} sx={{ justifyContent: "right" }}>
                                <Stack
                                    direction="row"
                                    display="flex"
                                    spacing={2}
                                    sx={{
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        sx={{
                                            color: theme.palette.success.light,
                                            fontSize: "20px",
                                            fontWeight: 100,
                                            mt: "15px",
                                        }}
                                    >
                                        Total Budget
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography
                                    component="span"
                                    sx={{
                                        color: "white",
                                        fontSize: "5.3rem",
                                        fontWeight: 600,
                                    }}
                                >
                                    $
                                    {subTotal.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 2 }}
                            columns={{ xs: 3, sm: 8, md: 12 }}
                        >
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Shift Date
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {new Date(Date.parse(time))
                                        .toLocaleString("en", {
                                            month: "long",
                                            day: "numeric",
                                        })
                                        .split(" ")
                                        .reverse()
                                        .join(" ")}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Start
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {startTime.toLocaleTimeString("en", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    End
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {endTime.toLocaleTimeString("en", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={12} md={12}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Location
                                </Typography>
                                <Stack direction="row" alignItems="flex-end" spacing={1}>
                                    <Typography variant="h4" sx={{ color: "white" }}>
                                        {address}{" "}
                                        {lat && lng && (
                                            <IconExternalLink
                                                height="1rem"
                                                width="1.2rem"
                                                color="white"
                                                cursor="pointer"
                                                onClick={() =>
                                                    window.open(
                                                        `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
                                                        "_blank"
                                                    )
                                                }
                                            />
                                        )}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Openings Filled
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {numFilledOpenings}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={3}>
                                <Typography
                                    variant="h4"
                                    sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                >
                                    Total Hours
                                </Typography>
                                <Typography variant="h4" sx={{ color: "white" }}>
                                    {totalWorkedHours.toFixed(2)}
                                </Typography>
                            </Grid>
                            {
                                paymentsEnabled && (
                                    <Grid item xs={1} md={3}>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.grey[200], mb: "12px" }}
                                        >
                                            Hourly Rate
                                        </Typography>
                                        <Typography variant="h4" sx={{ color: "white" }}>
                                            $
                                            {ratePerHourCAD.toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}{" "}
                                            {getCurrency()}
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>

                        <Stack
                            direction={matchUpMd ? "row" : "column"}
                            spacing={2}
                            mt="2.5rem"
                        >
                            <Chip
                                sx={{
                                    // width: "100%",
                                    borderRadius: "50px",
                                    background: "transparent",
                                    color: "white",
                                    cursor: "pointer",
                                    borderWidth: "1px",
                                    borderColor: "white",
                                    borderStyle: "solid",
                                    p: "5px",
                                }}
                                label={"View Shift"}
                                onClick={() => {
                                    // TODO this doesn't work right now
                                    resetEverything();
                                    dispatch({
                                        type: SET_VIEW_TIMESHEET_DRAWER,
                                        viewTimesheetShiftID: -1,
                                    });
                                    dispatch({
                                        type: SET_VIEW_EVENT_DRAWER,
                                        viewEventID: targetShift.id,
                                    });
                                }}
                            />

                            {outstandingOpeningsToApprove.length ? (
                                <Chip
                                    sx={{
                                        // width: "100%",
                                        borderRadius: "50px",
                                        background: theme.palette.primary.main,
                                        color: "white",
                                        cursor: "pointer",
                                        borderWidth: "1px",
                                        borderColor: theme.palette.primary.main,
                                        borderStyle: "solid",
                                        p: "5px",
                                    }}
                                    label={
                                        `Confirm All Reported Hours (${outstandingOpeningsToApprove.length})`
                                    }
                                    onClick={() =>
                                        setApproveOpenings(outstandingOpeningsToApprove)
                                    }
                                />
                            ) : null}
                        </Stack>
                    </Box>
                )}
                {!loading && (
                    <Box sx={{ backgroundColor: theme.palette.grey[100] }}>
                        <Grid
                            container
                            rowSpacing={2}
                            sx={{ padding: "5%", pb: "1%", justifyContent: "space-between" }}
                        >
                            <Grid item md={8} sx={{ alignSelf: "center", width: "100%" }}>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    justifyContent={matchUpMd ? "normal" : "space-between"}
                                >
                                    <Typography
                                        fontSize="1.4rem"
                                        variant="h2"
                                        fontWeight={600}
                                        color={theme.palette.secondary.dark}
                                        mr="10px"
                                    >
                                        Filled Openings
                                    </Typography>
                                    <Stack direction="row">
                                        {/* <EWCircularProgress
                      value={reviewedOpeningsPercent}
                      size={25}
                    /> */}
                                        <Typography
                                            variant="h3"
                                            fontWeight={100}
                                            color={theme.palette.grey[200]}
                                            ml="10px"
                                            mt="-5px"
                                            alignSelf="center"
                                        >
                                            {reviewedOpenings.length}/{numFilledOpenings} Reviewed
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Stack
                            justifyContent="center"
                            justifySelf="center"
                            alignSelf="center"
                            justifyItems="center"
                            alignItems="center"
                            mb="3rem"
                        >
                            {filledOpenings.map((opening, i) => (
                                <Grid
                                    container
                                    key={opening.id || i} // TODO this is hacky
                                    mt="20px"
                                    pt="2%"
                                    pl="5%"
                                    pr="5%"
                                    pb="5%"
                                    rowSpacing={2}
                                    sx={{
                                        background: "white",
                                        borderRadius: "15px",
                                        width: "90%",
                                    }}
                                >
                                    <Grid xs={12} item>
                                        <Stack
                                            direction="column"
                                            display="flex"
                                            spacing={
                                                getOpeningTimesheetStatus(opening) !==
                                                    "Awaiting Approval"
                                                    ? 2
                                                    : matchUpSm
                                                        ? 0
                                                        : 1.5
                                            }
                                        >
                                            <Stack
                                                direction="row"
                                                display="flex"
                                                justifyContent="space-between"
                                                spacing={opening.id ? 1.5 : 0}
                                                sx={{
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                <Typography
                                                    variant="h4"
                                                    fontWeight="100"
                                                    color="darkgray"
                                                >
                                                    {opening.id ? `ID#${opening.id}` : ""}
                                                </Typography>
                                                {getOpeningTimesheetStatus(opening) ===
                                                    "Awaiting Approval" ? (
                                                    <Stack direction="row" spacing={1}>
                                                        <Chip
                                                            sx={{
                                                                borderRadius: "50px",
                                                                background: "transparent",
                                                                color: theme.palette.error.main,
                                                                cursor: "pointer",
                                                                borderWidth: "1px",
                                                                borderColor: theme.palette.error.main,
                                                                borderStyle: "solid",
                                                                p: "5px",
                                                                "&:hover": {
                                                                    background: theme.palette.error.light,
                                                                    color: "white",
                                                                },
                                                            }}
                                                            label={"Modify"}
                                                            onClick={() => setDisputeOpening(opening)}
                                                        />
                                                        <Chip
                                                            sx={{
                                                                borderRadius: "50px",
                                                                background: theme.palette.primary.main,
                                                                color: "white",
                                                                cursor: "pointer",
                                                                borderWidth: "1px",
                                                                borderColor: theme.palette.primary.main,
                                                                borderStyle: "solid",
                                                                p: "5px",
                                                                "&:hover": {
                                                                    background: theme.palette.secondary.dark,
                                                                    color: "white",
                                                                },
                                                            }}
                                                            label={"Confirm"}
                                                            onClick={() => setApproveOpenings([opening])}
                                                        />
                                                    </Stack>
                                                ) : null}
                                            </Stack>
                                            <Typography
                                                variant="h3"
                                                // padding="5px"
                                                color={theme.palette.secondary.dark}
                                            >
                                                {workType}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Ambassador
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {opening.id > 0 &&
                                                opening.attributes.user &&
                                                opening.attributes.user.data ? (
                                                <Box
                                                    sx={{
                                                        // float: "left",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        // alignItems: "center",
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            width: "27px",
                                                            height: "27px",
                                                            fontSize: "1rem",
                                                            mt: "-5px",
                                                            mr: "5px",
                                                            // margin: "8px!important",
                                                            // ml: "0px !important",
                                                            cursor: "pointer",
                                                        }}
                                                        aria-controls="menu-list-grow"
                                                        aria-haspopup="true"
                                                        color="inherit"
                                                    >
                                                        <h5>
                                                            {opening.attributes.user.data.attributes
                                                                .firstName &&
                                                                opening.attributes.user.data.attributes.firstName
                                                                    .length > 0 &&
                                                                opening.attributes.user.data.attributes
                                                                    .lastName &&
                                                                opening.attributes.user.data.attributes.lastName
                                                                    .length > 0
                                                                ? `${opening.attributes.user.data.attributes.firstName
                                                                    .charAt(0)
                                                                    .toUpperCase()}${opening.attributes.user.data.attributes.lastName
                                                                        .charAt(0)
                                                                        .toUpperCase()}`
                                                                : "NI"}
                                                        </h5>
                                                    </Avatar>
                                                    <Typography
                                                        variant="h4"
                                                        color={theme.palette.primary.dark}
                                                    >
                                                        {opening.attributes.user.data.attributes.firstName}{" "}
                                                        {opening.attributes.user.data.attributes.lastName
                                                            ? `${opening.attributes.user.data.attributes.lastName
                                                                .charAt(0)
                                                                .toUpperCase()}.`
                                                            : ""}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                "--"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Shift Status
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                color:
                                                    shiftType === "Completed" &&
                                                        isOpeningComplete(opening)
                                                        ? theme.palette.primary.main
                                                        : theme.palette.grey[500],
                                            }}
                                        >
                                            {getOpeningStatus(opening, shiftType) === "Live"
                                                ? "Unreported"
                                                : getOpeningStatus(opening, shiftType)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Timesheet Status
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                color:
                                                    getOpeningTimesheetStatus(opening) === "Disputed"
                                                        ? getOpeningTimesheetStatus(opening) ===
                                                            "Awaiting Approval"
                                                            ? "black"
                                                            : theme.palette.error.main
                                                        : getOpeningTimesheetStatus(opening) === "Confirmed"
                                                            ? theme.palette.primary.main
                                                            : theme.palette.grey[500],
                                            }}
                                        >
                                            {getOpeningTimesheetStatus(opening)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            {getOpeningTimesheetStatus(opening) === "Confirmed" ? "Confirmed" : "Reported"} Start
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {getOpeningTimesheetStatus(opening) === "Confirmed" ? (
                                                opening.attributes.reportedAbsentByCompany ? ("--") : (
                                                    (opening.attributes.resolvedClockIn && new Date(Date.parse(opening.attributes.resolvedClockIn)).toLocaleTimeString("en", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })) || new Date(Date.parse(opening.attributes.approvedClockIn)).toLocaleTimeString("en", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }))
                                            ) : (opening.id > 0 &&
                                                opening.attributes &&
                                                opening.attributes.shiftClockIn
                                                ? new Date(
                                                    Date.parse(opening.attributes.shiftClockIn)
                                                ).toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })
                                                : opening.id > 0 && autoClocking ? startTime.toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                }) : "--")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            {getOpeningTimesheetStatus(opening) === "Confirmed" ? "Confirmed" : "Reported"} End
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {getOpeningTimesheetStatus(opening) === "Confirmed" ? (
                                                opening.attributes.reportedAbsentByCompany ? ("--") : (
                                                    (opening.attributes.resolvedClockOut && new Date(Date.parse(opening.attributes.resolvedClockOut)).toLocaleTimeString("en", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })) || new Date(Date.parse(opening.attributes.approvedClockOut)).toLocaleTimeString("en", {
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    }))
                                            ) : (opening.id > 0 &&
                                                opening.attributes &&
                                                opening.attributes.shiftClockIn
                                                ? new Date(
                                                    Date.parse(
                                                        opening.attributes.shiftClockOut || endTime
                                                    )
                                                ).toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })
                                                : opening.id > 0 ? endTime.toLocaleTimeString("en", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                }) : "--")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Hours
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            {opening.attributes.reportedAbsentByCompany ? "0.00" : (getShiftDurationHours(
                                                opening.attributes.resolvedClockIn ||
                                                opening.attributes.approvedClockIn ||
                                                (autoClocking ? startTime : opening.attributes.shiftClockIn),
                                                opening.attributes.resolvedClockOut ||
                                                opening.attributes.approvedClockOut ||
                                                opening.attributes.shiftClockOut ||
                                                endTime
                                            ).toFixed(2))}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="100"
                                            sx={{ color: theme.palette.grey[600], mb: "12px" }}
                                        >
                                            Budgeted
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            sx={{ color: theme.palette.secondary.dark }}
                                        >
                                            $
                                            {opening.attributes.reportedAbsentByCompany ? "0.00" : (
                                                getShiftDurationHours(
                                                    opening.attributes.resolvedClockIn ||
                                                    opening.attributes.approvedClockIn ||
                                                    (autoClocking ? startTime : opening.attributes.shiftClockIn),
                                                    opening.attributes.resolvedClockOut ||
                                                    opening.attributes.approvedClockOut ||
                                                    opening.attributes.shiftClockOut ||
                                                    endTime
                                                ) * ratePerHourCAD
                                            ).toFixed(2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                            <QuestionsBar />
                        </Stack>
                    </Box>
                )}
            </Drawer>
        </Box>
    );
};

ViewTimesheetDrawer.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerClose: PropTypes.func,
};

export default ViewTimesheetDrawer;
