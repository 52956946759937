function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export const NestedObjectAssign = (o1, o2) => {
    var output = o1;

    Object.keys(o2).forEach((key) => {
        if (isObject(o2[key])) {
            output[key] = Object.assign({}, o1[key], o2[key]);
        } else {
            output[key] = o2[key];
        }
    });
    return output;
};

function descendingComparator(a, b, sortBy) {
    // When compared to something undefined, always returns false.
    // This means that if a field does not exist from either element ('a' or 'b') the return will be 0.
    const aField = !!sortBy ? a[sortBy] : a;
    const bField = !!sortBy ? b[sortBy] : b;

    if (bField < aField) {
        return -1;
    }

    if (bField > aField) {
        return 1;
    }

    return 0;
}

function getComparator(sortDir, sortBy) {
    return (sortDir === 'desc'
        ? (a, b) => descendingComparator(a, b, sortBy)
        : (a, b) => -descendingComparator(a, b, sortBy));
}

export function applySort(documents, sortBy, sortDir) {
    const comparator = getComparator(sortDir, sortBy);
    const stabilizedThis = documents.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const newOrder = comparator(a[0], b[0]);

        if (newOrder !== 0) {
            return newOrder;
        }

        return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line consistent-return
export function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime());
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, index) => {
            arr[index] = deepCopy(item);
            return arr;
        }, []);
    }

    if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj, key) => {
            newObj[key] = deepCopy(obj[key]);
            return newObj;
        }, {});
    }
}

export const objFromArray = (arr, key = 'id') => arr.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
}, {});
