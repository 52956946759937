import PropTypes from "prop-types";

import { useState, useRef, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EVENTS_ADD_OR_UPDATE, SET_POST_EVENT_DRAWER, SET_VIEW_EVENT_DRAWER, fetchEvents } from "store/actions";

import * as Sentry from "@sentry/react";

import { useNavigate, usePrompt } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Box,
    Drawer,
    useMediaQuery,
    Chip,
    Stack,
    Typography,
    Stepper,
    Step,
    StepButton,
    LinearProgress,
    Grid,
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    TextField,
    Select,
    MenuItem,
    Autocomplete,
    List,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Switch,
    Card,
    ListItem,
    Button,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
} from "@mui/material";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/system";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { IconCircleX, IconCirclePlus, IconCircleMinus, IconAlertCircle, IconExternalLink } from "@tabler/icons-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

// third-party
import axios from "axios";
import { BrowserView, MobileView } from "react-device-detect";
import * as Yup from "yup";
import { Formik } from "formik";
import dayjs, { Dayjs } from "dayjs";
import minMax from 'dayjs/plugin/minMax';
import moment from "moment";
import { parseISO, isDate, set } from "date-fns";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

// project imports
import EventStatusBadge from "../../../ui-component/EventStatusBadge";
import { drawerWidth } from "store/constant";
import AgreementListItem from "../../../ui-component/lists/AgreementListItem";
import "../../../ui-component/lists/AgreementListItem/agreement-list.scss";
import FailSafeAvatar from "ui-component/avatars/FailSafeAvatar";
import { getChampionStatus } from "utils/shifts";
import { eventTimeRangeHumanReadable, getEarliestFutureEventInRecurringSeries, getEventType, getLatestFutureEventInRecurringSeries, getRecentTemplates } from "utils/events";
import EWCircularProgress from "ui-component/extended/EWCircularProgress";
import { useCallback } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// ==============================|| RIGHT SIDEBAR DRAWER ||============================== //

dayjs.extend(minMax);

const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];

const minimumWage = 15.6;
// How much to increment/decrement by when clicking plus/minus
const paidBreakInterval = 10;
const openingsInterval = 1;

// Disable tax for now
const hst = 0; // 0.13;

const customAddresses = [
    "Anywhere / Remote",
    "Athletics Centre",
    "Commons",
    "Admissions Office",
    "Welcome Centre",
];

const taskPlaceholders = [
    "Welcome visitors to the campus",
    "Give campus tours to prospective students",
    "Answer questions about campus facilities and programs",
    "Assist with registration and check-in for campus events",
    "Lead information sessions for prospective students and their families",
    "Participate in panel discussions or Q&A sessions with visitors",
    "Distribute informational brochures and materials",
    "Escort visitors to various campus locations",
    "Assist with setup and cleanup for events and presentations",
    "Conduct surveys or gather feedback from visitors",
    "Coordinate transportation for visiting groups",
    "Provide directions and guidance around campus",
    "Help organize and promote campus events",
    "Assist with social media promotion and engagement",
    "Serve as a point of contact for visitors with disabilities",
    "Conduct interviews or testimonials with current students",
    "Assist with multimedia presentations or demonstrations",
    "Translate for non-English speaking visitors",
    "Attend meetings or training sessions for student ambassadors",
    "Assist with administrative tasks in the admissions office"
];

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function parseCurrency(_, originalValue) {
    let formattedValue = originalValue;
    if (!originalValue) return "0.00";
    if (typeof originalValue === "string" || originalValue instanceof String) {
        formattedValue = originalValue.replace(/\.+$/, "");
        while (formattedValue.charAt(0) === "$") {
            formattedValue = formattedValue.substring(1);
        }
    }
    if (!isNaN(formattedValue)) {
        formattedValue = Number(formattedValue)
            .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            .replace(",", "");
    }

    return formattedValue;
}

function prettyDuration(hours) {
    let absoluteHours = Math.floor(hours);

    //Get remainder from hours and convert to minutes
    const minutes = (hours - absoluteHours) * 60;
    let absoluteMinutes = Math.floor(minutes);
    // If absolute minutes is one minute away from a factor of 5, round to it
    if (Math.abs(absoluteMinutes % 5) === 1) {
        absoluteMinutes = Math.round(absoluteMinutes / 5) * 5;
    }
    if (Math.abs(absoluteMinutes % 5) === 4) {
        absoluteMinutes = Math.round(absoluteMinutes / 5) * 5;
    }

    if (Math.ceil(minutes) === 60) {
        absoluteMinutes = 0;
        absoluteHours++;
    }

    return `${Number(absoluteHours) ? `${absoluteHours}hr ` : ""}${Number(absoluteMinutes) ? `${absoluteMinutes}min ` : ""
        }`;
}

const millisInDay = 1000 * 60 * 60 * 24;

function getEventDurationHours(startTime, endTime) {
    if (
        !startTime ||
        isNaN(Date.parse(startTime.$d)) ||
        !endTime ||
        isNaN(Date.parse(endTime.$d))
    ) {
        return 0;
    }
    // Correct for wrap-around
    const correctedEndTime =
        endTime.$d > startTime.$d ? endTime : endTime.add(24, "hour");

    const milliseconds = correctedEndTime.$d - startTime.$d;

    //Get hours from milliseconds
    return milliseconds > millisInDay ? ((milliseconds % millisInDay) / (1000 * 60 * 60)) : milliseconds / (1000 * 60 * 60);
}

const handleTimeChange = (event) => {
    console.log("CUSTOM ONCHANGE EVENT", event.target.value);
    let { value } = event.target;
    value = value.replace(/[^0-9ap:]/gi, ''); // allow colon in input

    if (value == "00:" || value == "0:" || value == "0A:" || value == "0P:" || value == "0a:" || value == "0p:" || value == "00A" || value == "00P" || value == "00a" || value == "00p" || value == "0A" || value == "0P" || value == "0a" || value == "0p") {
        // if user is trying to delete everything, allow it
        event.target.value = "";
        return;
    }

    if (value.length === 1 && /[ap]/i.test(value)) {
        // if 'a' or 'p' is entered first, move it to the end
        value = '0' + value;
    }

    if (value.length === 2 && value[1] === ':') {
        // if colon is entered second, add trailing zero to hour
        value = '0' + value[0] + ':';
    } else if (value.length === 2 && /[ap]/i.test(value.slice(-1))) {
        // if 'a' or 'p' is entered second, move it to the end
        value = '0' + value[0] + ':' + '00 ' + value[1].toUpperCase() + 'M';
    } else if (value.length === 2) {
        // add colon after two digits
        value += ':';
    } else if (value.length === 3 && value[2] !== ':') {
        // if third character is not a colon, move it to the end
        value = value.slice(0, 2) + ':' + value.slice(2);
    }

    if (value.length === 5) {
        // add space and 'M' after five characters
        value += ' M';
    }

    if (value.length === 6 && /[ap]/i.test(value.slice(-1))) {
        // if 'a' or 'p' is entered sixth, move it to the end
        value = value.slice(0, 5) + ' ' + value[5].toUpperCase() + 'M';
    } else if (value.length === 6) {
        // replace sixth character with 'M'
        value = value.slice(0, 5) + 'M';
    }

    if (value.length === 7 && /[ap]/i.test(value.slice(-2, -1))) {
        // if 'a' or 'p' is entered seventh, capitalize it
        value = value.slice(0, 5) + value.slice(5, 6).toUpperCase() + value.slice(6);
    }

    event.target.value = value;
};

const timeErrorMessage = "Must be a valid time (e.g. 05:30 PM)";

const PostEventDrawer = ({
    drawerOpen,
    onDrawerClose,
    eventID = -1,
    recurringSeries = false,
    eventTime = null,
    eventEnd = null,
    window,
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

    const updatingMode = Boolean(eventID > 0);
    // console.log("EDITING MODE FOR EVENT ", eventID);

    const { eventPostings: newEvents } = useSelector((state) => state.events);
    const { shiftPostings: allAmbassadors } = useSelector((state) => state.shifts);

    const eligibleAmbassadors = allAmbassadors.filter(
        (ambassador) => getChampionStatus(ambassador) === "Active"
    );

    const [usingTemplate, setUsingTemplate] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [availableAmbassadors, setAvailableAmbassadors] = useState([]);

    const targetEvent =
        newEvents.length > 0 && newEvents.some((event) => event.id === eventID)
            ? newEvents.find((event) => event.id === eventID)
            : {
                id: 0,
                attributes: {
                    workType: "",
                    summary: "",
                    workDuties: "",
                    breakDurationMins: 0,
                    shiftDurationMins: 0,
                    ratePerHourCAD: 0,
                    time: eventTime ?? dayjs(),
                    untilTime: dayjs(),
                    recurringID: 0,
                    repeatsSunday: false,
                    repeatsMonday: false,
                    repeatsTuesday: false,
                    repeatsWednesday: false,
                    repeatsThursday: false,
                    repeatsFriday: false,
                    repeatsSaturday: false,
                    ownedBy: 0,
                    totalOpenings: 1,
                    address: "",
                    lat: null,
                    lng: null,
                    status: "active",
                    shifts: { data: [] },
                    checklist: [],
                    notificationType: "both",
                },
            };

    // console.log("TARGET FOR UODATE", targetShift);

    const [notificationType, setNotificationType] = useState(targetEvent.attributes.notificationType ?? []);
    const [todoChecklist, setTodoChecklist] = useState(targetEvent.attributes.checklist ?? []);

    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [latLng, setLatLng] = useState({
        lat: targetEvent.attributes.lat,
        lng: targetEvent.attributes.lng,
    });
    const [errMsg, setErrMsg] = useState("");
    const [suggestedAddresses, setSuggestedAddresses] = useState([]);
    const [postedEventID, setPostedEventID] = useState(-1);
    const [customAddressesOnly, setCustomAddressesOnly] = useState(true);

    const [resetMode, setResetMode] = useState(false);

    const dispatch = useDispatch();

    const [userIdsToAssign, setUserIdsToAssign] = useState([]);
    const [originalAssignmentsWereNotEmpty, setOriginalAssignmentsWereNotEmpty] = useState(false);
    const [overWriteShifts, setOverWriteShifts] = useState(false);

    const account = useSelector((state) => state.account);
    const { companyID, accessToken, userID, sandboxUser } = account;
    //   const accessToken = "a4b8bd398044c14ed6e7520bd341b34bab26e47a0f8b57125b51a596edaf58b07b1b0b43aabb35749fdac08c9b84fdedf21d47bfbf05277322204d50cef2e6b7c1e831cc36bbfca9768f30245860881cdfc928884966c23c7b3390f1bdc289bec522b9d891872dc1454082de115b684d1a78bc4a29b79d1d4b415c2f77d05dba";
    //   const companyID = 1497;

    console.log(targetEvent);

    const isUserOwner = Number(targetEvent.attributes.ownedBy || userID) === Number(userID);
    const notAllowedToEdit = sandboxUser && !isUserOwner;

    const earliestEventInSeries = recurringSeries ? getEarliestFutureEventInRecurringSeries(newEvents, targetEvent.attributes.recurringID) : null;
    const latestEventInSeries = recurringSeries ? getLatestFutureEventInRecurringSeries(newEvents, targetEvent.attributes.recurringID) : null;
    const upcomingShiftsInSeries = recurringSeries ? newEvents.filter((event) => event.attributes.recurringID === targetEvent.attributes.recurringID && getEventType(event) === "Scheduled") : [];
    const recentTemplates = !recurringSeries && !updatingMode ? getRecentTemplates(newEvents) : [];

    const initialVals = {
        // TODO
        positionTitle: targetEvent.attributes.workType,
        address: targetEvent.attributes.address,
        date: (eventTime ? dayjs(eventTime) : null) ?? dayjs(earliestEventInSeries ? earliestEventInSeries.attributes.time : targetEvent.attributes.time),
        untilDate: dayjs(targetEvent.attributes.untilTime),
        startTime: (eventTime ? dayjs(eventTime) : null) ?? (targetEvent.id ? dayjs(targetEvent.attributes.time) : ""),
        endTime: (eventEnd ? dayjs(eventEnd) : null) ?? (targetEvent.id
            ? dayjs(targetEvent.attributes.time).add(targetEvent.attributes.shiftDurationMins, 'minute')
            : ""),
        isRecurring: !!targetEvent.attributes.recurringID,
        repeatsSunday: !!targetEvent.attributes.repeatsSunday,
        repeatsMonday: !!targetEvent.attributes.repeatsMonday,
        repeatsTuesday: !!targetEvent.attributes.repeatsTuesday,
        repeatsWednesday: !!targetEvent.attributes.repeatsWednesday,
        repeatsThursday: !!targetEvent.attributes.repeatsThursday,
        repeatsFriday: !!targetEvent.attributes.repeatsFriday,
        repeatsSaturday: !!targetEvent.attributes.repeatsSaturday,
        hourlyRate: targetEvent.id || !!selectedTemplate
            ? `$${parseCurrency(null, targetEvent.attributes.ratePerHourCAD)}`
            : `$${minimumWage.toFixed(2)}`,
        paidBreak: targetEvent.attributes.breakDurationMins,
        openings: targetEvent.attributes.totalOpenings,
        summary: targetEvent.attributes.summary,
        workDuties: targetEvent.attributes.workDuties,
        checkListItem: "",
        notificationType: targetEvent.attributes.notificationType ?? "both",
    };

    let steps = ["Basic Details", "Work Details", "Ambassador Preferences", "Completed"];

    if (updatingMode && recurringSeries) {
        steps = ["Upcoming Series Shifts", "Basic Details", "Work Details", "Completed"];
    } else if (newEvents.length > 0 && !updatingMode && usingTemplate) {
        steps = ["Choose Recent Template", "Basic Details", "Work Details", "Ambassador Preferences", "Completed"];
    }

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        options: {
            types: ["address"],
            componentRestrictions: {
                country: ["ca"],
            },
        },
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    });

    const formRef = useRef();

    const container =
        window !== undefined ? () => window.document.body : undefined;

    const MediaDatePicker = DatePicker;

    const saveAddressLatLng = (address) => {
        if (customAddresses.includes(address) || !address) {
            setLatLng({ lat: null, lng: null });
            return;
        }

        if (latLng.lat && latLng.lng) return;

        setLoading(true);

        const targetPlace = suggestedAddresses.find(
            (suggestedAddress) => address === suggestedAddress.description
        );
        if (!targetPlace || !targetPlace.placeId) {
            setErrMsg("Unable to locate the provided job site address.");
            setLoading(false);
            return;
        }

        if (!placesService) {
            // API key not valid
            setErrMsg("Unable to process request.");
            setLoading(false);
            return;
        }

        placesService.getDetails(
            {
                placeId: targetPlace.placeId,
            },
            (placeDetails) => {
                if (
                    !placeDetails ||
                    !placeDetails.geometry ||
                    !placeDetails.geometry.location
                ) {
                    setErrMsg("Unable to process the provided job site address.");
                    setLoading(false);
                    return;
                }
                setLatLng({
                    lat: placeDetails.geometry.location.lat(),
                    lng: placeDetails.geometry.location.lng(),
                });
                setLoading(false);
            }
        );
    };

    const postEvent = async (values) => {
        if (notAllowedToEdit) {
            setErrMsg("You can't edit existing shifts in sandbox mode. Please create your own shift instead.");
            return;
        }

        setLoading(true);

        if (!values.startTime) {
            setErrMsg("Unable to process the shift details.");
            console.log(`Start time (${values.startTime}) invalid.`);
            return;
        }
        if (!values.date) {
            setErrMsg("Unable to process the shift.");
            console.log(`Date (${values.date}) invalid.`);
            return;
        }
        const startDateAndTime = values.startTime
            .set("date", values.date.$D)
            .set("month", values.date.$M)
            .set("year", values.date.$y)
            .toISOString();

        const shiftDurationMins = Math.round(
            getEventDurationHours(values.startTime, values.endTime) * 60
        );

        // Get end date and time: account for wrap-around to next day
        // use shiftDurationMins and startDateAndTime to calculate
        const endDateAndTime = values.startTime
            .set("date", values.date.$D)
            .set("month", values.date.$M)
            .set("year", values.date.$y)
            .add(shiftDurationMins, 'minute')
            .toISOString();

        const untilDateAndTime = values.untilDate && (values.isRecurring || !!recurringSeries) ?
            values.untilDate
                .set("date", values.untilDate.$D)
                .set("month", values.untilDate.$M)
                .set("year", values.untilDate.$y).toISOString() :
            null;

        const postData = {
            company: companyID,
            workType: values.positionTitle.trim(),
            summary: values.summary,
            workDuties: values.workDuties,
            breakDurationMins: values.paidBreak,
            shiftDurationMins,
            // TODO this is sloppy
            ratePerHourCAD: Number(
                values.hourlyRate.replace(",", "").replace("$", "")
            ),
            time: startDateAndTime,
            endTime: endDateAndTime,
            untilTime: untilDateAndTime,
            repeatsSunday: values.repeatsSunday,
            repeatsMonday: values.repeatsMonday,
            repeatsTuesday: values.repeatsTuesday,
            repeatsWednesday: values.repeatsWednesday,
            repeatsThursday: values.repeatsThursday,
            repeatsFriday: values.repeatsFriday,
            repeatsSaturday: values.repeatsSaturday,
            totalOpenings: values.openings,
            address: values.address,
            lat: latLng ? latLng.lat : null,
            lng: latLng ? latLng.lng : null,
            checklist: todoChecklist ?? [],
            notificationType: notificationType,
            status: "active",
            originalAssignmentsWereNotEmpty,
            userIdsToAssign: userIdsToAssign.filter((id) => !!id),
            overWriteShifts,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ownedBy: userID,
            ...(latestEventInSeries ? { latestRecurringTime: latestEventInSeries.attributes.time } : {}),
            ...(earliestEventInSeries ? { earliestRecurringTime: earliestEventInSeries.attributes.time } : {}),
            ...(recurringSeries ? { updatingSeries: targetEvent.attributes.recurringID } : {}),
        };

        // const postData = {
        //   company: 1497,
        //   workType: "Warehouse Worker 14",
        //   summary: "Help with school's inventory",
        //   workDuties: "Data input",
        //   breakDurationMins: 30,
        //   shiftDurationMins: 390,
        //   ratePerHourCAD: 19.5,
        //   time: dayjs().set("month", 3).toISOString(),
        //   totalOpenings: 2,
        //   address: "200 University Avenue West, Waterloo, ON, Canada",
        //   lat: 43.4731446,
        //   lng: -80.5443909,
        //   status: "active",
        // };

        const apiURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/postings/${updatingMode ? targetEvent.id : ""
            }?populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups`;

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const apiCb = (response) => {
            console.log(response);
            const { data } = response;
            if (!data || !data.data) {
                console.log("Unable to parse response from server");
                return;
            }
            // Add event to our redux store
            if ((values.isRecurring || !!recurringSeries) && data.data.attributes.recurringID) {
                const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
                const yesterdatTimeUTCString = yesterday.toISOString().slice(0, 19);
                dispatch(
                    fetchEvents(
                        `filters[company]=${companyID}&filters[status][$ne]=draft&filters[status][$ne]=cancelled&filters[publishedAt][$null]=false&filters[recurringID]=${data.data.attributes.recurringID}&populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups&sort[0]=time%3Aasc&pagination[limit]=-1`,
                        accessToken
                    )
                );
            } else {
                dispatch({
                    type: EVENTS_ADD_OR_UPDATE,
                    updatedEvents: [{
                        id: data.data.id,
                        attributes: {
                            ...data.data.attributes,
                        },
                    }],
                });
            }
            setPostedEventID(data.data.id);
            setActiveStep(activeStep + 1);
        };

        const apiErr = (e) => {
            Sentry.captureException(e);
            setErrMsg("Please check your connection and try again.");
            console.log(e);
        };

        console.log(postData);

        if (updatingMode) {
            try {
                const respData = await axios.put(
                    apiURL,
                    {
                        data: postData,
                    },
                    apiHeaders
                );
                apiCb(respData);
            } catch (e) {
                console.error(e);
                apiErr(e);
            }
        } else {
            try {
                const respData = await axios.post(
                    apiURL,
                    {
                        data: postData,
                    },
                    apiHeaders
                );
                apiCb(respData);
            } catch (e) {
                console.error(e);
                apiErr(e);
            }
        }
        setLoading(false);
    };

    const cancelAllShiftsInSeries = async () => {
        if (notAllowedToEdit) {
            setErrMsg("You can't edit existing shifts in sandbox mode. Please create your own shift instead.");
            return;
        }

        const apiURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/postings/${targetEvent.id}`;

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const apiCb = (response) => {
            console.log(response);
            const { data } = response;
            if (!data || !data.data) {
                console.log("Unable to parse response from server");
                return;
            }

            dispatch({
                type: EVENTS_ADD_OR_UPDATE,
                updatedEvents: upcomingShiftsInSeries.map((event) => ({
                    id: event.id,
                    attributes: {
                        ...event.attributes,
                        status: "cancelled",
                    },
                })),
            });
        };

        const apiErr = (e) => {
            Sentry.captureException(e);
            setErrMsg("Please check your connection and try again.");
            console.log(e);
        };

        try {
            const response = await axios.put(apiURL,
                {
                    data: {
                        cancelSeries: true,
                        recurringID: targetEvent.attributes.recurringID,
                    },
                },
                apiHeaders
            );

            apiCb(response);
        } catch (e) {
            console.error(e);
            apiErr(e);
        }
    };

    const cancelEvent = async (eventToCancel) => {
        if (notAllowedToEdit) {
            setErrMsg("You can't edit existing shifts in sandbox mode. Please create your own shift instead.");
            return;
        }

        const apiURL = `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/postings/${eventToCancel.id}?populate[shifts][populate][0]=user&populate[shifts][populate][user][populate][0]=avatar&populate[shifts][populate][user][populate][1]=groups`;

        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const apiCb = (response) => {
            console.log(response);
            const { data } = response;
            if (!data || !data.data) {
                console.log("Unable to parse response from server");
                return;
            }
            // Add updated event to our redux store
            dispatch({
                type: EVENTS_ADD_OR_UPDATE,
                updatedEvents: [{
                    id: data.data.id,
                    attributes: {
                        ...data.data.attributes,
                    },
                }],
            });
        };

        const apiErr = (e) => {
            setErrMsg("Please check your connection and try again.");
            console.log(e);
        };

        try {
            const response = await axios.put(apiURL,
                {
                    data: {
                        status: "cancelled",
                    },
                },
                apiHeaders
            );

            apiCb(response);
        } catch (e) {
            console.error(e);
            apiErr(e);
        }
    };

    const fetchAvailableAmbassadors = async (values) => {
        if (!values.startTime) {
            setErrMsg("Unable to process the shift details.");
            console.log(`Start time (${values.startTime}) invalid.`);
            return;
        }
        if (!values.date) {
            setErrMsg("Unable to process the shift.");
            console.log(`Date (${values.date}) invalid.`);
            return;
        }
        const startDateAndTime = values.startTime
            .set("date", values.date.$D)
            .set("month", values.date.$M)
            .set("year", values.date.$y)
            .toISOString();

        const shiftDurationMins = Math.round(
            getEventDurationHours(values.startTime, values.endTime) * 60
        );

        // Get end date and time: account for wrap-around to next day
        // use shiftDurationMins and startDateAndTime to calculate
        const endDateAndTime = values.startTime
            .set("date", values.date.$D)
            .set("month", values.date.$M)
            .set("year", values.date.$y)
            .add(shiftDurationMins, 'minute')
            .toISOString();

        try {
            const response = await axios
                .post(
                    `${process.env.REACT_APP_SERVER_PROTOCOL}://${process.env.REACT_APP_SERVER_ADDRESS}/api/users/available`,
                    {
                        isRecurring: false,
                        weekdays: [],
                        startTime: startDateAndTime,
                        endTime: endDateAndTime,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone ?? "America/Toronto",
                        companyID,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

            const { data } = response;
            if (!data) {
                console.log("Unable to parse available ambassadors response from server");
                return;
            }

            setAvailableAmbassadors(data);
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }
    };

    const subtotal = (values, errors, status) => {
        if (
            status.startTimeError ||
            status.endTimeError ||
            errors.hourlyRate ||
            errors.paidBreak ||
            errors.openings
        )
            return "0.00";

        const { startTime, endTime, hourlyRate, paidBreak, openings } = values;

        const durationHours = getEventDurationHours(startTime, endTime);
        const rate = parseCurrency(null, hourlyRate);
        const breakHoursDeducted = paidBreak / 60;

        if (breakHoursDeducted > durationHours) return "0.00";

        return Number(rate * (durationHours - breakHoursDeducted) * openings);
    };

    const stepValuesValid = (values, errors, status) => {
        if (steps[activeStep] === "Upcoming Series Shifts") {
            return upcomingShiftsInSeries.length > 0;
        }
        if (steps[activeStep] === "Choose Recent Template") {
            return true;
        }
        if (steps[activeStep] === "Basic Details") {
            return (
                // TODO needs cleaning up
                // We should be splitting up the steps into their own forms

                // All completed
                values.positionTitle &&
                values.address &&
                values.date &&
                values.startTime &&
                values.endTime &&
                // If it's recurring, we need an until date and at least one day of the week selected
                (!(values.isRecurring || !!recurringSeries) || (values.untilDate && !errors.untilDate && (values.repeatsSunday ||
                    values.repeatsMonday || values.repeatsTuesday || values.repeatsWednesday ||
                    values.repeatsThursday || values.repeatsFriday || values.repeatsSaturday))) &&
                values.hourlyRate &&
                // values.paidBreak &&
                values.openings &&
                // With no errors
                !errors.date &&
                !errors.positionTitle &&
                !errors.address &&
                !errors.hourlyRate &&
                // !errors.paidBreak &&
                !errors.openings &&
                !status.startTimeError &&
                !status.endTimeError
            );
        }
        if (steps[activeStep] === "Work Details") {
            return (
                values.summary &&
                // values.workDuties &&
                !errors.summary &&
                !errors.workDuties
            );
        }
        if (steps[activeStep] === "Ambassador Preferences") {
            return true;
        }
    };

    const resetEverything = (e) => {
        if (formRef.current) {
            console.log("RESETTING EVERYTHING");
            // Reset all state
            formRef.current.resetForm();
        } else {
            console.log("FAILED RESETTING EVERYTHING");
        }

        setLatLng({ lat: null, lng: null });
        setErrMsg("");
        setActiveStep(0);
        setSuggestedAddresses([]);
        setCustomAddressesOnly(true);
        setPostedEventID(-1);
        setUserIdsToAssign([]);
        setUsingTemplate(false);
        setSelectedTemplate(null);
        setOverWriteShifts(false);
        setAvailableAmbassadors([]);
        setOriginalAssignmentsWereNotEmpty(false);
        setTodoChecklist([]);
        setNotificationType("both");
    };

    useEffect(() => {
        console.log(placePredictions);
        if (placePredictions.length) {
            setSuggestedAddresses(
                placePredictions.map((suggestion) => ({
                    description: suggestion.description,
                    placeId: suggestion.place_id,
                }))
            );
        }
    }, [placePredictions]);

    useEffect(() => {
        setLatLng({
            lat: targetEvent.attributes.lat,
            lng: targetEvent.attributes.lng,
        });
        setTodoChecklist(targetEvent.attributes.checklist ?? []);
        setNotificationType(targetEvent.attributes.notificationType ?? "both");
        console.log("Updated LATLNG: ", latLng);
    }, [targetEvent.id]);

    useEffect(() => {
        if (drawerOpen) {
            setResetMode(false);
        } else {
            setResetMode(true);
        }
        if (formRef.current) {
            // Ensure the form is initialized with the correct information
            formRef.current.resetForm({
                values: initialVals,
            });
        }
    }, [drawerOpen]);

    useEffect(() => {
        if (steps[activeStep] === "Basic Details" && resetMode) {
            setResetMode(false);
        }
    }, [activeStep]);

    useEffect(() => {
        const close = (e) => {
            // If they press escape, tab, or enter, close the time picker
            if ((e.key === "Escape" || e.key === "Enter") && formRef.current && formRef.current.status) {
                if (formRef.current.status.startTimeOpen) {
                    formRef.current.setStatus({ ...formRef.current.status, startTimeOpen: false });
                }
            }
        }
        document.addEventListener('keydown', close, true)
        return () => document.removeEventListener('keydown', close, true)
    }, [])

    return (
        <Box
            component="nav"
            sx={{ flexShrink: { md: 0 }, width: "auto" }}
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant="temporary"
                anchor="right"
                open={drawerOpen}
                onClose={(e) => {
                    onDrawerClose();
                    resetEverything(e);
                }}
                sx={{
                    "& .MuiDrawer-paper": {
                        width: matchUpMd ? "612px" : "100vw",
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: "none",
                        // [theme.breakpoints.up('md')]: {
                        //     top: '88px'
                        // }
                    },
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(errMsg)}
                    autoHideDuration={6000}
                    onClose={() => setErrMsg("")}
                >
                    <Alert
                        onClose={() => setErrMsg("")}
                        severity="error"
                        sx={{ width: "100%", whiteSpace: "pre-line" }}
                    >
                        Something went wrong.{`\n`}
                        {errMsg}
                    </Alert>
                </Snackbar>
                <Box
                    sx={{
                        backgroundColor: theme.palette.secondary.dark,
                        padding: "5%",
                    }}
                >
                    <Stack>
                        <Stack
                            direction="row"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <Stack direction="row">
                                {
                                    !!recurringSeries ? (
                                        <>
                                            <Typography
                                                variant="h2"
                                                color="white"
                                                fontWeight="100"
                                                ml="10px"
                                                mt="5px"
                                            >
                                                {upcomingShiftsInSeries.length} shifts {steps[activeStep] === "Upcoming Series Shifts" ? "upcoming" : "being updated"}
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <EventStatusBadge
                                                eventType={updatingMode ? getEventType(targetEvent) : "Draft"}
                                            />
                                            {updatingMode && (
                                                <Typography
                                                    variant="h3"
                                                    color="white"
                                                    fontWeight="100"
                                                    ml="10px"
                                                    mt="5px"
                                                >{`ID: ${targetEvent.id}`}</Typography>
                                            )}
                                        </>
                                    )
                                }

                            </Stack>
                            <IconCircleX
                                cursor="pointer"
                                color="white"
                                stroke={0.3}
                                size="2.5rem"
                                onClick={(e) => {
                                    resetEverything(e);
                                    onDrawerClose();
                                }}
                            />
                        </Stack>
                        <Typography
                            component="span"
                            variant="h1"
                            mt="30px"
                            sx={{
                                color: "white",
                                fontSize: "50px",
                            }}
                        >
                            {!!recurringSeries ? (steps[activeStep] === "Upcoming Series Shifts" ? "Recurring Series" : "Update Recurring Series") : updatingMode ? "Update a shift" : "Post a new shift"}
                        </Typography>
                    </Stack>
                </Box>
                <Box sx={{ height: "100%" }}>
                    <Stack
                        direction="column"
                        display="flex"
                        justifyContent="space-between"
                        height="100%"
                    >
                        <Box padding="5%" pb="0">
                            {
                                notAllowedToEdit && (
                                    <Grid item xs={4} sm={8} md={12} sx={{ mb: 3, mt: -2 }} >
                                        <Typography
                                            sx={{
                                                marginBottom: "10px",
                                                padding: "20px",
                                                background: theme.palette.secondary.light,
                                                borderRadius: "15px",
                                                fontWeight: "500",
                                                color: theme.palette.grey[600],
                                            }}
                                        >
                                            NOTE: You are currently on an <strong>interactive demo account</strong>. You can view and interact with existing shifts, but you cannot edit or delete them. Try creating your own shifts!
                                        </Typography>
                                    </Grid>
                                )
                            }
                            <LinearProgress
                                variant="determinate"
                                value={((1 + activeStep) / steps.length) * 100}
                            />
                            {steps[activeStep] === "Upcoming Series Shifts" && (
                                <Button
                                    onClick={async () => {
                                        localStorage.setItem("taskBoard", JSON.stringify({
                                            recurringID: targetEvent.attributes.recurringID,
                                            value: targetEvent.attributes.workType,
                                            type: "Shift Series",
                                            id: targetEvent.attributes.recurringID,
                                        }));
                                        onDrawerClose();
                                        navigate("/dashboard/tasks");
                                    }}
                                    variant="text"
                                    color="primary"
                                    sx={{
                                        fontWeight: 600,
                                        fontSize: "1.2rem",
                                        mt: "20px",
                                        mb: "-20px",
                                        ml: "10px",
                                        textAlign: "right",
                                        justifyContent: "flex-end",
                                        float: "right",
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        color="primary"
                                    >
                                        View Series Task Board{" "}
                                        <IconExternalLink
                                            height="1.1rem"
                                            width="1.2rem"
                                            cursor="pointer"
                                        />
                                    </Typography>
                                </Button>

                            )}
                            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", alignSelf: "center" }}>
                                <Grid sx={{
                                    pt: steps[activeStep] === "Basic Details" && !recurringSeries && !updatingMode ? "20px" : "0",
                                    pb: steps[activeStep] === "Basic Details" && !recurringSeries && !updatingMode ? "10px" : "0",
                                }} item xs={12} md={steps[activeStep] === "Basic Details" && !recurringSeries && !updatingMode ? 7 : 12}>
                                    <Typography
                                        variant="h1"
                                        sx={{
                                            color: theme.palette.primary.dark,
                                        }}
                                        pt="7%"
                                        pb="2%"
                                    >
                                        {steps[activeStep]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
                                    <Chip
                                        sx={{
                                            mb: "-20px",
                                            fontSize: "0.7rem",
                                            borderRadius: "50px",
                                            background: "transparent",
                                            color: theme.palette.primary.main,
                                            cursor: "pointer",
                                            borderWidth: "1px",
                                            borderColor: theme.palette.primary.main,
                                            borderStyle: "solid",
                                            alignSelf: "center",

                                            display: steps[activeStep] === "Basic Details" && !recurringSeries && !updatingMode ? "inline-flex" : "none",
                                        }}
                                        label={<h4 style={{ fontWeight: 500 }}>Use Another Shift as Template</h4>}
                                        onClick={(e) => {
                                            setUsingTemplate(true);
                                            setActiveStep(0);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Stack
                                // padding="5%"
                                pt="0"
                                sx={{
                                    ...theme.cards.agreementListItem,
                                    textAlign: "center",
                                    alignItems: "center",
                                    p: matchUpMd && !updatingMode ? "2rem 7rem" : "2rem 2rem",
                                }}
                                className={
                                    steps[activeStep] === "Completed" &&
                                        postedEventID > 0 &&
                                        !loading
                                        ? "fadedInView"
                                        : "fadedOutView"
                                }
                                spacing={3}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        color: theme.palette.secondary.dark,
                                        fontSize: "1.9rem",
                                    }}
                                >
                                    🥳 &nbsp;Your shift is {updatingMode ? "updated" : "live"}.
                                </Typography>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: "darkgrey",
                                        fontSize: "1.3rem",
                                        pr: updatingMode ? "5rem" : 0,
                                        pl: updatingMode ? "5rem" : 0,
                                    }}
                                >
                                    Next, track or edit your shift and monitor your fill rates.
                                </Typography>
                                <Chip
                                    sx={{
                                        fontSize: "1.2rem",
                                        fontWeight: "600",
                                        marginRight: "10px",
                                        height: "55px",
                                        pl: "20px",
                                        pr: "20px",
                                        alignItems: "center",
                                        borderRadius: "27px",
                                        transition: "all .2s ease-in-out",
                                        borderColor: theme.palette.primary.main,
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white",
                                        '&[aria-controls="menu-list-grow"], &:hover': {
                                            borderColor: theme.palette.primary.main,
                                            background: `${theme.palette.primary.dark}!important`,
                                            color: theme.palette.success.light,
                                            "& svg": {
                                                stroke: theme.palette.primary.light,
                                            },
                                        },
                                    }}
                                    label={
                                        updatingMode ? "View Updated Shift" : "View Live Shift"
                                    }
                                    variant="outlined"
                                    aria-haspopup="true"
                                    onClick={(e) => {
                                        resetEverything(e);
                                        onDrawerClose(e);

                                        dispatch({
                                            type: SET_VIEW_EVENT_DRAWER,
                                            viewEventID: postedEventID,
                                        });
                                    }}
                                />
                                {/* {!updatingMode && (
                  <Chip
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      marginRight: "10px",
                      height: "45px",
                      pl: "10px",
                      pr: "10px",
                      alignItems: "center",
                      borderRadius: "27px",
                      transition: "all .2s ease-in-out",
                      borderWidth: "0",
                      // borderColor: theme.palette.primary.main,
                      backgroundColor: "#C7E1F2",
                      color: theme.palette.primary.dark,
                      '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.dark}!important`,
                        color: theme.palette.success.light,
                        "& svg": {
                          stroke: theme.palette.primary.light,
                        },
                      },
                    }}
                    label="Post Another Shift"
                    variant="outlined"
                    // ref={anchorRef}
                    // aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={resetEverything}
                  />
                )} */}
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: "darkgrey",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={resetEverything}
                                >
                                    Post Another Shift
                                </Typography>
                            </Stack>
                        </Box>
                        {loading && (
                            <Box sx={{ alignSelf: "center" }}>
                                <CircularProgress size="4rem" color="primary" />
                            </Box>
                        )}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Formik
                                // enableReinitialize
                                initialValues={initialVals}
                                initialStatus={{
                                    // HACK had to use status for errors since the validation schema
                                    // used by errors was not behaving the way I expected (should revisit later)
                                    startTimeError: "",
                                    endTimeError: "",
                                    dateOpen: false,
                                    untilDateOpen: false,
                                    startTimeOpen: false,
                                    endTimeOpen: false,
                                }}
                                validationSchema={Yup.object().shape({
                                    // TODO
                                    positionTitle: Yup.string().required(
                                        "Position title is required"
                                    ),
                                    address: Yup.string().required("Address is required"),
                                    date: Yup.date()
                                        .typeError("Must be a valid date")
                                        // .transform(parseDateString)
                                        // .min(new Date(), "Must be a future date")
                                        .required("Shift date is required"),
                                    untilDate: Yup.date().typeError("Must be a valid date").min(Yup.ref('date'), 'Must be after start date'),
                                    hourlyRate: Yup.string()
                                        .transform(parseCurrency)
                                        .matches(
                                            /\$?\d+(?:\.(\d+)?)?$/,
                                            "Must be a valid dollar amount"
                                        )
                                        .transform((value, _) => {
                                            let noCurrencyFormatters = value
                                                .replace(",", "")
                                                .replace("$", "");
                                            let numberCurrency = Number(noCurrencyFormatters);
                                            if (isNaN(numberCurrency)) {
                                                console.log("Failed to parse dollar amount");
                                                Sentry.captureException(new Error("Failed to parse dollar amount"));
                                                return "$0.00";
                                            }

                                            const passThroughVal =
                                                numberCurrency < minimumWage ? "$0.00" : value;
                                            return passThroughVal;
                                        })
                                        // TODO fix this hack because Yup disappointed me here with their lack of
                                        // support in transforming primitives in the validation process
                                        .notOneOf(
                                            ["$0.00"],
                                            "Must be greater than the minimum wage in the locality selected"
                                        )
                                        .required("Hourly rate is required"),
                                    paidBreak: Yup.number()
                                        .min(0, "Must be a valid duration")
                                        .typeError("Must be a valid number"),
                                    openings: Yup.number()
                                        .min(1, "Must be at least one opening")
                                        .typeError("Must be a valid number")
                                        .required("Required field"),
                                    summary: Yup.string().max(255),
                                    checkListItem: Yup.string(),
                                    workDuties: Yup.string(),
                                })}
                                innerRef={formRef}
                                onSubmit={async (
                                    values,
                                    { setErrors, setStatus, setSubmitting }
                                ) => { }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    setFieldValue,
                                    setErrors,
                                    setStatus,
                                    status,
                                    isValid,
                                    isValidating,
                                }) => (
                                    <form
                                        noValidate
                                        onSubmit={handleSubmit}
                                        style={{ display: "contents" }}
                                    >
                                        <Grid
                                            container
                                            rowSpacing={0.1}
                                            spacing={{ xs: 2, md: 3 }}
                                            columns={{ xs: 4, sm: 8, md: 12 }}
                                            padding="5%"
                                            pt="0"
                                            className={
                                                steps[activeStep] === "Basic Details" && !loading
                                                    ? "fadedInView"
                                                    : "fadedOutView"
                                            }
                                        // onTransitionEnd={() =>
                                        //   !Boolean(activeStep === 0) &&
                                        // }
                                        >
                                            <Grid item xs={4} sm={4} md={6}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.positionTitle && errors.positionTitle
                                                    )}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    {/* <InputLabel htmlFor="outlined-adornment-positionTitle-register">
                            Position Title
                          </InputLabel> */}
                                                    <Typography
                                                        variant="h3"
                                                        fontWeight="300"
                                                        sx={{ p: 1.3 }}
                                                    >
                                                        Position Title
                                                    </Typography>
                                                    <OutlinedInput
                                                        id="outlined-adornment-positionTitle-register"
                                                        // label="Position Title"
                                                        name="positionTitle"
                                                        type="text"
                                                        placeholder="Ex. Campus Tour Guide"
                                                        value={values.positionTitle}
                                                        sx={{
                                                            "& .MuiInputBase-input": {
                                                                paddingTop: "20.5px !important",
                                                                paddingBottom: "20.5px !important",
                                                            },
                                                        }}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.positionTitle && errors.positionTitle && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-positionTitle-register"
                                                        >
                                                            {errors.positionTitle}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={6}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(touched.address && errors.address)}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    {/* <InputLabel htmlFor="outlined-adornment-address-register">
                            Job Site Address
                          </InputLabel> */}
                                                    <Typography
                                                        variant="h3"
                                                        fontWeight="300"
                                                        sx={{ p: 1.3 }}
                                                    >
                                                        Shift Site Address
                                                    </Typography>
                                                    <Autocomplete
                                                        id="outlined-adornment-address-register"
                                                        disablePortal
                                                        freeSolo
                                                        includeInputInList
                                                        value={values.address}
                                                        name="address"
                                                        type="text"
                                                        onChange={(e) => {
                                                            console.log(errors);
                                                            // setLatLng({ lat: null, lng: null });
                                                            setFieldValue(
                                                                "address",
                                                                e.target.innerText,
                                                                true
                                                            );
                                                        }}
                                                        onBlur={(e) => {
                                                            console.log("BLUR ADDRESS", e);
                                                            if (e.target.value !== values.address) {
                                                                const targetPlace = suggestedAddresses.find(
                                                                    (suggestedAddress) => values.address === suggestedAddress.description
                                                                );

                                                                if (!customAddresses.includes(e.target.value) && (!targetPlace || !targetPlace.placeId)) {
                                                                    console.log("SETTING ADDRESS FIELD VALUE to empty");
                                                                    setErrors({
                                                                        ...errors,
                                                                        address: "Must be a valid address",
                                                                    });
                                                                } else {
                                                                    console.log("SETTING ADDRESS FIELD VALUE to", e.target.value);
                                                                    setFieldValue("address", e.target.value, true);
                                                                    setErrors({
                                                                        ...errors,
                                                                        address: "",
                                                                    });
                                                                }
                                                            }
                                                            // handleBlur(e);
                                                        }}
                                                        clearOnBlur={false}
                                                        options={[
                                                            ...customAddresses,
                                                            ...(!customAddressesOnly ? suggestedAddresses.map((suggestedAddress) => suggestedAddress.description) : []),
                                                        ]}
                                                        isOptionEqualToValue={(option, value) => {
                                                            if (option && value) {
                                                                return option.value == value.value;
                                                            }
                                                            if (option || value) {
                                                                return false;
                                                            }
                                                            return true;
                                                        }}
                                                        noOptionsText={
                                                            isPlacePredictionsLoading
                                                                ? "Loading..."
                                                                : "No location found"
                                                        }
                                                        // onHighlightChange={(event, option, reason) => {
                                                        //     console.log("HIGHLIGHT CHANGE AUTO", event);
                                                        //     if (option && reason === "keyboard") {
                                                        //         setFieldValue(
                                                        //             "address",
                                                        //             option,
                                                        //             true
                                                        //         );
                                                        //     }
                                                        // }}
                                                        renderInput={({
                                                            InputProps,
                                                            InputLabelProps,
                                                            ...otherParams
                                                        }) => (
                                                            <OutlinedInput
                                                                {...otherParams}
                                                                {...InputProps}
                                                                {...InputLabelProps}
                                                                value={values.address}
                                                                name="address"
                                                                type="text"
                                                                placeholder="Ex. 8109 Airport Road, Mississauga, ON"
                                                                onChange={(e) => {
                                                                    setLatLng({ lat: null, lng: null });
                                                                    getPlacePredictions({
                                                                        input: e.target.value,
                                                                    });

                                                                    setCustomAddressesOnly(!e.target.value);
                                                                }}
                                                                sx={{
                                                                    ...theme.typography.customInput,
                                                                    padding: "15.5px 60px 10.5px 8px !important",
                                                                    mt: "0px",
                                                                }}
                                                                error={Boolean(errors.address)}
                                                            />
                                                        )}
                                                    />
                                                    {errors.address && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-address-register"
                                                        >
                                                            {errors.address}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(touched.date && errors.date)}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        fontWeight="300"
                                                        sx={{ p: 1.3 }}
                                                    >
                                                        Shift Date
                                                    </Typography>
                                                    <MediaDatePicker
                                                        id="outlined-adornment-date-register"
                                                        // label="Shift Date"
                                                        inputFormat="DD/MM/YYYY"
                                                        format="DD/MM/YYYY"
                                                        name="date"
                                                        value={values.date}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.date && errors.date)}
                                                        onChange={(value) =>
                                                            setFieldValue("date", value, true)
                                                        }
                                                        // {...(matchUpMd ? { open: status.dateOpen } : {})}
                                                        onClose={() => {
                                                            setStatus({
                                                                ...status,
                                                                dateOpen: false,
                                                            });
                                                        }}
                                                        sx={{
                                                            ...theme.typography.customInput,
                                                            '& > div > input': {
                                                                padding: '20px !important'
                                                            },
                                                        }}
                                                    // viewRenderers={{
                                                    //     hours: null,
                                                    //     minutes: null,
                                                    //     seconds: null,
                                                    // }}
                                                    // renderInput={(params) => {
                                                    //     console.log("DATE PARAMS", params);

                                                    //     return (
                                                    //         <TextField
                                                    //             fullWidth
                                                    //             sx={{
                                                    //                 "& .MuiInputBase-input": {
                                                    //                     paddingTop: "20.5px !important",
                                                    //                     paddingBottom: "20.5px !important",
                                                    //                 },
                                                    //             }}
                                                    //             error={Boolean(touched.date && errors.date)}
                                                    //             name="date"
                                                    //             {...params}
                                                    //             onBlur={handleBlur}
                                                    //             onClick={() =>
                                                    //                 setStatus({
                                                    //                     ...status,
                                                    //                     dateOpen: true,
                                                    //                 })
                                                    //             }
                                                    //         />
                                                    //     )
                                                    // }}
                                                    />
                                                    {touched.date && errors.date && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-date-register"
                                                        >
                                                            {errors.date}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            {!resetMode && (
                                                <>
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <ClickAwayListener
                                                            onClickAway={() => {
                                                                if (status.startTimeOpen) {
                                                                    setStatus({
                                                                        ...status,
                                                                        startTimeOpen: false,
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                                error={Boolean(status.startTimeError)}
                                                                sx={{ ...theme.typography.customInput }}
                                                            >
                                                                <Typography
                                                                    variant="h3"
                                                                    fontWeight="300"
                                                                    sx={{ p: 1.3 }}
                                                                >
                                                                    Start Time
                                                                </Typography>
                                                                <TimePicker
                                                                    id="outlined-adornment-startTime-register"
                                                                    // label="Start Time"
                                                                    defaultValue={""}
                                                                    name="startTime"
                                                                    value={values.startTime}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(status.startTimeError)}
                                                                    minutesStep={1}
                                                                    // showToolbar
                                                                    // ampmInClock
                                                                    // {...(matchUpMd
                                                                    //     ? { open: status.startTimeOpen }
                                                                    //     : {})}
                                                                    onClose={(e) => {
                                                                        //   setStatus({
                                                                        //     ...status,
                                                                        //     startTimeOpen: false,
                                                                        //   });
                                                                    }}
                                                                    onChange={(value) => {
                                                                        if (!value) {
                                                                            setStatus({
                                                                                ...status,
                                                                                startTimeError: "Start time required",
                                                                            });
                                                                        } else if (isNaN(Date.parse(value.$d))) {
                                                                            setStatus({
                                                                                ...status,
                                                                                startTimeError: timeErrorMessage,
                                                                            });
                                                                        } else {
                                                                            setStatus({
                                                                                ...status,
                                                                                startTimeError: "",
                                                                            });
                                                                        }
                                                                        setFieldValue("startTime", value, false);
                                                                    }}
                                                                    sx={{
                                                                        ...theme.typography.customInput,
                                                                        '& > div > input': {
                                                                            padding: '20px !important'
                                                                        },
                                                                        '& > div > fieldset': {
                                                                            borderColor: values.startTime == "" ? 'rgba(0, 0, 0, 0.23) !important' : null
                                                                        },
                                                                    }}
                                                                    renderInput={(params) => {
                                                                        params.inputProps.type = "text";
                                                                        params.inputProps.onInput = handleTimeChange;

                                                                        return (
                                                                            <TextField
                                                                                fullWidth
                                                                                sx={{
                                                                                    "& .MuiInputBase-input": {
                                                                                        paddingTop: "20.5px !important",
                                                                                        paddingBottom: "20.5px !important",
                                                                                    },
                                                                                }}
                                                                                name="startTime"
                                                                                disabled
                                                                                autoComplete="off"
                                                                                value=""
                                                                                spellCheck={false}
                                                                                onClick={(e) => {
                                                                                    setStatus({
                                                                                        ...status,
                                                                                        startTimeOpen: true,
                                                                                    })
                                                                                }}
                                                                                {...params}
                                                                                onChange={handleTimeChange}
                                                                                onBlur={(e) => {
                                                                                    setStatus({
                                                                                        ...status,
                                                                                        startTimeOpen: false,
                                                                                    })
                                                                                }}
                                                                                type="text"
                                                                                error={Boolean(status.startTimeError)}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                {!!status.startTimeError && (
                                                                    <FormHelperText
                                                                        error
                                                                        id="standard-weight-helper-text-startTime-register"
                                                                    >
                                                                        {status.startTimeError}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </ClickAwayListener>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <ClickAwayListener
                                                            onClickAway={() => {
                                                                if (status.endTimeOpen) {
                                                                    setStatus({
                                                                        ...status,
                                                                        endTimeOpen: false,
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                                error={Boolean(status.endTimeError)}
                                                                sx={{ ...theme.typography.customInput }}
                                                            >
                                                                <Typography
                                                                    variant="h3"
                                                                    fontWeight="300"
                                                                    sx={{ p: 1.3 }}
                                                                >
                                                                    End Time
                                                                </Typography>
                                                                <TimePicker
                                                                    id="outlined-adornment-endTime-register"
                                                                    // label="End Time"
                                                                    defaultValue={""}
                                                                    name="endTime"
                                                                    value={values.endTime}
                                                                    // onError={(e) => { }}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(status.endTimeError)}
                                                                    minutesStep={1}
                                                                    // showToolbar
                                                                    // {...(matchUpMd
                                                                    //     ? { open: status.endTimeOpen }
                                                                    //     : {})}
                                                                    onClose={() => {
                                                                        // setStatus({
                                                                        //     ...status,
                                                                        //     endTimeOpen: false,
                                                                        // })
                                                                    }}
                                                                    onChange={(value) => {
                                                                        if (!value) {
                                                                            setStatus({
                                                                                ...status,
                                                                                endTimeError: "End time required",
                                                                            });
                                                                        } else if (isNaN(Date.parse(value.$d))) {
                                                                            setStatus({
                                                                                ...status,
                                                                                endTimeError: timeErrorMessage,
                                                                            });
                                                                        } else {
                                                                            setStatus({
                                                                                ...status,
                                                                                endTimeError: "",
                                                                            });
                                                                        }
                                                                        setFieldValue("endTime", value, false);
                                                                    }}
                                                                    sx={{
                                                                        ...theme.typography.customInput,
                                                                        '& > div > input': {
                                                                            padding: '20px !important'
                                                                        },
                                                                        '& > div > fieldset': {
                                                                            borderColor: values.endTime == "" ? 'rgba(0, 0, 0, 0.23) !important' : null
                                                                        },
                                                                    }}
                                                                    renderInput={(params) => {
                                                                        params.inputProps.type = "text";
                                                                        params.inputProps.onInput = handleTimeChange;

                                                                        return (
                                                                            <TextField
                                                                                fullWidth
                                                                                sx={{
                                                                                    "& .MuiInputBase-input": {
                                                                                        paddingTop: "20.5px !important",
                                                                                        paddingBottom: "20.5px !important",
                                                                                    },
                                                                                }}
                                                                                name="endTime"
                                                                                disabled
                                                                                autoComplete="off"
                                                                                value=""
                                                                                spellCheck={false}
                                                                                onClick={() =>
                                                                                    setStatus({
                                                                                        ...status,
                                                                                        endTimeOpen: true,
                                                                                    })
                                                                                }
                                                                                {...params}
                                                                                onChange={handleTimeChange}
                                                                                onBlur={(e) => {

                                                                                }}
                                                                                type="text"
                                                                                error={Boolean(status.endTimeError)}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                                {status.endTimeError && (
                                                                    <FormHelperText
                                                                        error
                                                                        id="standard-weight-helper-text-endTime-register"
                                                                    >
                                                                        {status.endTimeError}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </ClickAwayListener>
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                sx={{
                                                    padding: "0px 0px 0px 30px !important",
                                                    margin: "0 !important",
                                                }}
                                            >
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={8}
                                                sx={{
                                                    padding: "0px 0px 0px 30px !important",
                                                    margin: "0 !important",
                                                    display: "inline-flex",
                                                    columnGap: "5px",
                                                }}
                                            >
                                                <Typography sx={{ color: "black" }}>
                                                    {values.startTime != values.endTime &&
                                                        values.startTime &&
                                                        values.endTime &&
                                                        !(status.startTimeError || status.endTimeError)
                                                        ? "Total duration: "
                                                        : ""}
                                                </Typography>
                                                <Typography sx={{ color: "black", fontWeight: 700 }}>
                                                    {values.startTime != values.endTime &&
                                                        values.startTime &&
                                                        values.endTime &&
                                                        !(status.startTimeError || status.endTimeError)
                                                        ? prettyDuration(
                                                            getEventDurationHours(
                                                                values.startTime,
                                                                values.endTime
                                                            )
                                                        )
                                                        : ""}
                                                </Typography>
                                            </Grid>
                                            {
                                                !(updatingMode && !recurringSeries) && (!resetMode || recurringSeries) && (
                                                    <Grid item xs={12}>
                                                        <Stack
                                                            marginTop="20px"
                                                            alignItems="center"
                                                            direction="row"
                                                            justifyContent="start"
                                                            spacing={3}
                                                        >
                                                            <Switch
                                                                defaultValue={!!values.isRecurring || recurringSeries}
                                                                checked={!!values.isRecurring || recurringSeries}
                                                                value={!!values.isRecurring || recurringSeries}
                                                                onChange={(e) => {
                                                                    setFieldValue("isRecurring", e.target.checked, true);
                                                                }}
                                                                disabled={recurringSeries}
                                                            />
                                                            <Typography variant="h5" fontWeight="300" sx={{ ml: "0px !important" }}>
                                                                Repeats Every
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                )
                                            }
                                            {!(updatingMode && !recurringSeries) && (values.isRecurring || !!recurringSeries) && (
                                                <>
                                                    <Grid item xs={12} sx={{ mt: "20px" }}>
                                                        <Stack
                                                            direction="row"
                                                            sx={{ alignItems: "center", justifyContent: "space-around", paddingRight: "20px" }}
                                                        >
                                                            {weekdays.map((weekday) => (
                                                                <Card
                                                                    key={weekday}
                                                                    sx={{
                                                                        p: "10px",
                                                                        borderRadius: "50px",
                                                                        borderStyle: "solid",
                                                                        borderWidth: "1px",
                                                                        fontWeight: values[`repeats${weekday}`] ? "bold" : "300",
                                                                        borderColor: values[`repeats${weekday}`] ? "darkcyan" : "transparent",
                                                                        width: "42px",
                                                                        textAlign: "center",
                                                                        background: values[`repeats${weekday}`] ? "#F2FFF2" : theme.palette.secondary.light,
                                                                        "&:hover": {
                                                                            cursor: "pointer",
                                                                        }
                                                                    }}
                                                                    onClick={(e) => {
                                                                        setFieldValue(`repeats${weekday}`, !values[`repeats${weekday}`], true);
                                                                    }}
                                                                >
                                                                    {weekday[0]}
                                                                </Card>
                                                            ))}
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{ mt: "10px" }}>
                                                        <FormControl
                                                            fullWidth
                                                            error={Boolean(touched.untilDate && errors.untilDate)}
                                                            sx={{ ...theme.typography.customInput, width: matchDownSm ? "80%" : "33%" }}
                                                        >
                                                            <Typography
                                                                variant="h5"
                                                                fontWeight="300"
                                                                sx={{ p: 1.3 }}
                                                            >
                                                                Until
                                                            </Typography>
                                                            <MediaDatePicker
                                                                id="outlined-adornment-untilDate-register"
                                                                // label="Shift Date"
                                                                inputFormat="DD/MM/YYYY"
                                                                format="DD/MM/YYYY"
                                                                name="untilDate"
                                                                value={values.untilDate}
                                                                onBlur={handleBlur}
                                                                disablePast
                                                                // Only allow dates after the start date
                                                                minDate={values.date}
                                                                // Up to 1 year from the start date
                                                                maxDate={dayjs(values.date).add(1, "year")}
                                                                error={Boolean(touched.untilDate && errors.untilDate)}
                                                                onChange={(value) =>
                                                                    setFieldValue("untilDate", value, true)
                                                                }
                                                                // {...(matchUpMd ? { open: status.untilDateOpen } : {})}
                                                                onClose={() => {
                                                                    setStatus({
                                                                        ...status,
                                                                        untilDateOpen: false,
                                                                    });
                                                                }}
                                                                sx={{
                                                                    ...theme.typography.customInput,
                                                                    '& > div > input': {
                                                                        padding: '20px !important'
                                                                    },
                                                                }}
                                                            // renderInput={(params) => (
                                                            //     <TextField
                                                            //         fullWidth
                                                            //         sx={{
                                                            //             "& .MuiInputBase-input": {
                                                            //                 paddingTop: "20.5px !important",
                                                            //                 paddingBottom: "20.5px !important",
                                                            //             },
                                                            //         }}
                                                            //         error={Boolean(touched.untilDate && errors.untilDate)}
                                                            //         name="untilDate"
                                                            //         {...params}
                                                            //         onBlur={handleBlur}
                                                            //         onClick={() =>
                                                            //             setStatus({
                                                            //                 ...status,
                                                            //                 untilDateOpen: true,
                                                            //             })
                                                            //         }
                                                            //     />
                                                            // )}
                                                            />
                                                            {touched.untilDate && errors.untilDate && (
                                                                <FormHelperText
                                                                    error
                                                                    id="standard-weight-helper-text-untilDate-register"
                                                                >
                                                                    {errors.untilDate}
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={4} sm={4} md={6}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.hourlyRate && status.hourlyRate
                                                    )}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        fontWeight="300"
                                                        sx={{ p: 1.3 }}
                                                    >
                                                        Hourly Rate
                                                    </Typography>
                                                    <OutlinedInput
                                                        id="outlined-adornment-hourlyRate-register"
                                                        // label="Hourly Rate"
                                                        name="hourlyRate"
                                                        type="text"
                                                        value={values.hourlyRate}
                                                        sx={{
                                                            "& .MuiInputBase-input": {
                                                                paddingTop: "20.5px !important",
                                                                paddingBottom: "20.5px !important",
                                                            },
                                                        }}
                                                        onBlur={(e) => {
                                                            let formattedValue = parseCurrency(
                                                                null,
                                                                e.target.value
                                                            );
                                                            setFieldValue(
                                                                "hourlyRate",
                                                                `$${formattedValue}`,
                                                                true
                                                            );
                                                            handleBlur(e);
                                                        }}
                                                        onChange={handleChange}
                                                        endAdornment={
                                                            <InputAdornment
                                                                position="end"
                                                                sx={{ mt: "20px", paddingRight: "20px" }}
                                                            >
                                                                <Typography>CAD</Typography>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    {touched.hourlyRate && errors.hourlyRate && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-hourlyRate-register"
                                                        >
                                                            {errors.hourlyRate}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={4} sm={4} md={4}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.paidBreak && errors.paidBreak)}
                          sx={{
                            ...theme.typography.customInputNoSidePadding,
                          }}
                        >
                          <InputLabel htmlFor="outlined-adornment-paidBreak-register">
                            Paid Break
                          </InputLabel>

                          <OutlinedInput
                            id="outlined-adornment-paidBreak-register"
                            label="Paid Break"
                            name="paidBreak"
                            type="text"
                            value={values.paidBreak}
                            sx={{
                              ...theme.typography.customInputNoSidePadding,
                            }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment
                                direction="row"
                                sx={{ mt: "15px", mr: "0" }}
                                position="start"
                              >
                                <IconCircleMinus
                                  width="1.5rem"
                                  height="1.5rem"
                                  strokeWidth={0.5}
                                  cursor="pointer"
                                  onClick={() => {
                                    if (isNaN(Number(values.paidBreak))) return;

                                    const subtractionRes =
                                      Number(values.paidBreak) -
                                      Number(paidBreakInterval);

                                    const newPaidBreak =
                                      !isNaN(subtractionRes) &&
                                      subtractionRes > 0
                                        ? subtractionRes
                                        : 0;

                                    setFieldValue(
                                      "paidBreak",
                                      newPaidBreak,
                                      true
                                    );
                                  }}
                                />
                                <IconCirclePlus
                                  width="1.5rem"
                                  height="1.5rem"
                                  strokeWidth={0.5}
                                  cursor="pointer"
                                  onClick={() => {
                                    if (
                                      isNaN(Number(values.paidBreak)) ||
                                      !(
                                        values.paidBreak + paidBreakInterval >=
                                        0
                                      )
                                    )
                                      return;

                                    setFieldValue(
                                      "paidBreak",
                                      Number(values.paidBreak) +
                                        paidBreakInterval,
                                      true
                                    );
                                  }}
                                />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{ mt: "20px", paddingRight: "20px" }}
                              >
                                <Typography>Mins</Typography>
                              </InputAdornment>
                            }
                          />

                          {touched.paidBreak && errors.paidBreak && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-paidBreak-register"
                            >
                              {errors.paidBreak}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid> */}
                                            <Grid item xs={4} sm={4} md={6}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(touched.openings && errors.openings)}
                                                    sx={{
                                                        ...theme.typography.customInputNoSidePadding,
                                                    }}
                                                >
                                                    {/* <InputLabel htmlFor="outlined-adornment-openings-register">
                              Openings
                            </InputLabel> */}
                                                    <Typography
                                                        variant="h3"
                                                        fontWeight="300"
                                                        sx={{ p: 1.3 }}
                                                    >
                                                        Openings
                                                    </Typography>

                                                    <OutlinedInput
                                                        id="outlined-adornment-openings-register"
                                                        // label="Openings"
                                                        name="openings"
                                                        type="text"
                                                        value={values.openings}
                                                        sx={{
                                                            // ...theme.typography.customInputNoSidePadding,
                                                            "& .MuiInputBase-input": {
                                                                paddingTop: "20.5px !important",
                                                                paddingBottom: "20.5px !important",
                                                            },
                                                        }}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        startAdornment={
                                                            <InputAdornment
                                                                direction="row"
                                                                // sx={{ mt: "15px" }}
                                                                position="start"
                                                            >
                                                                <IconCircleMinus
                                                                    width="1.5rem"
                                                                    height="1.5rem"
                                                                    strokeWidth={0.5}
                                                                    cursor="pointer"
                                                                    onClick={() => {
                                                                        if (
                                                                            isNaN(Number(values.openings)) ||
                                                                            !(
                                                                                values.openings - openingsInterval >
                                                                                0
                                                                            )
                                                                        )
                                                                            return;

                                                                        const newOpenings =
                                                                            values.openings - openingsInterval;

                                                                        setFieldValue(
                                                                            "openings",
                                                                            newOpenings,
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                                <IconCirclePlus
                                                                    width="1.5rem"
                                                                    height="1.5rem"
                                                                    strokeWidth={0.5}
                                                                    cursor="pointer"
                                                                    onClick={() => {
                                                                        if (
                                                                            isNaN(Number(values.openings)) ||
                                                                            !(
                                                                                values.openings + openingsInterval >=
                                                                                0
                                                                            )
                                                                        )
                                                                            return;

                                                                        setFieldValue(
                                                                            "openings",
                                                                            Number(values.openings) +
                                                                            openingsInterval,
                                                                            true
                                                                        );
                                                                    }}
                                                                />
                                                            </InputAdornment>
                                                        }
                                                    />

                                                    {touched.openings && errors.openings && (
                                                        <FormHelperText
                                                            error
                                                            id="standard-weight-helper-text-openings-register"
                                                        >
                                                            {errors.openings}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            {
                                                // If the date is scheduled for a past date, show a warning
                                                (values.date && values.endTime && dayjs(values.date).hour(values.endTime.hour()).minute(values.endTime.minute()).isBefore(dayjs())) && (
                                                    <Grid item xs={12} sx={{
                                                        padding: "0px 0px 0px 25px !important",
                                                        margin: "20px 0px 0px 0px !important",
                                                    }}>
                                                        <Typography
                                                            sx={{
                                                                marginBottom: "0px",
                                                                padding: "20px",
                                                                background: theme.palette.secondary.light,
                                                                borderRadius: "15px",
                                                                fontWeight: "500",
                                                                color: theme.palette.grey[600],
                                                            }}
                                                        >
                                                            NOTE: {((!!values.isRecurring && !updatingMode) || recurringSeries) ? "At least one shift in this series " : "This shift "}is scheduled for a past date. Ambassadors will not be able to claim it and it will automatically be marked as Completed.
                                                        </Typography>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={{ xs: 2, md: 3 }}
                                            columns={{ xs: 4, sm: 8, md: 12 }}
                                            padding="5%"
                                            className={
                                                steps[activeStep] === "Work Details" && !loading
                                                    ? "fadedInView"
                                                    : "fadedOutView"
                                            }
                                        >
                                            <Grid item xs={12}>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: "1.5rem",
                                                        mb: "10px",
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    htmlFor="input-what-to-do"
                                                >
                                                    Summary
                                                </InputLabel>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(touched.summary && errors.summary)}
                                                    sx={{
                                                        mb: "1rem",
                                                    }}
                                                >
                                                    <TextField
                                                        id="input-summary"
                                                        placeholder="Example: Show students around the campus."
                                                        // label="Summary"
                                                        rows={1}
                                                        fullWidth
                                                        required
                                                        autoFocus
                                                        name="summary"
                                                        value={values.summary}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText="Max 255 characters"
                                                    // InputLabelProps={{
                                                    //   sx: {
                                                    //     fontSize: "1rem"
                                                    //   },
                                                    // }}
                                                    />
                                                </FormControl>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: "1.5rem",
                                                        mb: "10px",
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    htmlFor="input-checklit"
                                                >
                                                    Checklist
                                                </InputLabel>
                                                <Typography
                                                    sx={{
                                                        fontSize: "0.9rem",
                                                        mb: "10px",
                                                        color: theme.palette.grey[600],
                                                    }}
                                                >
                                                    Add any tasks or items that the ambassador should complete during the shift.
                                                </Typography>
                                                <FormControl
                                                    fullWidth
                                                    // error={Boolean(touched.checklist && errors.checklist)}
                                                    sx={{ ...theme.typography.customInput }}
                                                >
                                                    {todoChecklist.map((todoItem) => (
                                                        <Chip
                                                            key={todoItem}
                                                            sx={{
                                                                fontSize: "1rem",
                                                                fontWeight: "600",
                                                                marginRight: "10px",
                                                                marginBottom: "15px",
                                                                height: "unset",
                                                                p: "10px",
                                                                alignItems: "center",
                                                                borderRadius: "27px",
                                                                transition: "all .2s ease-in-out",
                                                                borderWidth: "0",
                                                                // borderColor: theme.palette.primary.main,
                                                                backgroundColor: "transparent",
                                                                border: "1px solid #E0E0E0",
                                                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                                                color: theme.palette.primary.dark,
                                                                justifyContent: "space-between !important",
                                                                overflow: "visible",
                                                                '& .MuiChip-label': {
                                                                    overflow: "visible",
                                                                    whiteSpace: "pre-wrap",
                                                                }
                                                            }}
                                                            label={todoItem}
                                                            variant="outlined"
                                                            deleteIcon={<CancelIcon />}
                                                            onDelete={() => {
                                                                setTodoChecklist(
                                                                    todoChecklist.filter(
                                                                        (todoChecklistItem) => todoChecklistItem !== todoItem
                                                                    )
                                                                );
                                                            }}
                                                            // ref={anchorRef}
                                                            // aria-controls={open ? "menu-list-grow" : undefined}
                                                            aria-haspopup="true"
                                                        />
                                                    ))}
                                                    <Stack
                                                        alignItems="center"
                                                        direction="row"
                                                        justifyContent="start"
                                                        spacing={2}
                                                    >
                                                        <OutlinedInput
                                                            id="checkListItem"
                                                            // label="Position Title"
                                                            name="checkListItem"
                                                            type="text"
                                                            placeholder={`Example: ${taskPlaceholders[todoChecklist.length % taskPlaceholders.length]}`}
                                                            value={values.checkListItem}
                                                            sx={{
                                                                "& .MuiInputBase-input": {
                                                                    paddingTop: "13.5px !important",
                                                                    paddingBottom: "13.5px !important",
                                                                },
                                                                width: "100%",
                                                            }}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    event.preventDefault();
                                                                    if (values.checkListItem) {
                                                                        setTodoChecklist([...todoChecklist, values.checkListItem]);
                                                                        values.checkListItem = "";
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <AddIcon
                                                            sx={{
                                                                color: "white",
                                                                marginRight: "15px",
                                                                paddingLeft: "5px",
                                                                paddingRight: "5px",
                                                                height: "35px",
                                                                width: "1.5em",
                                                                alignItems: "center",
                                                                borderRadius: "45px",
                                                                transition: "all .2s ease-in-out",
                                                                borderColor: theme.palette.primary.main,
                                                                backgroundColor: values.checkListItem ? theme.palette.primary.main : "grey",
                                                                '&[aria-controls="menu-list-grow"], &:hover': values.checkListItem ? {
                                                                    cursor: "pointer",
                                                                    borderColor: theme.palette.primary.main,
                                                                    background: `${theme.palette.primary.dark}!important`,
                                                                    color: theme.palette.success.light,
                                                                    "& svg": {
                                                                        stroke: theme.palette.primary.light,
                                                                    },
                                                                } : null,
                                                            }}
                                                            onClick={values.checkListItem ? () => {
                                                                setTodoChecklist([...todoChecklist, values.checkListItem]);
                                                                values.checkListItem = "";
                                                            } : null}
                                                        />
                                                    </Stack>
                                                </FormControl>
                                                <InputLabel
                                                    sx={{
                                                        fontSize: "1.5rem",
                                                        mb: "10px",
                                                        mt: "1rem",
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    htmlFor="input-notes"
                                                >
                                                    Notes / Instructions (optional)
                                                </InputLabel>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.workDuties && errors.workDuties
                                                    )}
                                                >
                                                    <TextField
                                                        id="input-what-to-do"
                                                        placeholder={`Anything the ambassador should know about the position`}
                                                        multiline
                                                        // label="Description"
                                                        rows={4}
                                                        maxRows={Infinity}
                                                        fullWidth
                                                        required={false}
                                                        name="workDuties"
                                                        value={values.workDuties}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    // InputLabelProps={{
                                                    //   sx: {
                                                    //     fontSize: "1rem"
                                                    //   },
                                                    // }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Stack
                                            padding="5%"
                                            pt="0"
                                            className={
                                                steps[activeStep] === "Upcoming Series Shifts" && !loading
                                                    ? "fadedInView"
                                                    : "fadedOutView"
                                            }
                                        >
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    fontWeight: 100,
                                                    color: "darkgrey",
                                                    display: upcomingShiftsInSeries.length > 0 ? "block" : "none",
                                                }}
                                            >
                                                Here are all of the upcoming shifts in the series. You can either edit them individually, or edit details for all of them at once via the 'Edit Series' button at the bottom.
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                sx={{
                                                    fontWeight: 100,
                                                    color: "darkgrey",
                                                    display: upcomingShiftsInSeries.length === 0 ? "block" : "none",
                                                }}
                                            >
                                                There are no upcoming shifts in this series. You can filter for this series in the Shifts tab to see all of the past shifts.
                                            </Typography>
                                            <Chip
                                                sx={{
                                                    marginLeft: "auto",
                                                    float: "right",
                                                    mt: "20px",
                                                    fontSize: "0.7rem",
                                                    borderRadius: "50px",
                                                    background: matchUpMd ? theme.palette.warning.light : theme.palette.error.main,
                                                    color: "white",
                                                    cursor: "pointer",
                                                    borderWidth: "1px",
                                                    borderColor: matchUpMd ? theme.palette.warning.light : theme.palette.error.main,
                                                    borderStyle: "solid",
                                                    alignSelf: "center",
                                                    pt: "5px",
                                                    pb: "5px",
                                                    '&[aria-controls="menu-list-grow"], &:hover': {
                                                        borderColor: theme.palette.error.main,
                                                        background: `${theme.palette.orange.dark}!important`,
                                                        color: "white",
                                                        "& svg": {
                                                            stroke: theme.palette.primary.light,
                                                        },
                                                    },
                                                    display: upcomingShiftsInSeries.length > 0 ? "block" : "none",
                                                }}
                                                label={<h4 style={{ fontWeight: 500, display: "contents" }}> Cancel All Upcoming Shifts</h4>}
                                                onClick={(e) => {
                                                    setLoading(true);
                                                    cancelAllShiftsInSeries();
                                                    setLoading(false);
                                                    resetEverything(e);
                                                    onDrawerClose(e);
                                                }}
                                            />
                                            {upcomingShiftsInSeries.sort((a, b) => new Date(a.attributes.time) - new Date(b.attributes.time)).map((seriesShift) => (
                                                <Grid
                                                    container
                                                    key={`recurring_shift_${seriesShift.id}`}
                                                    mt="25px"
                                                    pt="0"
                                                    pl="5%"
                                                    pr="5%"
                                                    pb="4%"
                                                    rowSpacing={2}
                                                    sx={{
                                                        background: "white",
                                                        borderRadius: "15px",
                                                        width: "98%",
                                                        boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 10%)",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Grid item xs={8.1}>
                                                        <Typography
                                                            variant="h3"
                                                            fontWeight="300"
                                                            sx={{ pt: 1.3, pb: 1.3 }}
                                                        >
                                                            {new Date(Date.parse(seriesShift.attributes.time)).toLocaleString(
                                                                "en",
                                                                {
                                                                    weekday: "long",
                                                                    month: "long",
                                                                    day: "numeric",
                                                                    year: "numeric",
                                                                }
                                                            )}
                                                        </Typography>
                                                        <Typography variant="h4" sx={{ fontSize: "1rem" }} color={theme.palette.grey[600]}>
                                                            {eventTimeRangeHumanReadable(seriesShift)}
                                                        </Typography>
                                                        <Stack direction="row" spacing={1} alignItems="center" sx={{ pt: 1.3 }}>
                                                            <EWCircularProgress
                                                                value={
                                                                    seriesShift.attributes.totalOpenings > 0 &&
                                                                        seriesShift.attributes.shifts
                                                                        ? seriesShift.attributes.totalOpenings >
                                                                            seriesShift.attributes.shifts.data.length
                                                                            ? (seriesShift.attributes.shifts.data.length /
                                                                                seriesShift.attributes.totalOpenings) *
                                                                            100
                                                                            : 100
                                                                        : 0
                                                                }
                                                            />
                                                            <Typography
                                                                fontWeight={100}
                                                                fontSize="1.15rem"
                                                                color={theme.palette.grey[200]}
                                                            >
                                                                {seriesShift.attributes.shifts
                                                                    ? seriesShift.attributes.shifts.data.length
                                                                    : 0}
                                                                /{seriesShift.attributes.totalOpenings} Filled
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={3.9} sx={{ textAlign: "right" }}>
                                                        <Stack spacing={2} sx={{ float: "right" }}>
                                                            <Chip
                                                                sx={{
                                                                    fontSize: "0.7rem",
                                                                    borderRadius: "50px",
                                                                    background: "transparent",
                                                                    color: theme.palette.primary.main,
                                                                    cursor: "pointer",
                                                                    borderWidth: "1px",
                                                                    borderColor: theme.palette.primary.main,
                                                                    borderStyle: "solid",
                                                                    alignSelf: "center",
                                                                }}
                                                                label={<h4 style={{ fontWeight: 500 }}> Details</h4>}
                                                                onClick={(e) => {
                                                                    resetEverything(e);
                                                                    onDrawerClose(e);

                                                                    dispatch({
                                                                        type: SET_VIEW_EVENT_DRAWER,
                                                                        viewEventID: seriesShift.id,
                                                                    });
                                                                }}
                                                            />
                                                            {getEventType(seriesShift) === "Scheduled" && (
                                                                <Chip
                                                                    sx={{
                                                                        fontSize: "0.7rem",
                                                                        borderRadius: "50px",
                                                                        background: matchUpMd ? theme.palette.warning.light : theme.palette.error.main,
                                                                        color: "white",
                                                                        cursor: "pointer",
                                                                        borderWidth: "1px",
                                                                        borderColor: matchUpMd ? theme.palette.warning.light : theme.palette.error.main,
                                                                        borderStyle: "solid",
                                                                        alignSelf: "center",
                                                                        pt: "5px",
                                                                        pb: "5px",
                                                                        '&[aria-controls="menu-list-grow"], &:hover': {
                                                                            borderColor: theme.palette.error.main,
                                                                            background: `${theme.palette.orange.dark}!important`,
                                                                            color: "white",
                                                                            "& svg": {
                                                                                stroke: theme.palette.primary.light,
                                                                            },
                                                                        },
                                                                    }}
                                                                    label={<h4 style={{ fontWeight: 500 }}> Cancel</h4>}
                                                                    onClick={(e) => {
                                                                        setLoading(true);
                                                                        cancelEvent(seriesShift);
                                                                        setLoading(false);
                                                                    }}
                                                                />
                                                            )}
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Stack>
                                        <Stack
                                            padding="5%"
                                            pt="0"
                                            className={
                                                steps[activeStep] === "Choose Recent Template" && !loading
                                                    ? "fadedInView"
                                                    : "fadedOutView"
                                            }
                                        >
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    fontWeight: 100,
                                                    color: "darkgrey",
                                                }}
                                            >
                                                Use a recently created shift as a template for this new shift. All details that are not timing-related will be copied over.
                                            </Typography>
                                            {recentTemplates.map((templateShift) => (
                                                <Grid
                                                    container
                                                    key={`template_shift_${templateShift.id}`}
                                                    mt="25px"
                                                    pt="0"
                                                    pl="5%"
                                                    pr="5%"
                                                    pb="4%"
                                                    rowSpacing={2}
                                                    sx={{
                                                        background: "white",
                                                        borderRadius: "15px",
                                                        width: "98%",
                                                        boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 10%)",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={(e) => {
                                                        if (!!selectedTemplate && selectedTemplate.id === templateShift.id) {
                                                            setSelectedTemplate(null);
                                                        } else {
                                                            setSelectedTemplate(templateShift);
                                                        }
                                                    }}
                                                >
                                                    <Grid item xs={8.1}>
                                                        <Typography
                                                            variant="h3"
                                                            fontWeight="300"
                                                            sx={{ pt: 1.3, pb: 1.3 }}
                                                        >
                                                            {templateShift.attributes.workType}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3.9} sx={{ textAlign: "right" }}>
                                                        <Radio
                                                            checked={!!selectedTemplate && selectedTemplate.id === templateShift.id}
                                                            value={templateShift.id}
                                                            name="radio-buttons"
                                                            inputProps={{ 'aria-label': templateShift.id }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8.1} sx={{ pt: "5px !important" }}>
                                                        <Typography variant="h4" sx={{ fontSize: "1rem" }} color={theme.palette.grey[600]}>
                                                            {templateShift.attributes.summary}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3.9} sx={{ pt: "5px !important" }}>
                                                        <Typography sx={{ textAlign: "right" }}>
                                                            {templateShift.attributes.totalOpenings} Opening{templateShift.attributes.totalOpenings > 1 ? "s" : ""}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8.1}>
                                                        <Typography
                                                            fontWeight={100}
                                                            fontSize="1.15rem"
                                                            color={theme.palette.grey[200]}
                                                        >
                                                            @ {templateShift.attributes.address}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3.9}>
                                                        <Typography sx={{ textAlign: "right" }}>
                                                            ${templateShift.attributes.ratePerHourCAD.toFixed(2)}/hr
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Stack>
                                        <Stack
                                            padding="5%"
                                            pt="0"
                                            className={
                                                steps[activeStep] === "Ambassador Preferences" && !loading
                                                    ? "fadedInView"
                                                    : "fadedOutView"
                                            }
                                        >
                                            <InputLabel
                                                sx={{
                                                    fontSize: "1.5rem",
                                                    mb: "10px",
                                                    mt: "1rem",
                                                    color: theme.palette.primary.dark,
                                                }}
                                                htmlFor="input-notes"
                                            >
                                                Assign Openings
                                            </InputLabel>
                                            <Typography
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    mb: "10px",
                                                    color: theme.palette.grey[600],
                                                }}
                                            >
                                                Assign ambassadors to the shifts. Unfilled openings can be claimed by ambassadors through the CampusThreads app.
                                            </Typography>
                                            {Array.from({ length: values.openings }, (_, i) => i).map((i) => i < eligibleAmbassadors.length ? (
                                                <Grid
                                                    container
                                                    key={`can_choose${i}`} // TODO this is hacky
                                                    mt="25px"
                                                    pt="0"
                                                    pl="5%"
                                                    pr="5%"
                                                    pb="5%"
                                                    rowSpacing={2}
                                                    sx={{
                                                        background: "white",
                                                        borderRadius: "15px",
                                                        width: "90%",
                                                        boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 10%)",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Grid item xs={8.1}>
                                                        <Typography
                                                            variant="h3"
                                                            fontWeight="300"
                                                            sx={{ p: 1.3 }}
                                                        >
                                                            {`Shift Opening`}
                                                        </Typography>
                                                        <FormControl
                                                            fullWidth
                                                            sx={{ ...theme.typography.customInput, mt: 0, mb: 0 }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <Select
                                                                labelId={`action-select-label-${i}`}
                                                                id={`action-select-${i}`}
                                                                value={userIdsToAssign[i]}
                                                                onChange={(e) => {
                                                                    setUserIdsToAssign([...userIdsToAssign.slice(0, i), e.target.value, ...userIdsToAssign.slice(i + 1)]);
                                                                    console.log(e.target.value);
                                                                }}
                                                                label="Type"
                                                                sx={{ height: "55px" }}
                                                                displayEmpty
                                                                renderValue={(selected) => {
                                                                    const ambassador = eligibleAmbassadors.find((ambassador) => ambassador.id === userIdsToAssign[i]);
                                                                    return ambassador ? (
                                                                        <Typography
                                                                            variant="h4"
                                                                            p="3%"
                                                                            sx={{ color: theme.palette.secondary.dark }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    // float: "left",
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    // alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <FailSafeAvatar
                                                                                    sz={27}
                                                                                    firstName={ambassador.firstName}
                                                                                    lastName={ambassador.lastName}
                                                                                    profilePicURL={ambassador && ambassador.avatar ? ambassador.avatar.url : ""}
                                                                                    cartoonOptions={ambassador?.avatarOptions}
                                                                                    sx={{
                                                                                        width: "27px",
                                                                                        height: "27px",
                                                                                        fontSize: "1rem",
                                                                                        mt: "-5px",
                                                                                        mr: "5px",
                                                                                    }}
                                                                                />
                                                                                <Typography
                                                                                    variant="h4"
                                                                                    color={theme.palette.primary.dark}
                                                                                >
                                                                                    {ambassador.firstName}{" "}
                                                                                    {ambassador.lastName}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Typography>
                                                                    ) : null;
                                                                }}
                                                                input={
                                                                    <OutlinedInput
                                                                        id={`outlined-adornment-ambassador-${i}-select`}
                                                                        // label="Position Title"
                                                                        name="assignAmbassador"
                                                                        type="text"
                                                                    // value={userAction}
                                                                    // defaultValue={userAction}
                                                                    />
                                                                }
                                                            >
                                                                {eligibleAmbassadors.map((ambassador) => (
                                                                    <MenuItem
                                                                        key={`ambassador_${ambassador.id}`}
                                                                        value={ambassador.id}
                                                                        sx={{
                                                                            display: userIdsToAssign.includes(ambassador.id) ? "none" : "flex",
                                                                            justifyContent: "space-between",
                                                                            minWidth: "350px",
                                                                        }}
                                                                        onClick={(e) => {
                                                                            setUserIdsToAssign([...userIdsToAssign.slice(0, i), ambassador.id, ...userIdsToAssign.slice(i + 1)]);
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="h4"
                                                                            p="3%"
                                                                            sx={{ color: theme.palette.secondary.dark, justifyContent: "space-between", whiteSpace: "pre-wrap" }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    // float: "left",
                                                                                    display: "flex",
                                                                                    flexDirection: "row",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <FailSafeAvatar
                                                                                    sz={27}
                                                                                    firstName={ambassador.firstName}
                                                                                    lastName={ambassador.lastName}
                                                                                    profilePicURL={ambassador && ambassador.avatar ? ambassador.avatar.url : ""}
                                                                                    cartoonOptions={ambassador?.avatarOptions}
                                                                                    sx={{
                                                                                        width: "27px",
                                                                                        height: "27px",
                                                                                        fontSize: "1rem",
                                                                                        mt: "-5px",
                                                                                        mr: "5px",
                                                                                    }}
                                                                                />
                                                                                <Typography
                                                                                    variant="h4"
                                                                                    color={theme.palette.primary.dark}
                                                                                >
                                                                                    {ambassador.firstName}{" "}
                                                                                    {ambassador.lastName}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h5"
                                                                            color={theme.palette.warning.dark}
                                                                            sx={{ marginLeft: "12px", display: recurringSeries || (!!availableAmbassadors && Array.isArray(availableAmbassadors) && !!availableAmbassadors.find((availableAmbassador) => availableAmbassador.id === ambassador.id)) ? "none" : "flex", alignItems: "center", columnGap: "2px" }}
                                                                        >
                                                                            <IconAlertCircle strokeWidth="1" width="20px" />
                                                                            Schedule Conflict
                                                                        </Typography>
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem
                                                                    key={`ambassador_unassigned`}
                                                                    value={""}
                                                                    sx={{
                                                                        display: userIdsToAssign[i] == "" ? "none" : "auto",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="h4"
                                                                        sx={{ color: theme.palette.secondary.dark, fontWeight: 300 }}
                                                                    >
                                                                        Unassigned
                                                                    </Typography>
                                                                </MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <Grid
                                                    container
                                                    key={`can_not_choose${i}`} // TODO this is hacky
                                                    mt="25px"
                                                    pt="0"
                                                    pl="5%"
                                                    pr="5%"
                                                    pb="5%"
                                                    rowSpacing={2}
                                                    sx={{
                                                        background: "white",
                                                        borderRadius: "15px",
                                                        width: "90%",
                                                        boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 10%)",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <Grid item xs={8.1}>
                                                        <Typography
                                                            variant="h3"
                                                            fontWeight="300"
                                                            sx={{ p: 1.3 }}
                                                        >
                                                            {`Shift Opening`}
                                                        </Typography>
                                                        <FormControl
                                                            fullWidth
                                                            sx={{ ...theme.typography.customInput, mt: 0, mb: 0 }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <Select
                                                                defaultValue={0}
                                                                disabled={true}
                                                                // value={userAction}
                                                                onChange={(e) => {

                                                                }}
                                                                label="Type"
                                                                sx={{ height: "55px" }}
                                                                input={
                                                                    <OutlinedInput
                                                                        type="text"
                                                                    />
                                                                }
                                                            >
                                                                <MenuItem
                                                                    key={`no_ambassadors_available`}
                                                                    value={0}
                                                                >
                                                                    <Typography
                                                                        variant="h4"
                                                                        sx={{ color: theme.palette.secondary.dark, fontWeight: 300 }}
                                                                    >
                                                                        No Ambassadors Available
                                                                    </Typography>
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            {
                                                (!!recurringSeries) && (
                                                    <Stack direction="row" sx={{ alignItems: "center", p: "3%" }}>
                                                        <Switch
                                                            defaultValue={false}
                                                            checked={overWriteShifts}
                                                            value={overWriteShifts}
                                                            onChange={(e) => {
                                                                setOverWriteShifts(e.target.checked);
                                                            }}
                                                        />
                                                        <Typography variant="h5" sx={{}}>
                                                            Overwrite existing assignments
                                                        </Typography>
                                                    </Stack>
                                                )
                                            }
                                            {
                                                !updatingMode && (
                                                    <>
                                                        <InputLabel
                                                            sx={{
                                                                fontSize: "1.5rem",
                                                                mb: "10px",
                                                                mt: "2rem",
                                                                color: theme.palette.primary.dark,
                                                            }}
                                                            htmlFor="input-notes"
                                                        >
                                                            Notifications
                                                        </InputLabel>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.9rem",
                                                                mb: "10px",
                                                                color: theme.palette.grey[600],
                                                            }}
                                                        >
                                                            Once posted, email and push notifications should be sent out to:
                                                        </Typography>
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                value={notificationType}
                                                                name="radio-buttons-group"
                                                                onChange={(e) => {
                                                                    setNotificationType(e.target.value);
                                                                }}
                                                            >
                                                                <FormControlLabel value="no" control={<Radio />} label="No ambassadors" />
                                                                <FormControlLabel value="assigned" control={<Radio />} label="Only ambassadors who are assigned to the shift" />
                                                                <FormControlLabel value="both" control={<Radio />} label="Assigned ambassadors and ambassadors whose availability falls in the shift's time (if there are still openings left)" />
                                                                <FormControlLabel value="all" control={<Radio />} label="All ambassadors" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </>
                                                )
                                            }
                                        </Stack>
                                        {Boolean(steps[activeStep] !== "Completed") && (
                                            <Box
                                                padding="5%"
                                                sx={{
                                                    clear: "both",
                                                    backgroundColor: theme.palette.primary.light,
                                                    // position: "absolute",
                                                    // bottom: 0,
                                                    // width: "100%",
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            flexDirection: "column",
                                                        }}
                                                        justifyContent="space-between"
                                                    >
                                                        <Stack direction="row" mb="20px">
                                                            <Typography
                                                                variant="h1"
                                                                fontSize="2.2rem"
                                                                fontWeight={600}
                                                                mr="16px"
                                                            >
                                                                Budgeted:
                                                            </Typography>
                                                            <Typography
                                                                variant="h1"
                                                                fontSize="2.2rem"
                                                                mr="6px"
                                                                color={theme.palette.secondary.dark}
                                                            >
                                                                {`$${(
                                                                    subtotal(values, errors, status) *
                                                                    (1 + hst)
                                                                ).toLocaleString("en-US", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}`}
                                                                <Typography sx={{ textAlign: "center", display: !(updatingMode && !recurringSeries) && (values.isRecurring || !!recurringSeries) ? "block" : "none" }}>
                                                                    per shift
                                                                </Typography>
                                                            </Typography>
                                                            {/* <Typography
                                                                variant="h3"
                                                                fontSize="1.2rem"
                                                                color={theme.palette.grey[500]}
                                                            >
                                                                {" "}
                                                                CAD
                                                            </Typography> */}
                                                        </Stack>

                                                        <Stack
                                                            direction="row"
                                                            mb="20px"
                                                            display="flex"
                                                            alignItems="center"
                                                        >
                                                            <InfoOutlinedIcon
                                                                sx={{
                                                                    color: theme.palette.grey[500],
                                                                    mr: "10px",
                                                                    mb: "5px",
                                                                }}
                                                            />
                                                            <Typography
                                                                color={theme.palette.grey[500]}
                                                                sx={{ whiteSpace: "pre-line", mb: 0.5 }}
                                                            >
                                                                Assumes all openings are filled.
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                    <Typography
                                                        variant="h3"
                                                        sx={{
                                                            color: "darkgrey",
                                                            textDecoration: "underline",
                                                            cursor: "pointer",
                                                            mt: "0.8rem",
                                                            display:
                                                                activeStep === 0 ||
                                                                    activeStep === steps.length - 1
                                                                    ? "none"
                                                                    : "auto",
                                                        }}
                                                        onClick={() => {
                                                            if (activeStep > 0 && !loading && !isValidating) {
                                                                setActiveStep(activeStep - 1);
                                                            }
                                                        }}
                                                    >
                                                        Back
                                                    </Typography>
                                                    <Chip
                                                        sx={{
                                                            height: "55px",
                                                            pl:
                                                                updatingMode && activeStep === steps.length - 2
                                                                    ? "7px"
                                                                    : "17px",
                                                            pr:
                                                                updatingMode && activeStep === steps.length - 2
                                                                    ? "7px"
                                                                    : "17px",
                                                            alignItems: "center",
                                                            borderRadius: "27px",
                                                            transition: "all .2s ease-in-out",
                                                            borderColor: theme.palette.primary.main,
                                                            backgroundColor: theme.palette.primary.main,
                                                            color: "white",
                                                            '&[aria-controls="menu-list-grow"], &:hover': {
                                                                borderColor: theme.palette.primary.main,
                                                                background: `${theme.palette.primary.dark}!important`,
                                                                color: theme.palette.success.light,
                                                                "& svg": {
                                                                    stroke: theme.palette.primary.light,
                                                                },
                                                            },
                                                            "& .MuiChip-label": {
                                                                lineHeight: 0,
                                                            },
                                                        }}
                                                        label={
                                                            activeStep === steps.length - 2 ? (
                                                                <h2>{updatingMode ? "Update" : "Post"}</h2>
                                                            ) : (
                                                                <h2>{steps[activeStep] === "Upcoming Series Shifts" ? "Edit Series" : "Next"}</h2>
                                                            )
                                                        }
                                                        variant="outlined"
                                                        // ref={anchorRef}
                                                        // aria-controls={open ? "menu-list-grow" : undefined}
                                                        aria-haspopup="true"
                                                        disabled={Boolean(
                                                            isValidating || loading ||
                                                            !stepValuesValid(values, errors, status)
                                                        )}
                                                        onClick={() => {
                                                            console.log(values);
                                                            if (steps[activeStep] === "Choose Recent Template") {
                                                                if (selectedTemplate) {
                                                                    formRef.current.resetForm({
                                                                        values: {
                                                                            ...initialVals,
                                                                            positionTitle: selectedTemplate.attributes.workType,
                                                                            summary: selectedTemplate.attributes.summary,
                                                                            workDuties: selectedTemplate.attributes.workDuties,
                                                                            hourlyRate: `$${parseCurrency(null, selectedTemplate.attributes.ratePerHourCAD)}`,
                                                                            openings: selectedTemplate.attributes.totalOpenings,
                                                                            address: selectedTemplate.attributes.address,
                                                                        }
                                                                    });

                                                                    // Set lat/lng
                                                                    getPlacePredictions({
                                                                        input: selectedTemplate.attributes.address,
                                                                    });
                                                                    // setCustomAddressesOnly()
                                                                } else {
                                                                    formRef.current.resetForm({
                                                                        values: initialVals,
                                                                    });
                                                                    saveAddressLatLng(null);
                                                                }
                                                            }
                                                            if (steps[activeStep] === "Basic Details") {
                                                                saveAddressLatLng(values.address);
                                                                setResetMode(true);

                                                                setUserIdsToAssign(Array.from({ length: values.openings }, (_, i) => ""));

                                                                if (eligibleAmbassadors.length > 0 && !recurringSeries) {
                                                                    fetchAvailableAmbassadors(values);
                                                                }

                                                                // Get the assigned shifts for this event
                                                                if (targetEvent.id && targetEvent.attributes.shifts && steps.includes("Ambassador Preferences")) {
                                                                    const targetEventShifts = targetEvent.attributes.shifts.data;
                                                                    const newAssignments = targetEventShifts.map((shift) => {
                                                                        if (shift.attributes.user) {
                                                                            return shift.attributes.user.data.id;
                                                                        }

                                                                    }).slice(0, values.openings);
                                                                    setOriginalAssignmentsWereNotEmpty(newAssignments.length > 0);
                                                                    setUserIdsToAssign([
                                                                        ...newAssignments,
                                                                        ...Array.from({ length: values.openings - newAssignments.length }, (_, i) => ""),
                                                                    ]);
                                                                }
                                                            }
                                                            if (activeStep === steps.length - 2) {
                                                                // Post the shift!
                                                                postEvent(values);
                                                            } else {
                                                                setActiveStep(activeStep + 1);
                                                            }
                                                            // setOpen((prevOpen) => !prevOpen);
                                                            // handleDrawerToggle();
                                                        }}
                                                    />
                                                </Grid>
                                            </Box>
                                        )}
                                    </form>
                                )}
                            </Formik>
                        </LocalizationProvider>
                    </Stack>
                </Box>
            </Drawer>
        </Box>
    );
};

PostEventDrawer.propTypes = {
    drawerOpen: PropTypes.bool,
    onDrawerClose: PropTypes.func,
    window: PropTypes.object,
};

export default PostEventDrawer;
