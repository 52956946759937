// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

/*
 - Whether a notification is hidden or marked as read will only be stored in the client's state (localstorage)
 - Notifications will be fetched from the server at the start of the app and then updated in the client's state
    - Notifications will be fetched from the server when the user clicks on the notifications button
    - Notifications will be marked as read in the client's state (localstorage) when the user clicks on the Mark All As Read button
 - Link will go to associated shift/chat/call/etc. when they click on notification
 - Avatar will be the associated ambassador's profile picture
 - Notifications will be sorted by created at time
 - Will show the first 10 notifications and then have a "View More" button at the bottom
 - If more than 10, the badge will say "10+"
 - If the user clicks anywhere on the notification, it will navigate to the associated shift/chat/call/etc. and mark the notification as read
 - Eventually will do infinite scroll for notifications
*/

export const initialState = {
    fetchPage: 1,
    fetchPageLimit: 10,
    notifications: [],
    lastAllReadTime: null,
    notificationsHidden: [],
};

// ==============================|| NOTIFICATIONS REDUCER ||============================== //


const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATIONS_FETCH_SUCCEEDED:
            const savedLastAllReadTime = localStorage.getItem('lastAllReadTime');

            return {
                ...state,
                notifications: [...state.notifications, ...action.notifications],
                fetchPage: state.fetchPage + 1,
                lastAllReadTime: savedLastAllReadTime,
            };

        case actionTypes.NOTIFICATIONS_ADD_OR_UPDATE:
            const notificationToUpdate = !!action.updatedNotification ? state.notifications.find(
                (notification) => notification.id === action.updatedNotification.id
            ) : null;

            if (notificationToUpdate) {
                NestedObjectAssign(notificationToUpdate, action.updatedNotification);
            }
            const addedNotification = notificationToUpdate ? [] : [action.updatedNotification];

            const newNotifications = [...state.notifications, ...addedNotification].sort((a, b) => {
                return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
            });

            return {
                ...state,
                notifications: newNotifications,
            };

        case actionTypes.NOTIFICATIONS_FETCH_FAILED:
            console.log(action.message);
            return state;
        case actionTypes.NOTIFICATIONS_MARK_ALL_AS_READ:
            localStorage.setItem('lastAllReadTime', new Date(Date.now()).toUTCString());

            return {
                ...state,
                lastAllReadTime: new Date(Date.now()).toUTCString(),
            };
        case actionTypes.NOTIFICATIONS_HIDE_ONE:
            return {
                ...state,
                notificationsHidden: [...state.notificationsHidden, action.payload],
            };
        default:
            return state;
    }
};

export default notificationsReducer;
