// action - state management
import * as actionTypes from "./actions";
import { NestedObjectAssign } from "utils/objects";

export const initialState = {
    firstFetchMade: false,
    prospects: [],
};

// ==============================|| PROSPECTS REDUCER ||============================== //

const prospectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROSPECTS_ADD_OR_UPDATE:
            const prospectToUpdate = !!action.updatedProspect ? state.prospects.find(
                (prospect) => prospect.id === action.updatedProspect.id
            ) : null;

            if (prospectToUpdate) {
                NestedObjectAssign(prospectToUpdate, action.updatedProspect);
            }
            const addedProspect = prospectToUpdate ? [] : [action.updatedProspect];

            const newProspects = [...state.prospects, ...addedProspect];

            return {
                ...state,
                firstFetchMade: true,
                prospects: newProspects,
            };

        case actionTypes.PROSPECTS_DELETE:
            const filteredProspects = state.prospects.filter(
                (prospect) => prospect.id != action.deletedProspectID
            );

            return {
                ...state,
                firstFetchMade: true,
                prospects: filteredProspects,
            };

        default:
            return state;
    }
};

export default prospectsReducer;
