import { getEventType } from "./events";
import { getOpeningTimesheetStatus } from "./openings";

const millisInSeconds = 1000;

/**
 * This function determines the type of the shift. It can be one of:
 *  - Active
 *  - Inactive
 *  - No Availability
 *  - Pending Invite
 *  - Declined Offer
 *  - Archived
 *  - N/A
 *
 * @param {Object} shift - shift object; see Strapi for relevant fields
 *
 * @returns {string}
 */
export const getChampionStatus = (shift) => {
    const championAttributes =
        shift.hasOwnProperty("id") && shift.hasOwnProperty("attributes")
            ? shift.attributes
            : shift;

    if (!championAttributes) return "N/A";

    //   if (!championAttributes.rewardAccepted && !championAttributes.pendingOffer) {
    //     return "Declined Offer";
    //   }

    //   if (championAttributes.pendingOffer) {
    //     return "Pending Invite";
    //   }

    //   if (
    //     !championAttributes.Availability ||
    //     !championAttributes.Availability.length
    //   ) {
    //     return "No Availability";
    //   }

    if (!championAttributes.completedPassword || !championAttributes.firstName) {
        return "Pending Invite";
    }

    if (!championAttributes.championActive) {
        return "Inactive";
    }

    // if (!championAttributes.calendlyURL) {
    //   return "Validating";
    // }

    if (championAttributes.archived) {
        return "Archived";
    }

    return "Active";
};

/**
 * This function determines the conversation type. It can be one of:
 *  - Completed
 *  - Missed
 *  - Scheduled
 *  - Happening Now
 *  - Cancelled
 *  - N/A
 *
 * @param {Object} conversation - conversation object; see Strapi for relevant fields
 *
 * @returns {string}
 */
export const getConversationType = (conversation) => {
    if (
        !conversation.id ||
        !conversation.attributes ||
        !conversation.attributes.timeScheduled
    )
        return "N/A";

    if (conversation.attributes.cancelled) {
        return "Cancelled";
    }

    const curTime = new Date();
    const conversationMaxDurationMillis =
        conversation.attributes.maxDurationSeconds * millisInSeconds;
    const scheduledTime = new Date(
        Date.parse(conversation.attributes.timeScheduled)
    );
    const startTime = new Date(Date.parse(conversation.attributes.timeStarted));
    const endTime = new Date(Date.parse(conversation.attributes.timeEnded));

    if (
        curTime > scheduledTime + conversationMaxDurationMillis &&
        !conversation.attributes.timeStarted
    ) {
        // It's past the 10-minute window and the call never started. It must be missed.
        return "Missed";
    }

    if (curTime <= scheduledTime) {
        return "Scheduled";
    }

    if (
        conversation.attributes.timeStarted &&
        curTime > startTime &&
        !conversation.attributes.timeEnded
    ) {
        return "Happening Now";
    }

    if (
        conversation.attributes.timeEnded &&
        (!conversation.attributes.transcript || !conversation.attributes.audioID)
    ) {
        return "Processing";
    }

    if (conversation.attributes.timeEnded) {
        return "Completed";
    }
};

export const getTotalConversationSeconds = (champion) => {
    if (!champion.id) return 0;
    const championAttributes = champion.hasOwnProperty("attributes")
        ? champion.attributes
        : champion;

    const conversations = championAttributes.conversations;
    if (!conversations || !conversations.data) return 0;

    const totalMinutes = conversations.data.reduce((total, conversation) => {
        if (
            conversation.id &&
            conversation.attributes &&
            !!conversation.attributes.timeStarted &&
            !!conversation.attributes.timeEnded
        ) {
            return (
                total +
                (Date.parse(conversation.attributes.timeEnded) -
                    Date.parse(conversation.attributes.timeStarted)) /
                millisInSeconds
            );
        }

        return total;
    }, 0);

    return totalMinutes;
};

export const getMonthsActive = (champion) => {
    if (!champion.id) return 1;
    const championAttributes = champion.hasOwnProperty("attributes")
        ? champion.attributes
        : champion;

    // TODO this is wrong - should take time when they were actually active
    const publishedAt = Date.parse(championAttributes.createdAt);
    const curTime = Date.parse(new Date());
    const totalTime =
        publishedAt && curTime > publishedAt ? curTime - publishedAt : 0;
    const monthsPassed = totalTime / (millisInSeconds * 60 * 60 * 730);

    return Math.floor(Math.max(monthsPassed, 1));
};

export const eventTimeRangeHumanReadable = (conversation) => {
    if (!conversation.id || !conversation.attributes) return "N/A";

    const dateUTCString = conversation.attributes.timeStarted;
    const maxDurationSeconds = conversation.attributes.maxDurationSeconds;
    const dateStart = new Date(dateUTCString);
    const dateEnd = new Date(
        dateStart.getTime() + millisInSeconds * maxDurationSeconds
    );

    const timeStart = dateStart
        .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        })
        .replace(/^0+/, "");
    const timeEnd = dateEnd
        .toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
        })
        .replace(/^0+/, "");

    return `${timeStart} -\n ${timeEnd}`;
};
